import { useState } from 'react'
import { useRouter } from 'next/router'
import { TotpMultiFactorGenerator } from 'firebase/auth'
import { motion } from 'framer-motion'
import { ChevronRightIcon, Loader2Icon, Pencil } from 'lucide-react'
import { Trans, useTranslation } from 'react-i18next'
import useSWR from 'swr'

import {
  currencyOptions,
  dateFormatOptions,
  languageOptions,
  numberFormatOptions,
  timeZoneOptions
} from 'core/remodel/types/options'
import type { Preferences, Profile } from 'core/remodel/types/user'
import {
  fetchBackupCodes,
  fetchMultiFactorList,
  fetchPreferences,
  fetchProfile,
  updateNotification,
  userQuery
} from '@/api/AccountService'
import { POLICY_LINKS } from '@/constants/policies'
import { isMatchedEnv } from '@/constants/site'
import { cn } from '@/utils/classnames'
import { parseErrorMessage } from '@/utils/parseErrorMessages'
import { toast } from '@/hooks/useToast'
import { useAuthStore } from '@/store/authStore'
import { Button, Card, CardContent, Modal, Switch, Tooltip, TooltipContent, TooltipTrigger } from '@/components/base'
import AvatarEditor from '@/components/AvatarEditor'
import TruncatedText from '@/components/base/TruncatedText'
import TwoFactorAuthIcon from '@/components/icon/TwoFactorAuthIcon'

export default function ProfilePage() {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: profile } = useSWR([userQuery.profile], fetchProfile(database!))
  const { data: preferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const { data: backupCodes } = useSWR([userQuery.backupCodes], fetchBackupCodes(database!))

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={'mx-auto grid max-w-screen-xl grid-cols-1 gap-y-4 p-4'}
    >
      <h2 className={'text-lg text-white'}>{t('account:AccountSettings')}</h2>

      {[profile, preferences].every((entry) => entry !== undefined) ? (
        <>
          <AccountSettingSection profile={profile!} />
          <PreferenceSection preferences={preferences!} />
          <SecuritySection />
          <BackupCodesSection backupCodes={backupCodes} isDisable={isMatchedEnv(['prod'])} />
          <EmailNotificationSection notification={preferences!.notification} />
          <TermsAndPoliciesSection />
          <CloseAccountSection />
        </>
      ) : (
        <div className={'grid h-40 place-content-center'}>
          <Loader2Icon className={'h-9 w-9 animate-spin text-primary'} />
        </div>
      )}
    </motion.main>
  )
}

function AccountSettingSection({ profile }: { profile: Profile }) {
  const { t } = useTranslation()
  const router = useRouter()
  const { name, email, photo } = profile

  return (
    <Card>
      <CardContent>
        <div className={'mb-4 flex items-center gap-x-2'}>
          <h4 className={'font-medium text-text'}>{t('account:Profile')}</h4>
          <Button onClick={() => router.push('/account/profile/personal/')}>
            <Pencil className={'h-5 w-5 text-primary'} />
          </Button>
        </div>
        <div className={'grid gap-4 text-sm md:grid-cols-3'}>
          <div className={'flex items-center'}>
            <AvatarEditor photoUrl={photo} />
            <TruncatedText as={'label'}>{name || '-'}</TruncatedText>
          </div>
          <div className={'grid'}>
            <label className={'text-gray-500'}>{t('account:Field.Email')}</label>
            <TruncatedText as={'span'}>{email || '-'}</TruncatedText>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

function PreferenceSection({ preferences }: { preferences: Preferences }) {
  const { t } = useTranslation()
  const router = useRouter()
  const { baseCurrency, baseLanguage, timeZone, dateFormat, numberFormat } = preferences

  return (
    <Card>
      <CardContent>
        <div className={'mb-4 flex items-center gap-x-2'}>
          <h4 className={'font-medium text-text'}>{t('account:Preferences')}</h4>
          <Button onClick={() => router.push('/account/profile/preferences/')}>
            <Pencil className={'h-5 w-5 text-primary'} />
          </Button>
        </div>
        <div className={'grid gap-4 text-sm md:grid-cols-3'}>
          <div className={'grid'}>
            <label className={'text-gray-500'}>{t('account:Field.BaseCurrency')}</label>
            <span>{currencyOptions.find(({ value }) => value === baseCurrency)?.label || '-'}</span>
          </div>
          <div className={'grid'}>
            <label className={'text-gray-500'}>{t('account:Field.TimeZone')}</label>
            <span>{timeZoneOptions.find(({ value }) => value === timeZone)?.label || '-'}</span>
          </div>
          <div className={'grid'}>
            <label className={'text-gray-500'}>{t('account:Field.NumberFormat')}</label>
            <span>{numberFormatOptions.find(({ value }) => value === numberFormat)?.label || '-'}</span>
          </div>
          <div className={'grid'}>
            <label className={'text-gray-500'}>{t('account:Field.BaseLanguage')}</label>
            <span>{languageOptions.find(({ value }) => value === baseLanguage)?.label || '-'}</span>
          </div>
          <div className={'grid'}>
            <label className={'text-gray-500'}>{t('account:Field.DateFormat')}</label>
            <span>{dateFormatOptions.find(({ value }) => value === dateFormat)?.label || '-'}</span>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

function SecuritySection() {
  const { t } = useTranslation()
  const router = useRouter()
  const database = useAuthStore((state) => state.database)
  const { data: list = [] } = useSWR([userQuery.multiFactorList], fetchMultiFactorList(database!))
  const hasTotp = list.filter(({ factorId }) => factorId === TotpMultiFactorGenerator.FACTOR_ID).length > 0

  const handleResetPassword = () => router.push('/account/profile/reset-password/')

  const handleModify2FA = () => router.push('/account/profile/totp')

  return (
    <Card>
      <CardContent>
        <div className={'mb-4 flex items-center gap-x-2'}>
          <h4 className={'font-medium text-text'}>{t('account:Security')}</h4>
        </div>

        <div className={'grid gap-4 text-sm md:grid-cols-2'}>
          <div className={'space-y-2'}>
            <h5>{t('account:ChangeYourPassword')}</h5>
            <p className={'text-[#6B7EA9]'}>{t('account:ChangeYourPasswordDesc')}</p>
            <Button variant={'solid'} size={'md'} onClick={handleResetPassword}>
              {t('auth:ResetPassword')}
            </Button>
          </div>
          <div className={'space-y-2'}>
            <div className={'flex items-center gap-x-1'}>
              <h5>{t('account:2FA')}</h5>
              <Tooltip>
                <TooltipTrigger>
                  <TwoFactorAuthIcon className={cn(!hasTotp ? 'text-[#D1D5DB]' : 'text-primary')} />
                </TooltipTrigger>
                <TooltipContent className={'bg-primary text-xs text-white'}>
                  {!hasTotp ? t('account:SecureYourAccount') : t('account:YourAccountIsSecure')}
                </TooltipContent>
              </Tooltip>
            </div>
            <p className={'text-[#6B7EA9]'}>{t('account:Enable2FA')}</p>
            <Button variant={'solid'} size={'md'} onClick={handleModify2FA}>
              {!hasTotp ? t('account:Setup2FA') : t('account:Remove2FA')}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

function BackupCodesSection({ backupCodes, isDisable }: { backupCodes?: string[]; isDisable?: boolean }) {
  const { t } = useTranslation()
  const router = useRouter()
  const hasBackupCodes = backupCodes !== undefined

  return (
    <Card className={cn(isDisable && 'relative after:absolute after:inset-0 after:bg-black/40')}>
      <CardContent>
        <div className={'mb-4 flex items-center gap-x-2'}>
          <h4 className={'font-medium text-text'}>{t('account:BackupCodes')}</h4>
        </div>

        <div className={'space-y-4 text-sm'}>
          <p className={'text-[#6B7EA9]'}>{t('account:BackupCodesDesc')}</p>
          <Button
            variant={'solid'}
            size={'md'}
            onClick={() => router.push('/account/profile/backup-codes/')}
            disabled={isDisable}
          >
            {hasBackupCodes ? t('account:Manage') : t('account:Generate')}
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}

function EmailNotificationSection({ notification }: { notification: Preferences['notification'] }) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)

  const switchNotification = async (target: Partial<Preferences['notification']>) => {
    try {
      await updateNotification(database!, { ...notification, ...target })
      toast({ variant: 'success', description: t('account:Toast.Success.UpdateEmail') })
    } catch (e) {
      const errorMessage = parseErrorMessage(e, 'Unknown error: Update Email Notification')
      toast({ variant: 'error', description: errorMessage })
    }
  }

  return (
    <Card>
      <CardContent>
        <h4 className={'mb-4 font-medium text-text'}>{t('account:EmailNotifications')}</h4>
        <div className={'space-y-4 text-sm'}>
          <div className={'flex items-center gap-x-2'}>
            <Switch
              defaultChecked={notification.updateEmail}
              onCheckedChange={(checked) => switchNotification({ updateEmail: checked })}
            />
            <span>{t('account:ReceiveUpdates')}</span>
          </div>
          <div className={'flex items-center gap-x-2'}>
            <Switch
              defaultChecked={notification.tip}
              onCheckedChange={(checked) => switchNotification({ tip: checked })}
            />
            <span>{t('account:ReceiveTips')}</span>
          </div>
          <div className={'flex items-center gap-x-2'}>
            <Switch
              defaultChecked={notification.delegatesLogIn}
              onCheckedChange={(checked) => switchNotification({ delegatesLogIn: checked })}
            />
            <span>{t('account:NotifyDelegates')}</span>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

const policyLinks = Object.fromEntries(
  Object.entries(POLICY_LINKS).map(([key, link]) => [
    key,
    <a
      key={link}
      className={'text-primary underline hover:text-secondary-hover'}
      href={link}
      target={'_blank'}
      rel={'noopener noreferrer'}
    />
  ])
)

enum Policy {
  TermsAndConditions = 'TermsAndConditions',
  PrivacyPolicy = 'PrivacyPolicy',
  CookiePolicy = 'CookiePolicy'
}

function TermsAndPoliciesSection() {
  const { t } = useTranslation()
  const [content, setContent] = useState<Policy | null>(null)

  return (
    <>
      <Card>
        <CardContent>
          <h4 className={'mb-4 font-medium text-text'}>{t('account:LegalTermsAndPolicies')}</h4>
          <div className={'space-y-4 text-sm'}>
            <p className={'text-[#6B7EA9]'}>{t('account:TermsAndPoliciesDialog')}</p>
            <div className={'flex gap-3'}>
              {Object.values(Policy).map((policy) => (
                <Button
                  key={'test'}
                  className={cn(
                    'gap-1 border border-[#D4D4D4] bg-[#F6F6F6] p-1.5 pl-3  text-sm/6 text-background',
                    'hover:border-[#0F889B] hover:bg-primary-hover hover:text-white'
                  )}
                  size={'md'}
                  onClick={() => setContent(policy)}
                >
                  {t(`account:${policy}`)}
                  <ChevronRightIcon className={'p-0.5'} size={20} />
                </Button>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>

      {content && (
        <Modal onBackdropClick={() => setContent(null)}>
          <Modal.Header className={'bg-primary'}>
            <label className={'text-sm font-medium uppercase text-white'}>{t(`account:${content}`)}</label>
            <Modal.CloseButton className={'text-white'} onClose={() => setContent(null)} />
          </Modal.Header>
          <Modal.Content className={'overflow-y-auto'}>
            <div className={'whitespace-pre-line text-[#5F5F5F]'}>
              {
                <Trans
                  i18nKey={`account:${content}Content`}
                  parent={(props: any) => <div className={'whitespace-pre-line text-sm'} {...props} />}
                  components={{
                    bold: <strong className={'whitespace-pre-line'} />,
                    ul: <ul className={'list-[lower-alpha] pl-10'} />,
                    li: <li />,
                    cookieTable: <div className={'cky-audit-table-element'} />,
                    ...policyLinks
                  }}
                />
              }
            </div>
          </Modal.Content>
        </Modal>
      )}
    </>
  )
}

function CloseAccountSection() {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <Card>
      <CardContent>
        <h4 className={'mb-4 font-medium text-text'}>{t('account:CloseAccount')}</h4>
        <div className={'space-y-4 text-sm'}>
          <Trans
            t={t}
            i18nKey={'account:CloseAccountWarning'}
            parent={(props: any) => <p className={'text-red-600'} {...props} />}
          />
          <Button
            className={cn(
              'gap-1 border border-[#D4D4D4] bg-[#F6F6F6] p-1.5 pl-3 text-sm/6 text-[#DC5959]',
              'hover:border-[#DC5959] hover:bg-[#DC5959] hover:text-white'
            )}
            size={'md'}
            onClick={() => router.push('/account/profile/close-account/')}
          >
            {t('account:CloseAccount')}
            <ChevronRightIcon className={'p-0.5'} size={20} />
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}
