import { mutate } from 'swr'

import type { Database } from 'core/remodel/database'
import { Art } from 'core/remodel/types/arts'
import { BelongingsUtils } from 'core/remodel/types/belongings'
import { Account } from 'core/remodel/types/cashAndBanking'
import { CurrentAccount } from 'core/remodel/types/cashAndBanking/currentAccount'
import { SavingAccount } from 'core/remodel/types/cashAndBanking/savingAccount'
import { AssetType, Currency, LocationType, type Amount } from 'core/remodel/types/common'
import type { Groupable, GroupInfo, GroupItem, GroupWithItems } from 'core/remodel/types/groups'
import { LocationInfo } from 'core/remodel/types/relations/locationInfo'
import type { GroupableAsset } from '@/types/common'
import { delay } from '@/utils/delay'
import { getFullName } from '@/utils/formatter'
import { getTimestamp } from '@/utils/getTimeStamp'
import type { CreateGroupValues } from '@/pages/groups/summary/create'

export const groupQuery = {
  options: 'group-options',
  list: 'group-list',
  info: 'group-info',
  item: 'group-item',
  items: 'group-items',
  assets: 'group-assets'
} as const

export function fetchGroupOptions(database: Database) {
  return async ([_key]: [typeof groupQuery.options]) => {
    const list = await database.group.getAllInfo()
    const groups = list.filter((group) => !group.isDraft)
    return groups.map((group) => ({ label: group.name, value: group.id }))
  }
}

export type GroupExtra = GroupWithItems & {
  extraData?: {
    list: GroupableAsset[]
    assets: Amount | undefined
    liabilities: Amount | undefined
    netValue: Amount | undefined
  }
}

export function fetchGroups(database: Database) {
  return async ([_key, query]: [typeof groupQuery.list, Record<string, any>]) => {
    const listRaw = await database.group.getAll()
    const { baseCurrency: currency } = await database.Account.getCurrentPreferences()
    if (!currency) throw new Error('Base currency not found')

    const list = await Promise.all(
      listRaw.map(async ({ info, items }): Promise<GroupExtra> => {
        try {
          if (info.isDraft) {
            const assetList = await getGroupDraftInfo(database, items)
            const assetsValue = items.reduce((prev, curr) => {
              const { assets, liabilities: _ } = curr.draftData ?? {}
              return (assets ? database.ExRate.amountToBase(assets).value : 0) + prev
            }, 0)
            const liabilitiesValue = items.reduce((prev, curr) => {
              const { assets: _, liabilities } = curr.draftData ?? {}
              return (liabilities ? Math.abs(database.ExRate.amountToBase(liabilities).value) : 0) + prev
            }, 0)
            return {
              info,
              items,
              extraData: {
                list: assetList,
                assets: { currency, value: Math.round(assetsValue) },
                liabilities: { currency, value: Math.round(liabilitiesValue) },
                netValue: { currency, value: Math.round(assetsValue - liabilitiesValue) }
              }
            }
          } else {
            const assetList = await Promise.all(items.map((item) => getGroupItemInfo(database, item)))
            const assetsValue = assetList.reduce((prev, curr) => {
              const assets = curr.value.value > 0 ? curr.value : { currency, value: 0 }
              return database.ExRate.amountToBase(assets).value + prev
            }, 0)
            const liabilitiesValue = assetList.reduce((prev, curr) => {
              const liabilities = curr.value.value < 0 ? curr.value : { currency, value: 0 }
              return Math.abs(database.ExRate.amountToBase(liabilities).value) + prev
            }, 0)
            return {
              info,
              items,
              extraData: {
                list: assetList,
                assets: { currency, value: Math.round(assetsValue) },
                liabilities: { currency, value: Math.round(liabilitiesValue) },
                netValue: { currency, value: Math.round(assetsValue - liabilitiesValue) }
              }
            }
          }
        } catch {
          return { info, items }
        }
      })
    )

    const { sort = 'name', order = 'asc' } = query as Record<string, string>
    const sortedList = list.toSorted((a, b) => {
      switch (sort) {
        case 'name': {
          const comparison = a.info.name.localeCompare(b.info.name)
          return order === 'asc' ? comparison : -comparison
        }
        case 'items': {
          const comparison = a.items.length - b.items.length
          return order === 'asc' ? comparison : -comparison
        }
        case 'assets': {
          const comparison = (a.extraData?.assets?.value ?? 0) - (b.extraData?.assets?.value ?? 0)
          return order === 'asc' ? comparison : -comparison
        }
        case 'liabilities': {
          const comparison = (a.extraData?.liabilities?.value ?? 0) - (b.extraData?.liabilities?.value ?? 0)
          return order === 'asc' ? comparison : -comparison
        }
        case 'netValue': {
          const comparison = (a.extraData?.netValue?.value ?? 0) - (b.extraData?.netValue?.value ?? 0)
          return order === 'asc' ? comparison : -comparison
        }
        case 'updatedAt': {
          const comparison = getTimestamp(b.info.updateAt) - getTimestamp(a.info.updateAt)
          return order === 'asc' ? comparison : -comparison
        }
        default: {
          return 0
        }
      }
    })
    const paginatedList: GroupExtra[] = sortedList.slice(query.offset, query.offset + query.limit)

    return { list: paginatedList, totalCount: listRaw.length }
  }
}

export function fetchGroup(database: Database) {
  return async ([_key, id]: [typeof groupQuery.info, string]): Promise<GroupExtra> => {
    const { info, items } = await database.group.getById(id)
    const { baseCurrency: currency } = await database.Account.getCurrentPreferences()
    if (!currency) throw new Error('Base currency not found')

    try {
      if (info.isDraft) {
        const assetList = await getGroupDraftInfo(database, items)

        const assetsValue = items.reduce((prev, curr) => {
          const { assets, liabilities: _ } = curr.draftData ?? {}
          return (assets ? database.ExRate.amountToBase(assets).value : 0) + prev
        }, 0)
        const liabilitiesValue = items.reduce((prev, curr) => {
          const { assets: _, liabilities } = curr.draftData ?? {}
          return (liabilities ? Math.abs(database.ExRate.amountToBase(liabilities).value) : 0) + prev
        }, 0)
        return {
          info,
          items,
          extraData: {
            list: assetList,
            assets: { currency, value: Math.round(assetsValue) },
            liabilities: { currency, value: Math.round(liabilitiesValue) },
            netValue: { currency, value: Math.round(assetsValue - liabilitiesValue) }
          }
        }
      } else {
        const assetList = await Promise.all(items.map((item) => getGroupItemInfo(database, item)))
        const assetsValue = assetList.reduce((prev, curr) => {
          const assets = curr.value.value > 0 ? curr.value : { currency, value: 0 }
          return database.ExRate.amountToBase(assets).value + prev
        }, 0)
        const liabilitiesValue = assetList.reduce((prev, curr) => {
          const liabilities = curr.value.value < 0 ? curr.value : { currency, value: 0 }
          return Math.abs(database.ExRate.amountToBase(liabilities).value) + prev
        }, 0)
        return {
          info,
          items,
          extraData: {
            list: assetList,
            assets: { currency, value: Math.round(assetsValue) },
            liabilities: { currency, value: Math.round(liabilitiesValue) },
            netValue: { currency, value: Math.round(assetsValue - liabilitiesValue) }
          }
        }
      }
    } catch {
      return { info, items }
    }
  }
}

export function fetchGroupInfosByIds(database: Database) {
  return async ([_key, ids]: [typeof groupQuery.list, string[]]) => {
    return await database.group.getGroupInfoByIds(ids)
  }
}

export const groupableAssetTypes = [
  AssetType.CashAndBanking,
  AssetType.TraditionalInvestments,
  AssetType.Insurance,
  AssetType.Property,
  AssetType.Art,
  AssetType.OtherCollectables,
  AssetType.Belonging
]

export function fetchGroupableAssets(database: Database) {
  return async ([_key, query]: [typeof groupQuery.assets, Record<string, any>]) => {
    const queryWithCategory: Record<string, any> = {
      category: groupableAssetTypes,
      ...query
    }
    const { list, totalCount } = await database.getAssets(queryWithCategory)
    return {
      list: list.map((item) => {
        return {
          assetId: item?.id,
          assetType: item?.assetType as Groupable,
          subtype: item?.subtype,
          name: item?.name,
          // FIXME
          value:
            item?.value && 'currency' in item.value ? (item.value as Amount) : { currency: Currency.USD, value: 0 },
          updateAt: item?.updateAt
        } as GroupableAsset
      }),
      totalCount
    }
  }
}

export function fetchGroupItem(database: Database) {
  return async ([_key, item]: [typeof groupQuery.item, GroupItem]) => {
    return await getGroupItemInfo(database, item)
  }
}

async function getGroupItemInfo(database: Database, item: GroupItem): Promise<GroupableAsset> {
  const { assetId, assetType, subtype } = item
  switch (assetType) {
    case AssetType.CashAndBanking: {
      const account = await database.cashAndBanking.getAccountById(assetId, subtype as Account.Type)
      const isMultiCurrency = subtype == Account.Type.SavingAccount || subtype == Account.Type.CurrentAccount
      const balanceList: Amount[] = isMultiCurrency
        ? (account as SavingAccount | CurrentAccount).subAccounts.map((account) => account.balance)
        : [account.value]
      const baseCurrency = database.ExRate.BaseCurrency!
      const value: Amount = {
        currency: baseCurrency,
        value: balanceList.reduce((acc, curr) => database.ExRate.amountToBase(curr).value + acc, 0)
      }
      const updateAt = account.updateAt
      // NOTE: updateAt now is a Date type instead of FirestoreTimestamp
      return { ...item, name: account.name, mainImage: account.mainImage, value, updateAt }
    }
    case AssetType.TraditionalInvestments: {
      const portfolio = await database.traditionalInvestment.getPortfolioById(assetId)
      // FIXME get portfolio value
      const value = database.ExRate.amountToBase({ currency: Currency.USD, value: 0 })
      const updateAt = portfolio.updateAt
      return { ...item, name: portfolio.name, mainImage: portfolio.mainImage, value, updateAt }
    }
    case AssetType.OtherInvestment: {
      const investment = await database.otherInvestment.getById(assetId)
      const value = database.ExRate.amountToBase(investment.value)
      const updateAt = investment.updateAt
      return { ...item, name: investment.name, mainImage: investment.mainImage, value, updateAt }
    }
    case AssetType.Insurance: {
      const insurance = await database.insurance.getById(assetId)
      const value = database.ExRate.amountToBase(insurance.value)
      const updateAt = insurance.updateAt
      return { ...item, name: insurance.name, mainImage: insurance.mainImage, value, updateAt }
    }
    case AssetType.Property: {
      const property = await database.property.getById(assetId)
      const value = database.ExRate.amountToBase(property.value)
      const updateAt = property.updateAt
      return { ...item, name: property.name, mainImage: property.mainImage, value, updateAt }
    }
    case AssetType.Art: {
      const art = await database.art.getById(assetId)
      const value = database.ExRate.amountToBase(art.value)
      const { locationName, roomName } = await getLocationName(database, art.location)
      const updateAt = art.updateAt
      return { ...item, name: art.name, mainImage: art.mainImage, value, updateAt, locationName, roomName }
    }
    case AssetType.OtherCollectables: {
      const other = await database.otherCollectable.getById(assetId)
      const value = database.ExRate.amountToBase(other.value)
      const { locationName, roomName } = await getLocationName(database, other.location)
      const updateAt = other.updateAt
      return { ...item, name: other.name, mainImage: other.mainImage, value, updateAt, locationName, roomName }
    }
    case AssetType.Belonging: {
      const belonging = await database.belonging.getById(assetId)
      const value = database.ExRate.amountToBase(belonging.value)
      const { locationName, roomName } = await getLocationName(database, belonging.location)
      const updateAt = belonging.updateAt
      return { ...item, name: belonging.name, mainImage: belonging.mainImage, value, updateAt, locationName, roomName }
    }
  }
}

async function getLocationName(database: Database, location: LocationInfo) {
  const { locationType, locationId, roomId } = location

  switch (locationType) {
    case LocationType.MyProperty: {
      const property = await database.property.getById(locationId)
      const { bedroom = [], bathroom = [], otherRoom = [], carPark = [] } = property.configuration ?? {}
      const rooms = [bedroom, bathroom, otherRoom, carPark].flat()
      return {
        locationName: property.name,
        roomName: roomId ? rooms.find(({ id }) => id === roomId)?.name : undefined
      }
    }
    case LocationType.Address:
    case LocationType.NewAddress: {
      const contact = await database.Account.getContact(locationId)
      const rooms = contact.room ?? []
      return {
        locationName: getFullName(contact),
        roomName: roomId ? rooms.find(({ id }) => id === roomId)?.name : undefined
      }
    }
  }
}

async function getGroupDraftInfo(database: Database, items: GroupItem[]): Promise<GroupableAsset[]> {
  if (items.length === 0) return []
  let groupDrafts: Art.Encrypted[] | BelongingsUtils.DraftDisplay[]
  const assetType = items[0].assetType

  switch (assetType) {
    case AssetType.Art:
      groupDrafts = await database.art.getAllEncryptedDraftStates()
      break
    case AssetType.OtherCollectables:
      groupDrafts = await database.otherCollectable.getAllEncryptedDraftStates()
      break
    case AssetType.Belonging:
      groupDrafts = await database.belonging.getAllEncryptedDraftStates()
    default:
      groupDrafts = await database.belonging.getAllEncryptedDraftStates()
  }

  return items.map((item): GroupableAsset => {
    const { name = '', value, updateAt = new Date() } = groupDrafts.find((draft) => item.assetId === draft.id) ?? {}
    return {
      ...item,
      name,
      updateAt,
      value: database.ExRate.amountToBase({
        currency: value?.currency ?? database.ExRate.BaseCurrency!,
        value: value?.value ?? 0
      }),
      mainImage: ''
    }
  })
}

export async function addGroup(database: Database, data: CreateGroupValues, newItems: GroupItem[]) {
  const id = database.genAssetId()
  const group = { id, ...data }
  await database.group.add(group, newItems)
  await delay()
  mutate([groupQuery.list])
  mutate([groupQuery.options])
  return id
}

export async function updateGroup(
  database: Database,
  data: GroupInfo,
  itemsAdded: GroupItem[],
  itemsRemoved: GroupItem[],
  currency?: Currency
) {
  await database.group.update(data, itemsAdded, itemsRemoved)
  await delay()
  mutate([groupQuery.info, data.id])
  currency && mutate([groupQuery.list, currency]) // #NOTE: update group list only on groups/summary/index page
}

export async function deleteGroup(database: Database, id: string) {
  const group = await database.group.getById(id)
  if (group.info.isDraft) {
    // If all drafts are removed, the group will be automatically deleted
    // Group with drafts can't be directly
    const assetType = group.items[0].assetType
    switch (assetType) {
      case AssetType.Art: {
        for (const { assetId } of group.items) {
          await database.art.deleteDraft(assetId)
        }
        break
      }
      case AssetType.OtherCollectables: {
        for (const { assetId } of group.items) {
          await database.otherCollectable.deleteDraft(assetId)
        }
        break
      }
      case AssetType.Belonging: {
        for (const { assetId } of group.items) {
          await database.belonging.deleteDraft(assetId)
        }
        break
      }
    }
  } else {
    const task = await database.group.delete(id)
    await task.run()
  }

  await delay()
  mutate([groupQuery.list])
}
export async function updateGroupsAssets(database: Database, id: string, assetsIds: string[], assetType: Groupable) {
  const group = await database.group.getById(id)
  const assets = await Promise.all(
    assetsIds.map((id) => {
      switch (assetType) {
        case AssetType.Art:
          return database.art.getById(id)
        case AssetType.OtherCollectables:
          return database.otherCollectable.getById(id)
        case AssetType.Belonging:
          return database.belonging.getById(id)
        case AssetType.Property:
          return database.property.getById(id)
        default:
          throw new Error('Asset type not expected')
      }
    })
  )
  const items = assets.map(({ id: assetId, subtype }) => ({ assetId, assetType, subtype }))
  await database.group.update(group.info, items, [])

  await delay()
  mutate([groupQuery.info, id])
  mutate([groupQuery.list])
}

export async function copyGroup(database: Database, groupId: string, items: GroupItem[]) {
  const group = await database.group.getById(groupId)
  await database.group.update(group.info, items, [])
  await delay()
  mutate([groupQuery.info, groupId])
  mutate([groupQuery.list])
}

export async function moveGroup(database: Database, fromGroupId: string, toGroupId: string, items: GroupItem[]) {
  if (fromGroupId === toGroupId) return
  const fromGroup = await database.group.getById(fromGroupId)
  const toGroup = await database.group.getById(toGroupId)
  await database.group.update(toGroup.info, items, [])
  await database.group.update(fromGroup.info, [], items)

  await delay()
  mutate([groupQuery.info, fromGroupId])
  mutate([groupQuery.info, toGroupId])
  mutate([groupQuery.list])
}

export async function relocateGroup(
  database: Database,
  groupId: string,
  selected: GroupItem[],
  newLocation: LocationInfo,
  callback?: (percentage: number) => void
) {
  let progress: Record<'current' | 'total', number> = { current: 0, total: selected.length }
  let successCount: number = 0

  const isNewAddress = newLocation.locationType === LocationType.NewAddress
  if (isNewAddress) newLocation.locationType = LocationType.Address

  for (const { assetId, assetType } of selected) {
    switch (assetType) {
      case AssetType.Belonging: {
        const belongings = await database.belonging.getById(assetId)
        const oldLocation = belongings.location
        if (oldLocation.locationId === newLocation.locationId) continue

        await database.belonging.update({ ...belongings, location: newLocation })
        successCount += 1
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      case AssetType.Art: {
        const arts = await database.art.getById(assetId)
        const oldLocation = arts.location
        if (oldLocation.locationId === newLocation.locationId || arts.closedWith) continue

        await database.art.update({ ...arts, location: newLocation })
        successCount += 1
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      case AssetType.OtherCollectables: {
        const otherCollectables = await database.otherCollectable.getById(assetId)
        const oldLocation = otherCollectables.location
        if (oldLocation.locationId === newLocation.locationId) continue

        await database.otherCollectable.update({ ...otherCollectables, location: newLocation })
        successCount += 1
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      case AssetType.Property: {
        // pass, property can't be relocated
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
    }
  }
  await delay()
  mutate([groupQuery.info, groupId])
  return successCount
}
