import { useEffect, useMemo, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FactorId } from 'firebase/auth'
import { motion } from 'framer-motion'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { cn } from '@/utils/classnames'
import { parseErrorMessage } from '@/utils/parseErrorMessages'
import { useToast } from '@/hooks/useToast'
import { useTrackedCallback } from '@/hooks/useTracking'
import { useAuthStore } from '@/store/authStore'
import { Policies } from '@/pages/auth/login'
import { Button, FormInput } from '@/components/base'

export default function TwoFactorTotpPage() {
  const { t } = useTranslation()
  const { toast } = useToast()
  const router = useRouter()
  const [submitting, setSubmitting] = useState(false)
  const { status, getMultiFactorResolver, loginWithTotp } = useAuthStore(
    useShallow((state) => ({
      status: state.status,
      getMultiFactorResolver: state.getMultiFactorResolver,
      loginWithTotp: state.loginWithTotp
    }))
  )
  const verificationId = useMemo(
    () => getMultiFactorResolver()?.hints.find((info) => info.factorId === FactorId.TOTP)?.uid,
    [getMultiFactorResolver]
  )

  useEffect(() => {
    switch (status) {
      case 'ready': {
        toast({ variant: 'success', description: t('auth:Toast.Success.SignIn') })
        router.push('/identity/')
        break
      }
      case 'unverified': {
        router.push('/auth/verify-email/')
        break
      }
      case 'closing': {
        router.push('/auth/reactivate/')
        break
      }
    }
  }, [router, status, t, toast])

  useEffect(() => {
    if (!verificationId) router.replace('/auth/login/')
  }, [verificationId, router])

  const onSubmit = useTrackedCallback(
    async (data: VerifyFormValues) => {
      try {
        setSubmitting(true)
        const { verificationCode } = data
        await loginWithTotp(verificationId!, verificationCode)
      } catch (e) {
        setSubmitting(false)
        const isTimeout = e instanceof Error && e.message.includes('TOTP_CHALLENGE_TIMEOUT')
        const errorMessage = isTimeout
          ? t('auth:SessionTimeout')
          : parseErrorMessage(e, 'Unknown error: Login with MFA TOTP')
        toast({ variant: 'error', description: errorMessage })
        router.replace('/auth/login/')
      }
    },
    [verificationId, loginWithTotp, toast, t, router],
    'login-with-mfa-totp'
  )

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={'relative grid h-screen w-full place-items-center'}
    >
      {submitting ? (
        <Loader2Icon className={'absolute animate-spin text-primary'} />
      ) : (
        <div>
          <div className={'flex w-full max-w-[440px] flex-col gap-4 rounded border border-grey/50 p-[50px] text-white'}>
            <VerifyCode onSubmit={onSubmit} />
            <Link className={'block text-center text-sm text-primary hover:text-secondary-hover'} href={'/auth/login'}>
              {t('auth:BackToLogin')}
            </Link>
            <div className={'h-px w-full bg-grey'} />
            <Link
              className={'block text-center text-sm text-primary hover:text-secondary-hover'}
              href={'/auth/two-factor'}
            >
              {'Try other methods'}
            </Link>
          </div>
          <Policies />
        </div>
      )}
    </motion.main>
  )
}

TwoFactorTotpPage.isAuthRoute = true

export interface VerifyFormValues {
  verificationCode: string
}

const defaultValues: VerifyFormValues = {
  verificationCode: ''
}

interface VerifyCodeProps {
  onSubmit: SubmitHandler<VerifyFormValues>
}

export function VerifyCode({ onSubmit }: VerifyCodeProps) {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm<VerifyFormValues>({ defaultValues })

  return (
    <form className={'space-y-4'} onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex w-full flex-col gap-[10px] pb-1 pt-5'}>
        <h1 className={'text-[30px] font-bold leading-[35px]'}>{t('auth:TwoFactorVerification')}</h1>
        <span className={'text-sm'}>{t('auth:EnterTotpCode')}</span>
      </div>
      <FormInput
        control={control}
        name={'verificationCode'}
        placeholder={'Enter Code'}
        rules={{
          required: t('validation:Required'),
          minLength: 6,
          maxLength: 6
        }}
        maxLength={6}
      />

      <Button
        className={'group mb-4 w-full'}
        variant={'solid'}
        size={'md'}
        type={'submit'}
        disabled={!isValid || isSubmitting}
      >
        {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
        <span className={cn({ 'opacity-0': isSubmitting })}>{t('Verify')}</span>
      </Button>
    </form>
  )
}
