import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2222%22%20viewBox%3D%220%200%2018%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8.19%200.36L1.19%203.47C0.47%203.79%200%204.51%200%205.3V10C0%2015.55%203.84%2020.74%209%2022C14.16%2020.74%2018%2015.55%2018%2010V5.3C18%204.51%2017.53%203.79%2016.81%203.47L9.81%200.36C9.3%200.13%208.7%200.13%208.19%200.36ZM9%2010.99H16C15.47%2015.11%2012.72%2018.78%209%2019.93V11H2V5.3L9%202.19V10.99Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E)
 */
export default function PasswordBreachedIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg width={size} height={size} viewBox={'0 0 18 22'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
      <path
        d={
          'M8.19 0.36L1.19 3.47C0.47 3.79 0 4.51 0 5.3V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V5.3C18 4.51 17.53 3.79 16.81 3.47L9.81 0.36C9.3 0.13 8.7 0.13 8.19 0.36ZM9 10.99H16C15.47 15.11 12.72 18.78 9 19.93V11H2V5.3L9 2.19V10.99Z'
        }
        fill={'white'}
      />
    </svg>
  )
}
