import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%200.48%200.48%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M0.414%200.414a0.02%200.02%200%200%200%200%20-0.028l-0.32%20-0.32a0.02%200.02%200%200%200%20-0.028%200.028L0.104%200.132C0.056%200.167%200.03%200.214%200.03%200.24c0%200.045%200.075%200.15%200.21%200.15%200.038%200%200.072%20-0.008%200.1%20-0.022l0.046%200.046a0.02%200.02%200%200%200%200.028%200m-0.105%20-0.076%20-0.035%20-0.035A0.08%200.08%200%200%201%200.168%200.196L0.133%200.161a0.18%200.18%200%200%200%20-0.022%200.017C0.086%200.201%200.07%200.228%200.07%200.24c0%200.012%200.016%200.039%200.041%200.062C0.145%200.332%200.189%200.35%200.24%200.35a0.2%200.2%200%200%200%200.07%20-0.012zm0.097%20-0.016C0.435%200.292%200.45%200.26%200.45%200.24c0%20-0.045%20-0.075%20-0.15%20-0.21%20-0.15%20-0.021%200%20-0.04%200.003%20-0.058%200.007l0.034%200.034Q0.228%200.13%200.24%200.13c0.051%200%200.095%200.018%200.129%200.048%200.025%200.023%200.041%200.05%200.041%200.062%200%200.01%20-0.012%200.033%20-0.032%200.053z%22%20fill%3D%22%23000000%22%2F%3E%3C%2Fsvg%3E)
 */
export default function EyeOffIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={'0 0 24 24'}
      fill={'currentColor'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M20.707 20.707a1 1 0 0 0 0-1.414l-16-16a1 1 0 0 0-1.414 1.414L5.205 6.62C2.785 8.338 1.5 10.683 1.5 12c0 2.25 3.75 7.5 10.5 7.5 1.916 0 3.59-.423 5.006-1.08l2.287 2.287a1 1 0 0 0 1.414 0zm-5.228-3.814-1.774-1.774A4 4 0 0 1 8.38 9.794L6.644 8.058a9.332 9.332 0 0 0-1.09.84C4.28 10.034 3.5 11.417 3.5 12c0 .584.781 1.966 2.052 3.103C7.231 16.605 9.438 17.5 12 17.5c1.25 0 2.417-.213 3.48-.607zm4.841-.815C21.751 14.61 22.5 13.006 22.5 12c0-2.25-3.75-7.5-10.5-7.5-1.043 0-2.015.125-2.912.346l1.721 1.72c.388-.043.785-.066 1.191-.066 2.562 0 4.77.895 6.448 2.397 1.27 1.137 2.052 2.52 2.052 3.103 0 .51-.596 1.63-1.595 2.663l1.415 1.415z'
        }
      />
    </svg>
  )
}
