import { Art, Command, Event } from "../types/arts";
import {
  AggregateRoot,
  AssetRelationStateWriter,
  Repo,
  buildUpdateGroupCommand,
  newRepo as newAggregateRepo,
} from "../types/aggregate";
import { AssetType, Optional, LocationType, Currency } from "../types/common";
import { EncryptionFieldKey, EncryptionField } from "../encryption/utils";
import { UpdateObject } from "../utils";
import { PropertiesRepo } from "./properties";
import { ArtSummary } from "../types/artSummary";
import { ExchangeRate } from "../database/exchangeRate";
import { Valuation } from "../types/actions/valuation";
import {
  actionRefFromAssetDocRef,
  buildAddActionCommand,
  buildAddValuationCommand,
  buildDeleteActionCommand,
  buildDeleteSoldInfoCommand,
  buildDeleteValuationCommand,
  buildMarkAsSoldCommand,
  buildUpdateActionCommand,
  buildUpdateSoldInfoCommand,
  buildUpdateValuationCommand,
  doGetAllActions,
} from "./actions";
import { RoleToAsset } from "../types/relations";
import { FullRefs, Refs, getAssetDraftsByIds, getAssetsByIds } from "../refs";
import { InsuranceRepo } from "./insurance";
import { GroupUpdater } from "./groups";
import { Encryption, EncryptionManager } from "./encryption";
import { SoldInfo } from "../types/actions/soldInfo";
import {
  Action,
  ActionTypeBundleExtend,
  ConsignmentBundle,
  ExhibitionBundle,
  LiteratureBundle,
  OfferBundle,
} from "../types/actions";
import { ActionType } from "../types/actions/base";
import { Offer } from "../types/actions/offer";
import { Consignment } from "../types/actions/consignment";
import { Exhibition } from "../types/actions/exhibition";
import { Literature } from "../types/actions/literature";
import { DataPoisoned } from "../types/error";
import { SummaryManager } from "../types/summaryManager";
import { DbSharedFields } from "../types/database";
import {
  CoreFirestore,
  DocumentReference,
  Transaction,
  checkAndGetData,
  checkDuplicated,
  getQueriedData,
} from "../../coreFirebase";
import { TypeResult } from "../types/typeVersion";
import { CashAndBankingRepo } from "./cashAndBanking";
import { LocationInfo } from "../types/relations/locationInfo";
import { DraftItemData } from "../types/groups";

export class ArtsRepo {
  protected readonly refs: FullRefs;
  protected readonly exRate: ExchangeRate;
  protected readonly summaryManager: SummaryManager;

  // protected readonly summaryRef: DocumentReference<ArtSummary>;

  readonly Encryption: EncryptionManager;

  constructor(shared: DbSharedFields) {
    this.exRate = shared.exRate;
    this.refs = shared.refs;
    this.Encryption = shared.encryption;
    this.summaryManager = shared.summaryManager;
  }

  async getSyncedSummary(currency?: Currency) {
    const summary = (
      await this.summaryManager.get(AssetType.Art).syncAndGetData()
    ).summary;
    this.exRate.checkInitialized();
    const exRate = await this.exRate.getToTargetExchangeRates(
      currency || this.exRate.BaseCurrency!
    );
    return ArtSummary.toDisplay(summary, exRate);
  }

  private actionDocRef(id: string, valuationId: string) {
    return CoreFirestore.docFromCollection(
      this.refs.currentRefs.getActionCollectionRef<Art.WriteActionEncrypted>(
        AssetType.Art,
        id
      ),
      valuationId
    );
  }

  //#NOTE read before this function and set/update after this function
  private async handleRelatedAggregates(
    transaction: Transaction,
    aggregateRoot: AggregateRoot<Art.Encrypted, Command, Event>,
    isDelete?: boolean
  ) {
    const asset = aggregateRoot.state();
    const data = aggregateRoot.relatedUpdates() as Art.RelatedUpdates;
    const repoAndAggregates: Art.RelatedAggregates = {};
    let groupUpdater: Optional<GroupUpdater> = undefined;
    if (data.addedGroupIds || data.removedGroupIds) {
      groupUpdater = new GroupUpdater(
        this.refs.currentRefs,
        (data.addedGroupIds || []).concat(data.removedGroupIds || [])
      );
      await groupUpdater.read(transaction);
    }
    if (data.addedInsuranceIds || data.removedInsuranceIds) {
      repoAndAggregates.insurance = {
        repo: await InsuranceRepo.newRepo(this.refs.currentRefs, transaction),
        aggregates: [],
      };
      if (data.addedInsuranceIds) {
        for (const insuranceId of data.addedInsuranceIds) {
          repoAndAggregates.insurance!.aggregates.push(
            await InsuranceRepo.newArAndUpdateInsured(
              this.refs.currentRefs,
              transaction,
              this.refs.selfRefs.userId,
              insuranceId,
              asset.id,
              AssetType.Art
            )
          );
        }
      }
      if (data.removedInsuranceIds) {
        for (const insuranceId of data.removedInsuranceIds) {
          repoAndAggregates.insurance!.aggregates.push(
            await InsuranceRepo.newArAndUpdateInsured(
              this.refs.currentRefs,
              transaction,
              this.refs.selfRefs.userId,
              insuranceId,
              asset.id,
              AssetType.Art,
              false
            )
          );
        }
      }
    }
    if (isDelete) {
      repoAndAggregates.cashAndBanking = {
        repo: await CashAndBankingRepo.newRepo(
          this.refs.currentRefs,
          transaction
        ),
        aggregates: await CashAndBankingRepo.newArAndUpdateAllocatedLiability(
          this.refs.currentRefs,
          transaction,
          this.refs.selfRefs.userId,
          asset.id
        ),
      };
    }
    // * writes
    if (data.addedGroupIds && groupUpdater) {
      for (const groupId of data.addedGroupIds) {
        groupUpdater.addOneItemToGroup(
          transaction,
          groupId,
          asset.id,
          AssetType.Art,
          asset.subtype
        );
      }
    }
    if (data.removedGroupIds && groupUpdater) {
      for (const groupId of data.removedGroupIds) {
        groupUpdater.deleteOneItemFromGroup(transaction, groupId, asset.id);
      }
    }
    if (repoAndAggregates.insurance) {
      const insuranceData = repoAndAggregates.insurance;
      insuranceData.aggregates.forEach((aggregate) => {
        insuranceData.repo.commitWithState(aggregate);
      });
    }
    if (repoAndAggregates.cashAndBanking) {
      const accountData = repoAndAggregates.cashAndBanking;
      accountData.aggregates.forEach((aggregate) => {
        accountData.repo.commitWithState(aggregate);
      });
    }

    if (data.setActions) {
      data.setActions.forEach((action) => {
        const docRef = this.actionDocRef(asset.id, action.id);
        action.ownerId = this.refs.currentRefs.userId;
        transaction.set(docRef, action);
      });
    }
    if (data.removedActionIds) {
      data.removedActionIds.forEach((id) => {
        const docRef = this.actionDocRef(asset.id, id);
        transaction.delete(docRef);
      });
    }
  }

  async getAll(): Promise<Art[]> {
    const collectionRef =
      this.refs.currentRefs.getAssetCollectionRef<Art.Encrypted>(AssetType.Art);
    const assets = await Promise.all(
      (
        await CoreFirestore.getDocsFromCollection(
          collectionRef,
          CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId)
        ).then(getQueriedData)
      ).map((v) => Art.decryptAndConvertDate(v, this.Encryption.current))
    );
    return assets.sort((a, b) => b.updateAt.getTime() - a.updateAt.getTime());
  }

  async getByIds(ids: string[]): Promise<Art[]> {
    const result = await getAssetsByIds<Art.Encrypted>(
      this.refs.currentRefs,
      AssetType.Art,
      ids
    );
    return Promise.all(
      result.map((v) => Art.decryptAndConvertDate(v, this.Encryption.current))
    );
  }

  async getById(id: string): Promise<Art> {
    const docRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      id
    );
    return await CoreFirestore.getDoc(docRef)
      .then(checkAndGetData)
      .then((v) => Art.decryptAndConvertDate(v, this.Encryption.current));
  }

  async addDraft(req: Art.Draft["req"], groupId: string) {
    const newDocRef =
      this.refs.currentRefs.getAssetDraftDocRef<Art.EncryptedDraftWithState>(
        AssetType.Art,
        req.id
      );
    const artWithEmptyLocation: Art.CreateFields = {
      ...req,
      location: LocationInfo.emptyLocation,
    };

    await CoreFirestore.runTransaction(async (transaction) => {
      await transaction.get(newDocRef).then(checkDuplicated);
      const art = Art.fromCreate(
        artWithEmptyLocation,
        this.refs.currentRefs.userId
      );
      const valuation = await Valuation.getSystemCreationEncrypted(
        CoreFirestore.genAssetId(),
        this.refs.currentRefs.userId,
        art.value,
        this.Encryption.current
      );
      Art.validateEncryptedPart(art, true);
      const groupUpdater = new GroupUpdater(this.refs.currentRefs, [groupId]);
      await groupUpdater.read(transaction);

      const ar = Art.newAggregateRoot(Art.defaultStateValue());
      const encrypted = await Art.encrypt(art, this.Encryption.current);
      ar.handle(
        Command.createAsset(this.refs.selfRefs.userId, encrypted, valuation)
      );
      const events = ar.applyAllChanges();
      const encryptedDraft = await Art.encryptDraft(
        {
          req,
          ownerId: this.refs.currentRefs.userId,
          groupId,
        },
        this.Encryption.current
      );
      transaction.set(newDocRef, {
        ...encryptedDraft,
        state: ar.state(),
      });
      let maybeEvent = events.find(
        (v) => v.data.kind === Event.Kind.ValueUpdated
      );
      let updateDraftItemData: Optional<DraftItemData> = undefined;
      if (maybeEvent !== undefined) {
        const valueUpdated = maybeEvent.data as Event.ValueUpdated;
        updateDraftItemData = {
          assets: valueUpdated.current,
        };
      }
      groupUpdater.addOneItemToGroup(
        transaction,
        groupId,
        req.id,
        AssetType.Art,
        req.subtype,
        updateDraftItemData
      );
    });
  }

  async deleteDraft(id: string) {
    const docRef =
      this.refs.currentRefs.getAssetDraftDocRef<Art.EncryptedDraftWithState>(
        AssetType.Art,
        id
      );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const groupId = currentData.groupId;
      const groupUpdater = new GroupUpdater(this.refs.currentRefs, [groupId]);
      await groupUpdater.read(transaction);
      await groupUpdater.readItemsOfGroup(transaction, groupId, [id]);

      groupUpdater.deleteOneItemFromGroup(transaction, groupId, id);
      transaction.delete(docRef);
    });
  }

  // Info in encrypted state is enough for display
  async getEncryptedDraftStateById(id: string): Promise<Art.Encrypted> {
    const docRef =
      this.refs.currentRefs.getAssetDraftDocRef<Art.EncryptedDraftWithState>(
        AssetType.Art,
        id
      );
    const data = await CoreFirestore.getDoc(docRef).then(checkAndGetData);
    CoreFirestore.convertDateFieldsFromFirestore(data.state, Art.datePaths);
    return data.state;
  }

  // Info in encrypted state is enough for display
  async getAllEncryptedDraftStates(): Promise<Art.Encrypted[]> {
    const collectionRef =
      this.refs.currentRefs.getAssetDraftCollectionRef<Art.EncryptedDraftWithState>(
        AssetType.Art
      );
    const data = await CoreFirestore.getDocsFromCollection(
      collectionRef,
      CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId)
    ).then(getQueriedData);
    data.map((v) =>
      CoreFirestore.convertDateFieldsFromFirestore(v.state, Art.datePaths)
    );
    return data
      .map((v) => v.state)
      .sort((a, b) => b.updateAt.getTime() - a.updateAt.getTime());
  }

  async addFromDrafts(ids: string[], location: LocationInfo) {
    const result = await getAssetDraftsByIds<Art.EncryptedDraftWithState>(
      this.refs.currentRefs,
      AssetType.Art,
      ids
    );
    const requests: Art.CreateFields[] = (
      await Promise.all(
        result.map((v) =>
          Art.decryptDraftWithStateAndConvertDate(v, this.Encryption.current)
        )
      )
    ).map((v) => ({ ...v.req, location }));
    for (const req of requests) {
      const draftDocRef =
        this.refs.currentRefs.getAssetDraftDocRef<Art.EncryptedDraftWithState>(
          AssetType.Art,
          req.id
        );
      const newDocRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
        AssetType.Art,
        req.id
      );
      await CoreFirestore.runTransaction(async (transaction) => {
        const draft = await transaction.get(draftDocRef).then(checkAndGetData);
        const groupId = draft.groupId;
        const groupUpdater = new GroupUpdater(this.refs.currentRefs, [groupId]);
        await groupUpdater.read(transaction);
        await groupUpdater.readItemsOfGroup(transaction, groupId, [req.id]);

        await this.addInTransaction(transaction, newDocRef, req);
        transaction.delete(draftDocRef);
        groupUpdater.deleteOneItemFromGroup(transaction, groupId, req.id);
      });
    }
  }

  private async addInTransaction(
    transaction: Transaction,
    newDocRef: DocumentReference<Art.Encrypted>,
    req: Art.CreateFields
  ) {
    await transaction.get(newDocRef).then(checkDuplicated);
    if (req.location.locationType == LocationType.MyProperty)
      await PropertiesRepo.checkActiveAndGet(
        this.refs.currentRefs,
        transaction,
        req.location.locationId
      );
    const repo = await ArtsRepo.newRepo(this.refs.currentRefs, transaction);

    const art = Art.fromCreate(req, this.refs.currentRefs.userId);
    const valuation = await Valuation.getSystemCreationEncrypted(
      CoreFirestore.genAssetId(),
      this.refs.currentRefs.userId,
      art.value,
      this.Encryption.current
    );
    Art.validateEncryptedPart(art, true);
    const ar = Art.newAggregateRoot(Art.defaultStateValue());
    const encrypted = await Art.encrypt(art, this.Encryption.current);
    ar.handle(
      Command.createAsset(this.refs.selfRefs.userId, encrypted, valuation)
    );
    const events = ar.applyAllChanges();
    await this.handleRelatedAggregates(transaction, ar);
    //commit
    repo.manualCommit(ar, events);
  }

  async add(req: Art.CreateFields) {
    const newDocRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      req.id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      await this.addInTransaction(transaction, newDocRef, req);
    });
  }

  async update(req: Art) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      req.id
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Art.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) Art.handleOutDated();
      if (
        req.location.locationType == LocationType.MyProperty &&
        req.location.locationId !== currentUndecrypted.location.locationId
      )
        await PropertiesRepo.checkActiveAndGet(
          this.refs.currentRefs,
          transaction,
          req.location.locationId
        );
      const currentData = await Art.decryptAndConvertDate(
        currentUndecrypted,
        this.Encryption.current
      );
      const repo = await ArtsRepo.newRepo(this.refs.currentRefs, transaction);

      const {
        updates: artUpdate,
        metadata: {
          addedToGroup,
          removedFromGroup,
          newImages,
          newMainImage,
          removedImages,
          locationPrimaryDetailsUpdated,
        },
      } = Art.intoUpdate(currentData, req);
      const encryptedFieldsUpdated = Art.encryptedKeysArray.reduce(
        (result, key) => result || Object.keys(artUpdate).includes(key),
        false
      );

      const encryptedUpdate: UpdateObject<Art.Encrypted> =
        Art.removeEncryptedFields(artUpdate);
      let encryptionPart: Optional<Art.EncryptedPart> = undefined;
      if (encryptedFieldsUpdated) {
        encryptionPart = {};
        if (artUpdate.notes !== null) {
          encryptionPart.notes = artUpdate.notes || currentData.notes;
        }
        if (artUpdate.attributeNotes !== null) {
          encryptionPart.attributeNotes =
            artUpdate.attributeNotes || currentData.attributeNotes;
        }
      }

      const newEncrypted = await Art.encryptUpdate(
        encryptionPart,
        artUpdate.location,
        artUpdate.acquisition,
        artUpdate.attachments,
        this.Encryption.current
      );
      encryptedUpdate[EncryptionFieldKey] = newEncrypted[EncryptionFieldKey];
      encryptedUpdate.location = newEncrypted.location;
      encryptedUpdate.acquisition = newEncrypted.acquisition;
      encryptedUpdate.attachments = newEncrypted.attachments;

      const ar = Art.newAggregateRoot(currentUndecrypted);
      ar.handle(
        Command.updateAsset(
          this.refs.selfRefs.userId,
          encryptedUpdate,
          addedToGroup,
          removedFromGroup,
          newImages,
          newMainImage,
          removedImages,
          locationPrimaryDetailsUpdated
        )
      );
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async delete(id: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Art.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Art.handleOutDated();
      const repo = await ArtsRepo.newRepo(this.refs.currentRefs, transaction);
      const ar = Art.newAggregateRoot(currentData);
      ar.handle(Command.deleteAsset(this.refs.selfRefs.userId));
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar, true);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async getActionById(assetId: string, actionId: string): Promise<Action> {
    const docRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      assetId
    );
    const actionCollectionRef = actionRefFromAssetDocRef(docRef);
    const actionDocRef = CoreFirestore.docFromCollection(
      actionCollectionRef,
      actionId
    );
    return await CoreFirestore.getDoc(actionDocRef)
      .then(checkAndGetData)
      .then((v) => Action.decryptAndConvertDate(v, this.Encryption.current));
  }

  async getAllActions(assetId: string): Promise<Action[]> {
    const docRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      assetId
    );
    return doGetAllActions(
      docRef,
      this.refs.currentRefs.userId,
      this.Encryption.current
    );
  }

  async addValuation(assetId: string, createFields: Valuation.CreateFields) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Art.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Art.handleOutDated();
      const command = await buildAddValuationCommand(
        docRef,
        transaction,
        this.refs.currentRefs.userId,
        this.refs.selfRefs.userId,
        createFields,
        this.Encryption.current,
        createFields.updateValue
      );

      const repo = await ArtsRepo.newRepo(this.refs.currentRefs, transaction);

      const ar = Art.newAggregateRoot(currentData);
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async updateValuation(assetId: string, updateFields: Valuation.Update) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Art.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Art.handleOutDated();
      const { command, valuation } = await buildUpdateValuationCommand(
        docRef,
        transaction,
        this.refs.currentRefs.userId,
        updateFields.id,
        updateFields,
        this.Encryption.current,
        currentData.valueSourceId
      );

      const repo = await ArtsRepo.newRepo(this.refs.currentRefs, transaction);

      const ar = Art.newAggregateRoot(currentData, {
        actions: {
          [updateFields.id]: valuation,
        },
      });
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async deleteValuation(assetId: string, valuationId: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      assetId
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Art.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Art.handleOutDated();
      const command = await buildDeleteValuationCommand(
        docRef,
        transaction,
        this.refs.selfRefs.userId,
        valuationId,
        this.Encryption.current
      );

      const repo = await ArtsRepo.newRepo(this.refs.currentRefs, transaction);

      const ar = Art.newAggregateRoot(currentData);
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async markAsSold(assetId: string, createFields: SoldInfo.CreateFields) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Art.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Art.handleOutDated();
      const command = await buildMarkAsSoldCommand(
        docRef,
        transaction,
        this.refs.currentRefs.userId,
        this.refs.selfRefs.userId,
        createFields,
        this.Encryption.current
      );

      const repo = await ArtsRepo.newRepo(this.refs.currentRefs, transaction);

      const ar = Art.newAggregateRoot(currentData);
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async updateSoldInfo(assetId: string, updateFields: SoldInfo.Update) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Art.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Art.handleOutDated();
      const { command, soldInfo } = await buildUpdateSoldInfoCommand(
        docRef,
        transaction,
        this.refs.currentRefs.userId,
        updateFields,
        this.Encryption.current
      );

      const repo = await ArtsRepo.newRepo(this.refs.currentRefs, transaction);

      const ar = Art.newAggregateRoot(currentData, {
        actions: {
          [updateFields.id]: soldInfo,
        },
      });
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async deleteSoldInfo(assetId: string, soldInfoId: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      assetId
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Art.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Art.handleOutDated();
      const { command, soldInfo } = await buildDeleteSoldInfoCommand(
        docRef,
        transaction,
        this.refs.selfRefs.userId,
        soldInfoId
      );

      const repo = await ArtsRepo.newRepo(this.refs.currentRefs, transaction);

      const ar = Art.newAggregateRoot(currentData, {
        actions: {
          [soldInfoId]: soldInfo,
        },
      });
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  private async addAction<T extends ActionTypeBundleExtend<Art.SupportActions>>(
    assetId: string,
    createFields: T["Create"],
    fromCreate: (createFields: T["Create"], ownerId: string) => T["Original"],
    validateEncryptedPart: (data: T["EncryptedPart"]) => void,
    encrypt: (
      rawData: T["Original"],
      encryption: Encryption
    ) => Promise<T["Encrypted"]>
  ) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Art.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Art.handleOutDated();
      const command = await buildAddActionCommand<T>(
        docRef,
        transaction,
        this.refs.currentRefs.userId,
        this.refs.selfRefs.userId,
        createFields,
        this.Encryption.current,
        fromCreate,
        validateEncryptedPart,
        encrypt
      );

      const repo = await ArtsRepo.newRepo(this.refs.currentRefs, transaction);

      const ar = Art.newAggregateRoot(currentData);
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  private async updateAction<
    T extends ActionTypeBundleExtend<Art.SupportActions>
  >(
    assetId: string,
    actionType: T["Type"],
    encryptedKeysArray: readonly (keyof T["EncryptedPart"])[],
    updateFields: T["Update"],
    validateEncryptedPart: (data: T["EncryptedPart"]) => void,
    encryptPartial: <U extends T["EncryptedPart"]>(
      rawData: U,
      encryption: Encryption
    ) => Promise<EncryptionField>,
    decrypt: (
      input: T["Encrypted"],
      encryption: Encryption
    ) => Promise<T["Original"]>,
    intoUpdate: (
      current: T["Original"],
      update: T["Update"]
    ) => UpdateObject<T["Update"]>
  ) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Art.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Art.handleOutDated();
      const { command, action } = await buildUpdateActionCommand<T>(
        docRef,
        transaction,
        this.refs.selfRefs.userId,
        updateFields,
        this.Encryption.current,
        actionType,
        encryptedKeysArray,
        validateEncryptedPart,
        encryptPartial,
        decrypt,
        intoUpdate
      );

      const repo = await ArtsRepo.newRepo(this.refs.currentRefs, transaction);

      const ar = Art.newAggregateRoot(currentData, {
        actions: { [updateFields.id]: action },
      });
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  private async deleteAction<
    T extends ActionTypeBundleExtend<Art.SupportActions>
  >(
    assetId: string,
    actionId: string,
    actionType: T["Type"],
    decrypt: (
      input: T["Encrypted"],
      encryption: Encryption
    ) => Promise<T["Original"]>
  ) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Art.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Art.handleOutDated();
      const { command, action } = await buildDeleteActionCommand<T>(
        docRef,
        transaction,
        this.refs.selfRefs.userId,
        actionId,
        actionType,
        this.Encryption.current,
        decrypt
      );

      const repo = await ArtsRepo.newRepo(this.refs.currentRefs, transaction);

      const ar = Art.newAggregateRoot(currentData, {
        actions: { [actionId]: action },
      });
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async addOffer(assetId: string, createFields: Offer.CreateFields) {
    return this.addAction<OfferBundle>(
      assetId,
      createFields,
      Offer.fromCreate,
      Offer.validateEncryptedPart,
      Offer.encrypt
    );
  }
  async updateOffer(assetId: string, updateFields: Offer.Update) {
    return this.updateAction<OfferBundle>(
      assetId,
      ActionType.AddOffer,
      Offer.encryptedKeysArray,
      updateFields,
      Offer.validateEncryptedPart,
      Offer.encryptPartial,
      Offer.decryptAndConvertDate,
      Offer.intoUpdate
    );
  }
  async deleteOffer(assetId: string, offerID: string) {
    return this.deleteAction<OfferBundle>(
      assetId,
      offerID,
      ActionType.AddOffer,
      Offer.decrypt
    );
  }

  async addConsignment(
    assetId: string,
    createFields: Consignment.CreateFields
  ) {
    return this.addAction<ConsignmentBundle>(
      assetId,
      createFields,
      Consignment.fromCreate,
      Consignment.validateEncryptedPart,
      Consignment.encrypt
    );
  }
  async updateConsignment(assetId: string, updateFields: Consignment.Update) {
    return this.updateAction<ConsignmentBundle>(
      assetId,
      ActionType.AddConsignment,
      Consignment.encryptedKeysArray,
      updateFields,
      Consignment.validateEncryptedPart,
      Consignment.encryptPartial,
      Consignment.decryptAndConvertDate,
      Consignment.intoUpdate
    );
  }
  async deleteConsignment(assetId: string, consignmentID: string) {
    return this.deleteAction<ConsignmentBundle>(
      assetId,
      consignmentID,
      ActionType.AddConsignment,
      Consignment.decrypt
    );
  }

  async addExhibition(assetId: string, createFields: Exhibition.CreateFields) {
    return this.addAction<ExhibitionBundle>(
      assetId,
      createFields,
      Exhibition.fromCreate,
      Exhibition.validateEncryptedPart,
      Exhibition.encrypt
    );
  }
  async updateExhibition(assetId: string, updateFields: Exhibition.Update) {
    return this.updateAction<ExhibitionBundle>(
      assetId,
      ActionType.AddExhibition,
      Exhibition.encryptedKeysArray,
      updateFields,
      Exhibition.validateEncryptedPart,
      Exhibition.encryptPartial,
      Exhibition.decryptAndConvertDate,
      Exhibition.intoUpdate
    );
  }
  async deleteExhibition(assetId: string, exhibitionID: string) {
    return this.deleteAction<ExhibitionBundle>(
      assetId,
      exhibitionID,
      ActionType.AddExhibition,
      Exhibition.decrypt
    );
  }

  async addLiterature(assetId: string, createFields: Literature.CreateFields) {
    return this.addAction<LiteratureBundle>(
      assetId,
      createFields,
      Literature.fromCreate,
      Literature.validateEncryptedPart,
      Literature.encrypt
    );
  }
  async updateLiterature(assetId: string, updateFields: Literature.Update) {
    return this.updateAction<LiteratureBundle>(
      assetId,
      ActionType.AddLiterature,
      Literature.encryptedKeysArray,
      updateFields,
      Literature.validateEncryptedPart,
      Literature.encryptPartial,
      Literature.decryptAndConvertDate,
      Literature.intoUpdate
    );
  }
  async deleteLiterature(assetId: string, literatureID: string) {
    return this.deleteAction<LiteratureBundle>(
      assetId,
      literatureID,
      ActionType.AddLiterature,
      Literature.decrypt
    );
  }

  //#NOTE this cannot handle actions now, should rethink the rebuild process later
  // async rebuild(assetId: string) {
  //   await loopCatchupEvents(this.refs.currentRefs.userId, AssetType.Art, {
  //     [assetId]: ArtsRepo.newEventConsumer(this.refs.currentRefs, assetId),
  //   });
  // }
}

export namespace ArtsRepo {
  export async function relocate(
    refs: Refs,
    executerId: string,
    id: string,
    fromLocationId: string,
    toLocation: LocationInfo.Encrypted
  ) {
    const docRef = refs.getAssetDocRef<Art.Encrypted>(AssetType.Art, id);
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Art.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Art.handleOutDated();
      const repo = await newRepo(refs, transaction);
      const ar = Art.newAggregateRoot(currentData);
      ar.handle(Command.relocateAsset(executerId, fromLocationId, toLocation));
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  export async function removeGroup(
    refs: FullRefs,
    id: string,
    groupId: string
  ) {
    const docRef = refs.currentRefs.getAssetDocRef<Art.Encrypted>(
      AssetType.Art,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Art.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Art.handleOutDated();

      const repo = await newRepo(refs.currentRefs, transaction);
      const ar = Art.newAggregateRoot(currentData);
      const currentGroupIds = ar.state().groupIds;
      const arToCommit = buildUpdateGroupCommand(
        ar,
        Command.updateAsset,
        refs.selfRefs.userId,
        currentGroupIds,
        groupId,
        false
      );

      const groupUpdater = new GroupUpdater(refs.currentRefs, [groupId]);
      await groupUpdater.read(transaction);

      //commit
      if (arToCommit) {
        const events = arToCommit.applyAllChanges();
        repo.manualCommit(arToCommit, events);
      }

      // delete from group
      groupUpdater.deleteOneItemFromGroup(transaction, groupId, id);
    });
  }

  export async function removeContactRelation(
    refs: Refs,
    executerId: string,
    id: string,
    contactId: string,
    roles: RoleToAsset[]
  ) {
    const docRef = refs.getAssetDocRef<Art.Encrypted>(AssetType.Art, id);
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Art.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) Art.handleOutDated();
      const encryptedUpdate: UpdateObject<Art.Encrypted> = {};
      roles.forEach((role) => {
        switch (role) {
          case RoleToAsset.Seller:
            if (contactId !== currentUndecrypted.acquisition?.sellerId)
              throw new DataPoisoned("sellerId mismatch");
            encryptedUpdate.acquisition = {
              ...currentUndecrypted.acquisition,
              sellerId: null!,
            };
            break;
          case RoleToAsset.Shareholder: {
            if (!currentUndecrypted.ownership)
              throw new DataPoisoned("ownership not found");
            const updateShareholder =
              currentUndecrypted.ownership.shareholder.filter(
                (s) => s.contactId !== contactId
              );
            if (
              updateShareholder.length ===
              currentUndecrypted.ownership.shareholder.length
            )
              throw new DataPoisoned("shareholderId not found");
            encryptedUpdate.ownership = {
              myOwnership: currentUndecrypted.ownership.myOwnership,
              shareholder: updateShareholder,
            };
            break;
          }
          case RoleToAsset.Beneficiary: {
            if (!currentUndecrypted.beneficiary)
              throw new DataPoisoned("beneficiary not found");
            const updateBeneficiary = currentUndecrypted.beneficiary.filter(
              (s) => s.contactId !== contactId
            );
            if (
              updateBeneficiary.length === currentUndecrypted.beneficiary.length
            )
              throw new DataPoisoned("beneficiaryId not found");
            encryptedUpdate.beneficiary = updateBeneficiary;
            break;
          }
        }
      });

      const repo = await ArtsRepo.newRepo(refs, transaction);
      const ar = Art.newAggregateRoot(currentUndecrypted);
      ar.handle(Command.updateAsset(executerId, encryptedUpdate));
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  export async function newRepo(
    refs: Refs,
    transaction: Transaction
  ): Promise<Repo<Art.Encrypted, Command, Event>> {
    return newAggregateRepo(
      transaction,
      refs.userId,
      AssetType.Art,
      new AssetRelationStateWriter(
        refs.getAssetCollectionRef(AssetType.Art),
        refs.Relations
      )
    );
  }

  //#NOTE required in rebuild process
  // export function newEventConsumer(
  //   refs: Refs,
  //   id: string
  // ): EventConsumer<Art.Encrypted, Command, Event> {
  //   const docRef = refs.getAssetDocRef<Art.Encrypted>(AssetType.Art, id);
  //   return {
  //     stateWriter: new BaseStateWriter(
  //       refs.getAssetCollectionRef(AssetType.Art)
  //     ),
  //     getAggregateRoot: async (transaction) => {
  //       const currentUndecrypted = await transaction
  //         .get(docRef)
  //         .then(checkAndGetData);
  //       Art.assureVersion(currentUndecrypted);
  //       return Art.newAggregateRoot(currentUndecrypted);
  //     },
  //     processEvent: (raw) => assetProcessEventBase(id, raw),
  //   };
  // }

  export async function newArAndUpdateInsurance(
    refs: Refs,
    transaction: Transaction,
    executerId: string,
    assetId: string,
    insuranceId: string,
    isCreate: boolean = true
  ) {
    const docRef = refs.getAssetDocRef<Art.Encrypted>(AssetType.Art, assetId);
    const currentData = await transaction.get(docRef).then(checkAndGetData);
    const result = Art.assureVersion(currentData);
    if (result === TypeResult.DataOutDated) Art.handleOutDated();
    const ar = Art.newAggregateRoot(currentData);
    ar.handle(
      isCreate
        ? Command.addInsurance(executerId, insuranceId)
        : Command.removeInsurance(executerId, insuranceId)
    );
    return ar;
  }

  export async function newArAndUpdateGroup(
    refs: Refs,
    transaction: Transaction,
    executerId: string,
    assetId: string,
    groupId: string,
    isAdd: boolean = true
  ) {
    const docRef = refs.getAssetDocRef<Art.Encrypted>(AssetType.Art, assetId);
    const currentData = await transaction.get(docRef).then(checkAndGetData);
    const result = Art.assureVersion(currentData);
    if (result === TypeResult.DataOutDated) Art.handleOutDated();
    const ar = Art.newAggregateRoot(currentData);
    const currentGroupIds = ar.state().groupIds;
    return buildUpdateGroupCommand(
      ar,
      Command.updateAsset,
      executerId,
      currentGroupIds,
      groupId,
      isAdd
    );
  }
}
