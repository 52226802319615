import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { motion } from 'framer-motion'
import { AlertCircleIcon, CheckCircleIcon, InfoIcon, Loader2Icon } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import useSWR, { mutate } from 'swr'

import { SubscriptionPlan, SubscriptionStatus } from 'core/remodel/types/billing'
import { currencyOptions } from 'core/remodel/types/options'
import { fetchPreferences, fetchSubscription, updatePreferences, userQuery } from '@/api/AccountService'
import { parseErrorMessage } from '@/utils/parseErrorMessages'
import { useDebounce } from '@/hooks/useDebounce'
import { useToast } from '@/hooks/useToast'
import { useAuthStore } from '@/store/authStore'
import { PreferenceValues } from '@/pages/account/profile/preferences'
import { Button, FormSelect, Modal, SelectItem, Tooltip, TooltipContent, TooltipTrigger } from '@/components/base'
import EnrollTotp from '@/components/EnrollTotp'

export default function Setup() {
  const { delegatorId } = useAuthStore((state) => state.permissions)

  return !delegatorId ? <SelfSetup /> : <DelegatorSetup />
}

/* self */
type SelfCheckEvent = 'subscription' | 'baseCurrency' | 'enrollTotp'

function SelfSetup() {
  const subscriptionSWR = useSWR('checkNotSubscribed', checkNotSubscribed)
  const baseCurrencySWR = useSWR('checkEmptyBaseCurrency', checkEmptyBaseCurrency)
  const isFirstLoginSWR = useSWR('checkEnrollTotp', checkEnrollTotp)

  const checkEvent = useMemo(() => {
    if ([subscriptionSWR, baseCurrencySWR, isFirstLoginSWR].some(({ data }) => data === undefined)) return undefined
    const entrySet: Record<SelfCheckEvent, boolean> = {
      subscription: subscriptionSWR.data!,
      baseCurrency: baseCurrencySWR.data!,
      enrollTotp: isFirstLoginSWR.data!
    }
    const activeEntry = (Object.entries(entrySet) as [SelfCheckEvent, boolean][]).find(([_key, value]) => value)
    if (!activeEntry) return undefined
    const [event] = activeEntry
    return event
  }, [subscriptionSWR, baseCurrencySWR, isFirstLoginSWR])

  useEffect(() => {
    // setup done
    if ([subscriptionSWR, baseCurrencySWR, isFirstLoginSWR].every(({ data }) => data === false)) {
      useAuthStore.setState({ isSetupDone: true })
    }
  }, [subscriptionSWR, baseCurrencySWR, isFirstLoginSWR])

  switch (checkEvent) {
    case 'subscription': {
      return <SetupSubscription />
    }
    case 'baseCurrency': {
      return <SetupBaseCurrency />
    }
    case 'enrollTotp': {
      return <EnrollTotp />
    }
    // loading
    default: {
      return (
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={'relative grid h-screen w-full place-items-center'}
        >
          <Loader2Icon className={'h-9 w-9 animate-spin text-primary'} />
        </motion.main>
      )
    }
  }
}

async function checkNotSubscribed(): Promise<boolean> {
  const database = useAuthStore.getState().database
  if (!database) throw new Error('No database')
  if (process.env.NODE_ENV === 'development') return false
  // we need know what time to check the subscription status, so we use isBack to determine
  const isBack = new URL(window.location.href).searchParams.get('isBack') === 'true'
  if (isBack) return true
  const isSubscribed = await fetchSubscription(database)([userQuery.subscription])
    .then(({ status }) => status === SubscriptionStatus.Successful)
    .catch(() => false)
  return !isSubscribed
}

async function checkEmptyBaseCurrency(): Promise<boolean> {
  const database = useAuthStore.getState().database
  if (!database) throw new Error('No database')
  const { baseCurrency } = await fetchPreferences(database)([userQuery.preferences])
  console.log('checkEmptyBaseCurrency', !!baseCurrency)
  return !baseCurrency
}

async function checkEnrollTotp(): Promise<boolean> {
  const database = useAuthStore.getState().database
  if (!database) throw new Error('No database')
  return database.checkRemindMFA()
}

type ModalKind = 'trialStarted' | 'trialEnded' | 'subscriptionStarted' | 'subscriptionCanceled' | 'subscriptionFailed'

function SetupSubscription() {
  const { t } = useTranslation()
  const searchParams = useSearchParams()
  const isBack = searchParams.get('isBack') === 'true'
  const [kind, setKind] = useState<ModalKind | null>(null)
  const database = useAuthStore((state) => state.database)
  const { data, error } = useSWR([userQuery.subscription], fetchSubscription(database!), { errorRetryInterval: 1000 })

  useEffect(() => {
    const checkStatus = async () => {
      if (isBack && error) return

      if (data) {
        const { plan, status } = data
        switch (plan) {
          case SubscriptionPlan.Trial: {
            setKind(status === SubscriptionStatus.Successful ? 'trialStarted' : 'trialEnded')
            break
          }
          case SubscriptionPlan.Subscribed: {
            setKind(status === SubscriptionStatus.Successful ? 'subscriptionStarted' : 'subscriptionFailed')
            break
          }
        }
      } else if (error) {
        const url = new URL(window.location.href)
        const returnUrl = `${url.origin}/?isBack=true`
        const result = await database!.getStripeCheckoutSessionUrl({ returnUrl })
        window.location.href = result.url
      }
    }

    if (database) checkStatus()
  }, [isBack, data, error, database])

  const handleResubscribe = async () => {
    const url = new URL(window.location.href)
    const returnUrl = `${url.origin}/?isBack=true`
    const result = await database!.getStripePortalSessionUrl({ returnUrl })
    window.location.href = result.url
  }

  const handleSetup = async () => {
    // clear isBack parameter to avoid infinite loop
    const url = new URL(window.location.href)
    url.searchParams.delete('isBack')
    window.history.replaceState({}, document.title, url.toString())
    // revalidate the subscription status and base currency to avoid stale data
    await Promise.all([
      mutate('checkNotSubscribed', false, { revalidate: false }),
      mutate('checkEmptyBaseCurrency', undefined, { revalidate: true })
    ])
  }

  return (
    <Modal className={'max-w-[460px] rounded-lg'}>
      {kind === null && (
        <Modal.Content className={'h-60 place-items-center'}>
          <Loader2Icon className={'h-8 w-8 animate-spin text-primary'} />
        </Modal.Content>
      )}

      {kind === 'trialStarted' && (
        <Modal.Content className={'gap-y-4 text-center'}>
          <CheckCircleIcon className={'mx-auto text-green-600'} size={40} />
          <label className={'text-xl font-semibold text-[#21253B]'}>{t('account:TrialActivated')}</label>
          <p className={'text-sm text-[#424553]'}>{t('account:FreeTrialHasStarted')}</p>
          <Button className={'py-3'} variant={'solid'} size={'md'} onClick={handleSetup}>
            {t('Continue')}
          </Button>
        </Modal.Content>
      )}

      {kind === 'trialEnded' && (
        <Modal.Content className={'gap-y-4 text-center'}>
          <AlertCircleIcon className={'mx-auto text-red-600'} size={40} />
          <label className={'text-xl font-semibold text-[#21253B]'}>{t('account:FreeTrialHasEnded')}</label>
          <p className={'text-sm text-[#424553]'}>{t('account:TrialPeriodHasEnded')}</p>
          <Button className={'py-3'} variant={'solid'} size={'md'} onClick={handleResubscribe}>
            {t('account:ReactivateAccountNow')}
          </Button>
        </Modal.Content>
      )}

      {kind === 'subscriptionStarted' && (
        <Modal.Content className={'gap-y-4 text-center'}>
          <CheckCircleIcon className={'mx-auto text-green-600'} size={40} />
          <label className={'text-xl font-semibold text-[#21253B]'}>{t('account:SubscriptionActivated')}</label>
          <p className={'text-sm text-[#424553]'}>{t('account:PaymentHasBeenProcessed')}</p>
          <Button className={'py-3'} variant={'solid'} size={'md'} onClick={handleSetup}>
            {t('Continue')}
          </Button>
        </Modal.Content>
      )}

      {kind === 'subscriptionFailed' && (
        <Modal.Content className={'gap-y-4 text-center'}>
          <AlertCircleIcon className={'mx-auto text-red-600'} size={40} />
          <label className={'text-xl font-semibold text-[#21253B]'}>{t('account:AccountReactivationFailed')}</label>
          <p className={'text-sm text-[#424553]'}>{t('account:CheckYourPaymentDetails')}</p>
          <Button className={'py-3'} variant={'solid'} size={'md'} onClick={() => setKind('subscriptionCanceled')}>
            {t('Continue')}
          </Button>
        </Modal.Content>
      )}

      {kind === 'subscriptionCanceled' && (
        <Modal.Content className={'gap-y-4 text-center'}>
          <AlertCircleIcon className={'mx-auto text-red-600'} size={40} />
          <label className={'text-xl font-semibold text-[#21253B]'}>{t('account:SubscriptionPlanCancelled')}</label>
          <p className={'text-sm text-[#424553]'}>{t('account:SubscriptionHasBeenCancelled')}</p>
          <Button className={'py-3'} variant={'solid'} size={'md'} onClick={handleResubscribe}>
            {t('account:ReactivateAccountNow')}
          </Button>
        </Modal.Content>
      )}
    </Modal>
  )
}

type BaseCurrencyStep = 'confirm' | 'result'

function SetupBaseCurrency() {
  const { t } = useTranslation()
  const { toast } = useToast()

  // step state
  const [step, setStep] = useState<BaseCurrencyStep | null>(null)
  const debouncedStep = useDebounce(step, 100)

  // form state
  const database = useAuthStore((state) => state.database)
  const { data: preferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const form = useForm<PreferenceValues>({ values: preferences })

  const onConfirm = async (values: PreferenceValues) => {
    try {
      await updatePreferences(database!, values)
      await mutate('checkEmptyBaseCurrency')
    } catch (e) {
      const errorMessage = parseErrorMessage(e, 'Unknown error: Update Preferences')
      toast({ variant: 'error', description: errorMessage })
    }
  }

  return (
    !preferences?.baseCurrency && (
      <>
        <Modal className={'max-w-xl'}>
          <Modal.Content className={'gap-4'}>
            <div className={'flex min-h-[320px] flex-col items-center justify-center gap-y-4 bg-finances-map bg-cover'}>
              <div className={'flex flex-col items-center gap-y-4'}>
                <p className={'text-2xl text-primary'}>{t('WelcomeToMyAssets')}</p>
                <p className={'text-center text-text'}>{t('SetBaseCurrency')}</p>
                <div className={'flex items-center gap-4'}>
                  <FormSelect
                    control={form.control}
                    name={'baseCurrency'}
                    className={'w-40'}
                    placeholder={t('Select')}
                    rules={{ required: true }}
                  >
                    {currencyOptions.map((option) => (
                      <SelectItem key={option.value} value={`${option.value}`}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </FormSelect>

                  <Tooltip>
                    <TooltipTrigger asChild={true}>
                      <InfoIcon className={'cursor-pointer fill-primary text-white'} size={24} />
                    </TooltipTrigger>
                    <TooltipContent className={'rounded border-0 bg-primary px-2 py-1'}>
                      <span className={'whitespace-pre text-xs font-medium text-white'}>
                        {t('SelectCantBeReserved')}
                      </span>
                    </TooltipContent>
                  </Tooltip>
                </div>
              </div>
            </div>
            <fieldset className={'flex justify-end gap-x-2'}>
              <Button
                className={'group relative min-w-[130px]'}
                variant={'solid'}
                size={'md'}
                type={'submit'}
                onClick={() => setStep('confirm')}
                disabled={!form.watch('baseCurrency')}
              >
                {t('Confirm')}
              </Button>
            </fieldset>
          </Modal.Content>
        </Modal>

        {debouncedStep === 'confirm' && (
          <Modal className={'max-w-md'}>
            <Modal.Header className={'bg-primary'}>
              <label className={'text-sm font-medium uppercase text-white'}>{t('ConfirmSelection')}</label>
            </Modal.Header>
            <Modal.Content>
              <div className={'space-y-4'}>
                <div className={'flex min-h-[50px] flex-col items-center justify-center gap-4'}>
                  <Trans
                    t={t}
                    i18nKey={'ConfirmBaseCurrencyContent'}
                    parent={(props: any) => (
                      <span className={'max-w-[416px] break-words text-center text-sm text-gray-600'} {...props} />
                    )}
                    values={{ currency: form.watch('baseCurrency') }}
                    components={{ bold: <strong /> }}
                  />
                </div>
                <fieldset className={'flex justify-center gap-2'} disabled={form.formState.isSubmitting}>
                  <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={() => setStep(null)}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    className={'group relative min-w-[130px]'}
                    variant={'solid'}
                    size={'md'}
                    onClick={form.handleSubmit(onConfirm)}
                  >
                    <Loader2Icon className={'absolute animate-spin opacity-0 group-disabled:opacity-100'} />
                    <span className={'group-disabled:opacity-0'}>{t('Confirm')}</span>
                  </Button>
                </fieldset>
              </div>
            </Modal.Content>
          </Modal>
        )}
      </>
    )
  )
}

/* delegator */
type DelegateCheckEvent = 'mock'

function DelegatorSetup() {
  const checkEvent = useMemo(() => {
    const entrySet: Record<DelegateCheckEvent, boolean> = {
      mock: false
    }
    const activeEntry = (Object.entries(entrySet) as [DelegateCheckEvent, boolean][]).find(([_key, value]) => value)
    if (!activeEntry) return undefined
    const [event] = activeEntry
    return event
  }, [])

  useEffect(() => {
    // setup done
    if ([].every(({ data }) => data === false)) {
      useAuthStore.setState({ isSetupDone: true })
    }
  }, [])

  switch (checkEvent) {
    // loading
    default: {
      return (
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={'relative grid h-screen w-full place-items-center'}
        >
          <Loader2Icon className={'h-9 w-9 animate-spin text-primary'} />
        </motion.main>
      )
    }
  }
}
