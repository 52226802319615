import { useRef, useState } from 'react'
import Image from 'next/image'
import { Loader2Icon } from 'lucide-react'
import Cropper, { type Area, type Point } from 'react-easy-crop'
import { Trans, useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { defaultPreferences } from 'core/remodel/types/user'
import { deleteAvatar, fetchPreferences, uploadAvatar, userQuery } from '@/api/AccountService'
import { MAX_DOCUMENT_SIZE_MB } from '@/constants/preference'
import { ACCEPTED_IMAGE_FORMATS, isDocument } from '@/constants/upload'
import { heicToPng } from '@/utils/heicToPng'
import { ImageDimensions, ImageSizes } from '@/utils/imageTools'
import { parseErrorMessage } from '@/utils/parseErrorMessages'
import { toast } from '@/hooks/useToast'
import { useAuthStore } from '@/store/authStore'
import { Avatar, AvatarFallback, AvatarImage, Button, Modal, Slider } from '@/components/base'
import Confirm from '@/components/Confirm'
import DropArea from '@/components/DropArea'
import { MinusIcon, PlusIcon, UploadCloudIcon, UserIcon, XIcon } from '@/components/icon'

interface AvatarEditorProps {
  photoUrl?: string
}

export default function AvatarEditor({ photoUrl }: AvatarEditorProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [action, setAction] = useState<'Upload' | 'Delete' | null>(null)

  const onSubmit = async (blob: Blob) => {
    try {
      setIsLoading(true)
      await uploadAvatar(database!, blob, preferences.ivSalt)
      setAction(null)
      toast({ variant: 'success', description: t('Toast.Success.Update') })
    } catch (e) {
      const errorMessage = parseErrorMessage(e, 'Unknown error: Upload Avatar')
      toast({ variant: 'error', description: errorMessage })
    } finally {
      setIsLoading(false)
    }
  }

  const onRemove = async () => {
    try {
      setIsLoading(true)
      await deleteAvatar(database!)
      setAction(null)
      toast({ variant: 'success', description: t('Toast.Success.RemovePhoto') })
    } catch (e) {
      const errorMessage = parseErrorMessage(e, 'Unknown error: Delete Avatar')
      toast({ variant: 'error', description: errorMessage })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className={'group relative mr-2'}>
        <Avatar>
          <AvatarImage src={photoUrl} alt={'avatar'} />
          <AvatarFallback className={'bg-[#6B7EA9]'}>
            <UserIcon />
          </AvatarFallback>
        </Avatar>

        <fieldset className={'absolute inset-0 opacity-0 group-hover:opacity-100'} disabled={isLoading}>
          {/* upload */}
          <Button
            className={
              'absolute flex h-10 w-10 items-center justify-center rounded-full bg-primary disabled:opacity-100'
            }
            onClick={() => setAction('Upload')}
          >
            <PlusIcon className={'text-white'} size={32} />
          </Button>

          {/* close */}
          {photoUrl && (
            <Button
              className={
                'absolute right-0 top-0 -m-1 flex h-5 w-5 items-center justify-center rounded-full border border-white bg-error disabled:opacity-100'
              }
              onClick={() => setAction('Delete')}
            >
              <XIcon className={'text-white'} size={16} />
            </Button>
          )}
        </fieldset>
      </div>

      {/* upload avatar */}
      {action === 'Upload' && (
        <UploadModal onClose={() => setAction(null)} onSubmit={onSubmit} isSubmitting={isLoading} />
      )}

      {action === 'Delete' && (
        <Confirm
          title={t('Delete')}
          content={t('account:AskDeleteAvatar')}
          onCancel={() => setAction(null)}
          cancelLabel={t('Cancel')}
          onConfirm={onRemove}
          confirmLabel={t('Delete')}
          disabled={isLoading}
        />
      )}
    </>
  )
}

interface UploadModal {
  onClose: () => void
  onSubmit: (blob: Blob) => Promise<void>
  isSubmitting?: boolean
}

function UploadModal({ onClose, onSubmit, isSubmitting = false }: UploadModal) {
  const { t } = useTranslation()
  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [uploadErrorMsg, setUploadErrorMsg] = useState<string | null>(null)

  const sizeCheck = (newFiles: File[]) => {
    const isSizeValidForFile = newFiles.every((file) => {
      const isDoc = isDocument(file)
      if (isDoc) {
        return file.size <= MAX_DOCUMENT_SIZE_MB * Math.pow(1024, 2)
      }
      return true
    })
    if (!isSizeValidForFile) {
      setUploadErrorMsg(t('validation:OverDocumentMaxUploadSize', { value: MAX_DOCUMENT_SIZE_MB }))
    }
    return isSizeValidForFile
  }

  const onUpload = async (photos: File[]) => {
    if (!sizeCheck(photos)) return

    try {
      const filterHeicResult = await heicToPng(photos)
      const photo = filterHeicResult[0]
      if (!photo) throw new Error('Upload failed. Please retry.')
      if (!/^image\/*/.test(photo.type)) throw new Error('Unsupported file type.')
      const objectURL = URL.createObjectURL(photo)
      setImageSrc(objectURL)
    } catch (e) {
      const errorMessage = parseErrorMessage(e, 'Unknown error: Upload Avatar')
      toast({ variant: 'error', description: errorMessage })
    }
  }

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const onSave = async () => {
    try {
      const image = imageRef.current
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      if (!croppedAreaPixels) throw new Error('Cropped area not found')
      if (!image) throw new Error('Image element not found')
      if (!canvas) throw new Error('Canvas not found')
      if (!context) throw new Error('Canvas context not found')

      const { x, y, width, height } = croppedAreaPixels
      const avatarSize = ImageDimensions[ImageSizes.Small] // legacy: 256
      canvas.width = avatarSize
      canvas.height = avatarSize
      context.drawImage(image, x, y, width, height, 0, 0, avatarSize, avatarSize)

      const blob: Blob = await new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          blob ? resolve(blob) : reject(new Error('Create url filed'))
        })
      })

      onSubmit(blob)
    } catch (e) {
      const errorMessage = parseErrorMessage(e, 'Unknown error: Save Avatar')
      toast({ variant: 'error', description: errorMessage })
    }
  }

  return (
    <Modal className={'max-w-xl'}>
      <Modal.Header className={'bg-primary'}>
        <label className={'text-sm font-medium uppercase text-white'}>{t('account:Photo')}</label>
        <Modal.CloseButton className={'text-white'} onClose={onClose} />
      </Modal.Header>
      <Modal.Content className={'h-96'}>
        {!imageSrc ? (
          <DropArea className={'flex h-full flex-col items-center justify-center gap-4'} onDrop={onUpload}>
            <label className={'group flex w-full cursor-pointer flex-col items-center'} htmlFor={'attachments'}>
              <UploadCloudIcon
                className={'text-primary transition-colors ease-out group-hover:text-primary-hover'}
                size={64}
              />
              <Trans
                parent={(props: any) => <p className={'text-sm text-primary'} {...props} />}
                t={t}
                i18nKey={'DropFile'}
                components={[<span key={0} className={'underline'} />]}
              />
              <input
                id={'attachments'}
                className={'sr-only'}
                name={'attachments'}
                type={'file'}
                accept={ACCEPTED_IMAGE_FORMATS}
                multiple={false}
                onChange={(e) => {
                  if (e.target.files) {
                    const files = Array.from(e.target.files)
                    onUpload(files)
                  }
                }}
              />
            </label>
            {uploadErrorMsg && (
              <Confirm
                title={t('UploadError')}
                content={uploadErrorMsg}
                onCancel={() => setUploadErrorMsg(null)}
                cancelLabel={t('Cancel')}
                onConfirm={() => setUploadErrorMsg(null)}
                confirmLabel={t('Confirm')}
              />
            )}
          </DropArea>
        ) : (
          <div className={'flex h-full flex-col items-stretch gap-4'}>
            <Image ref={imageRef} src={imageSrc} fill={true} className={'hidden'} alt={'ref'} />
            <div className={'relative grow bg-gray-400'}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                cropShape={'round'}
                showGrid={false}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>

            <div className={'flex items-center justify-center gap-2'}>
              <Button className={'p-0'} size={'md'} onClick={() => setZoom((prev) => Math.max(prev - 0.1, 1))}>
                <MinusIcon className={'text-primary'} size={32} />
              </Button>
              <Slider
                className={'max-w-[250px]'}
                value={[zoom]}
                onValueChange={([value]) => setZoom(value)}
                min={1}
                max={3}
                step={0.1}
              />
              <Button className={'p-0'} size={'md'} onClick={() => setZoom((prev) => Math.min(prev + 0.1, 3))}>
                <PlusIcon className={'text-primary'} size={32} />
              </Button>
            </div>

            <fieldset className={'flex justify-end gap-x-2'} disabled={isSubmitting}>
              <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onClose}>
                {t('Cancel')}
              </Button>
              <Button className={'group relative min-w-[130px]'} variant={'solid'} size={'md'} onClick={onSave}>
                <Loader2Icon className={'absolute animate-spin opacity-0 group-disabled:opacity-100'} />
                <span className={'group-disabled:opacity-0'}>{t('Update')}</span>
              </Button>
            </fieldset>
          </div>
        )}
      </Modal.Content>
    </Modal>
  )
}
