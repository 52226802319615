import { ReactNode } from 'react'
import { useRouter } from 'next/router'
import { Loader2Icon } from 'lucide-react'
import { IdleTimerProvider } from 'react-idle-timer'
import { useShallow } from 'zustand/react/shallow'

import { promptBeforeIdle, timeout } from '@/constants/expiredTime'
import { useRedirect } from '@/hooks/useRedirect'
import { useRouteGuard } from '@/hooks/useRouteGuard'
import { useAuthStore } from '@/store/authStore'
import AppcuesIdentifier from '@/components/AppcuesIdentifier'
import { DataImporterModal } from '@/components/DataImporter'
import DelegateChecker from '@/components/DelegateChecker'
import DialogSessionCountDown from '@/components/DialogSessionCountDown'
import DialogSessionExpired from '@/components/DialogSessionExpired'
import Setup from '@/components/Setup'

interface ProtectedPageProps {
  children: ReactNode
}

export default function ProtectedPage({ children }: ProtectedPageProps) {
  const router = useRouter()
  const { logout, status, isSetupDone } = useAuthStore(
    useShallow((state) => ({
      logout: state.logout,
      status: state.status,
      isSetupDone: state.isSetupDone
    }))
  )

  useRedirect({
    shouldRedirect: ['unauthenticated', 'closing'].includes(status),
    redirectTo: '/auth/login'
  })
  useRedirect({
    shouldRedirect: status === 'unverified',
    redirectTo: '/auth/verify-email'
  })
  useRouteGuard()

  if (!isSetupDone && !router.pathname.startsWith('/identity')) return <Setup />

  switch (status) {
    case 'sessionExpired': {
      return (
        <>
          {children}
          <DialogSessionExpired />
        </>
      )
    }
    case 'ready': {
      return (
        <IdleTimerProvider
          timeout={timeout}
          promptBeforeIdle={promptBeforeIdle}
          throttle={500}
          onIdle={async () => await logout(true)}
        >
          {children}
          <DialogSessionCountDown />
          <DataImporterModal />
          <AppcuesIdentifier />
          <DelegateChecker />
        </IdleTimerProvider>
      )
    }
    default: {
      return (
        <main className={'flex h-screen w-screen items-center justify-center'}>
          <Loader2Icon className={'h-8 w-8 animate-spin text-primary'} />
        </main>
      )
    }
  }
}
