import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'

interface MaskContextType {
  isMasked: boolean
  toggleMask: () => void
}

const MaskContext = createContext<MaskContextType | undefined>(undefined)

export const MaskProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isMasked, setIsMasked] = useState<boolean>(() => {
    if (typeof window !== 'undefined') {
      const sessionMask = window.sessionStorage.getItem('isMasked')
      return sessionMask ? JSON.parse(sessionMask) : false
    }
    return false
  })

  useEffect(() => {
    if (typeof window === 'undefined') return
    window.sessionStorage.setItem('isMasked', JSON.stringify(isMasked))
  }, [isMasked])

  const toggleMask = () => {
    setIsMasked((prev) => !prev)
  }

  return <MaskContext.Provider value={{ isMasked, toggleMask }}>{children}</MaskContext.Provider>
}

export const useMask = () => {
  const context = useContext(MaskContext)
  if (!context) {
    throw new Error('useMask must be used within a MaskProvider')
  }
  return context
}
