import type { ReactNode } from 'react'

import { useMask } from '@/store/MaskContext'

export function PrivacyField({
  children,
  className,
  as: Element = 'span',
  mask = '******'
}: {
  children: ReactNode
  className?: string
  as?: keyof JSX.IntrinsicElements
  mask?: string
}) {
  const { isMasked } = useMask()

  return isMasked ? (
    <Element className={className}>{mask}</Element>
  ) : (
    <Element className={className}>{children}</Element>
  )
}
