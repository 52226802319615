import { useCallback, useEffect, useState } from 'react'

import { isMatchedEnv } from '@/constants/site'
import { firebase } from '@/utils/firebase'
import { parseErrorMessage } from '@/utils/parseErrorMessages'
import type { PasswordCheckVerification } from '@/utils/typescript-recaptcha-password-check-helpers'
import { useToast } from '@/hooks/useToast'

let PasswordCheckModule: typeof PasswordCheckVerification | null = null

export function usePasswordLeak() {
  const { toast } = useToast()
  const [isPasswordLeaked, setIsPasswordLeaked] = useState<boolean>(false)

  const checkPasswordLeak = useCallback(
    async (email?: string, password?: string) => {
      if (!password || !email) return
      if (isMatchedEnv(['development'])) return // skip in development

      try {
        if (!PasswordCheckModule) {
          const { PasswordCheckVerification } = await import('@/utils/typescript-recaptcha-password-check-helpers')
          PasswordCheckModule = PasswordCheckVerification
        }
        const verification = await PasswordCheckModule.create(email, password)
        const res = await firebase.checkPasswordLeaked({
          lookupHashPrefix: Buffer.from(verification.getLookupHashPrefix()).toString('base64'),
          encryptedUserCredentialsHash: Buffer.from(verification.getEncryptedUserCredentialsHash()).toString('base64')
        })
        const encryptedLeakMatchPrefixes = res.encryptedLeakMatchPrefixes.map((prefix) => {
          return new Uint8Array(Buffer.from(prefix, 'base64'))
        })
        const reencryptedUserCredentialsHash = new Uint8Array(Buffer.from(res.reencryptedUserCredentialsHash, 'base64'))
        const leaked = verification
          .verify(reencryptedUserCredentialsHash, encryptedLeakMatchPrefixes)
          .areCredentialsLeaked()
        setIsPasswordLeaked(leaked)
        return leaked
      } catch (e) {
        const errorMessage = parseErrorMessage(e, 'Error checking password')
        toast({ variant: 'error', description: errorMessage })
      }
    },
    [toast]
  )

  return {
    isPasswordLeaked,
    checkPasswordLeak
  }
}
