import { useSearchParams } from 'next/navigation'
import { FileQuestionIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { cn } from '@/utils/classnames'
import { useQueryParamsHandler } from '@/hooks/useQueryParamsHandler'
import { ChevronsUpDownIcon, SortAscendIcon } from '@/components/icon'

export interface Column<T> {
  key: string
  header?: () => JSX.Element
  cell: (item: T, index: number) => JSX.Element
  className?: string
  isSortable?: boolean
}

interface TableProps<T> {
  columns: Column<T>[]
  data: T[]
  className?: string
  dropdown?: (item: T, index: number) => JSX.Element
  selected?: (item: T) => boolean
  removed?: (item: T) => boolean
  secondaryText?: string
}

export function Table<T>({ columns, data, className, dropdown, selected, removed, secondaryText }: TableProps<T>): JSX.Element {
  const { t } = useTranslation()
  const searchParams = useSearchParams()
  const { onUrlUpdate } = useQueryParamsHandler()
  const sort = searchParams.get('sort') ?? ''
  const order = searchParams.get('order') ?? 'asc'

  const handleSortByField = (field: string) => {
    const isSameField = sort === field
    onUrlUpdate({ sort: field, order: isSameField && order === 'asc' ? 'desc' : 'asc' }, ['sort', 'order'])
  }

  return (
    <div className={cn('overflow-y-auto rounded bg-gray-100 p-4 shadow-sm', className)}>
      <div className={'flex w-full flex-col text-xs'} role={'table'}>
        <div className={'flex'} role={'row'}>
          {/* Headers */}
          {columns.map((col) => (
            <div
              key={`header_${col.key}`}
              className={cn(
                'flex h-12 flex-1 items-center gap-1 px-2 text-sm font-light text-gray-500',
                col.isSortable ? 'cursor-pointer' : 'cursor-default',
                col.className
              )}
              role={'columnheader'}
              onClick={() => (col.isSortable ? handleSortByField(col.key) : undefined)}
            >
              {col.header && col.header()}
              {col.isSortable &&
                (sort === col.key ? (
                  <SortAscendIcon size={20} className={cn('shrink-0', order === 'desc' && 'rotate-180')} />
                ) : (
                  <ChevronsUpDownIcon size={20} className={'shrink-0'} />
                ))}
            </div>
          ))}

          {/* Dropdown menu */}
          {dropdown && <div className={'h-12 w-12 px-2'} role={'columnheader'} />}
        </div>
        <div className={'flex flex-col rounded border border-grey bg-white'} role={'rowgroup'}>
          {data.length > 0 ? (
            data.map((item, rowIndex) => {
              const isSelected = selected?.(item)
              const isRemoved = removed?.(item)
              return (
                <div
                  key={`row_${rowIndex}`}
                  className={cn(
                    'flex border-t border-grey transition-colors first:border-t-0',
                    isSelected ? 'bg-[#CFE7EB]' : isRemoved ? 'bg-[#FFF5F5]' : 'hover:bg-grey/10'
                  )}
                  role={'row'}
                >
                  {/* Cells */}
                  {columns.map((col, columnIndex) => (
                    <div
                      key={`cell_${rowIndex}_${columnIndex}`}
                      className={cn('flex h-12 min-w-0 flex-1 items-center p-2', col.className)}
                      role={'cell'}
                    >
                      {col.cell(item, rowIndex)}
                    </div>
                  ))}

                  {/* Dropdown */}
                  {dropdown && (
                    <div className={'flex h-12 basis-12 items-center border-l border-grey p-2'} role={'cell'}>
                      {dropdown(item, rowIndex)}
                    </div>
                  )}
                </div>
              )
            })
          ) : (
            <div className={'flex h-48 items-center justify-center px-2'} role={'cell'} aria-colspan={columns.length}>
              <div className={'flex flex-col items-center gap-2 text-grey'}>
                <FileQuestionIcon size={32} />
                <span className={'text-base'}>{t('NoData')}</span>
                {secondaryText && <span className={'text-sm'}>{secondaryText}</span>}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

interface TableSkeletonProps {
  columns: Column<any>[]
  className?: string
  length?: number
  hasDropdown?: boolean
}

export function TableSkeleton({ columns, className, length = 15, hasDropdown }: TableSkeletonProps) {
  return (
    <div className={cn('overflow-y-auto rounded bg-gray-100 p-4', className)}>
      <div className={'flex w-full flex-col text-xs'} role={'table'}>
        <div className={'flex'} role={'row'}>
          {columns.map((col) => (
            <div
              key={`header_${col.key}`}
              className={cn(
                'flex h-12 flex-1 items-center px-2 text-sm font-light text-gray-500',
                col.isSortable ? 'cursor-pointer' : 'cursor-default',
                col.className
              )}
              role={'columnheader'}
            >
              {col.header && col.header()}
            </div>
          ))}
          {/* Dropdown Menu */}
          {hasDropdown && <div className={'h-12 w-12 px-2'} role={'columnheader'} />}
        </div>
        <div className={'flex flex-col rounded border border-grey bg-white'} role={'rowgroup'}>
          {Array.from({ length }, (_, rowIndex) => (
            <div
              key={`row_${rowIndex}`}
              className={'flex border-t border-grey transition-colors first:border-t-0 hover:bg-grey/10'}
              role={'row'}
            >
              {columns.map((col, columnIndex) => (
                <div
                  key={`cell_${rowIndex}_${columnIndex}`}
                  className={cn('flex h-12 min-w-0 flex-1 items-center p-2', col.className)}
                  role={'cell'}
                >
                  <div className={'h-[40px] w-full animate-pulse rounded bg-grey/20'} />
                </div>
              ))}
              {hasDropdown && (
                <div className={'flex h-12 w-12 items-center px-2'} role={'cell'}>
                  <div className={'h-[40px] w-full animate-pulse rounded bg-grey/20'} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
