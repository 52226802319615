import { AssetType } from 'core/remodel/types/enums'

export const rootPage: Record<AssetType, string[]> = {
  [AssetType.CashAndBanking]: ['finances:PageTabs.MyFinances', 'finances:PageTabs.CashAndBanking'],
  [AssetType.TraditionalInvestments]: ['finances:PageTabs.MyFinances', 'finances:PageTabs.TraditionalInvestments'],
  [AssetType.OtherInvestment]: ['finances:PageTabs.MyFinances', 'finances:PageTabs.OtherInvestment'],
  [AssetType.Cryptocurrency]: ['finances:PageTabs.MyFinances', 'finances:PageTabs.Cryptocurrencies'],
  [AssetType.Insurance]: ['finances:PageTabs.MyFinances', 'finances:PageTabs.Insurances'],
  [AssetType.Property]: ['properties:PageTabs.MyProperties'],
  [AssetType.Art]: ['collectables:PageTabs.MyCollectables', 'collectables:PageTabs.Art'],
  [AssetType.WineAndSpirits]: ['collectables:PageTabs.MyCollectables', 'collectables:PageTabs.WineAndSpirits'],
  [AssetType.WinePurchases]: [
    'finances:PageTabs.MyCollectables',
    'collectables:PageTabs.WineAndSpirits',
    'finances:PageTabs.WinePurchases'
  ],
  [AssetType.OtherCollectables]: ['collectables:PageTabs.MyCollectables', 'collectables:PageTabs.OtherCollectables'],
  [AssetType.Belonging]: ['belongings:PageTabs.MyBelongings'],
  [AssetType.BankOrInstitution]: [
    'finances:PageTabs.MyFinances',
    'finances:PageTabs.CashAndBanking',
    'finances:PageTabs.BankOrInstitution'
  ]
}

export const pageNames: Record<string, string[]> = {
  // root
  '/': ['nav:dashboard'],
  // error
  '/_error': ['NotFound'],
  // auth
  '/auth/proxy': ['nav:auth.authProxy'],
  '/auth/login': ['nav:auth.login'],
  '/auth/two-factor/totp': ['nav:auth.login'],
  '/auth/two-factor/backup-codes': ['nav:auth.login'],
  '/auth/reactivate': ['nav:auth.login'],
  '/auth/signup': ['nav:auth.signUp'],
  '/auth/verify-email': ['nav:auth.signUp'],
  '/auth/verify-fail': ['auth:VerifyFailed'],
  '/auth/reset': ['nav:auth.reset'],
  '/auth/forgot': ['nav:auth.forgot'],
  // account
  '/account/profile': ['nav:account.profile'],
  '/account/profile/totp': ['nav:account.profile', 'common:pageTabAction.2fa'],
  '/account/profile/personal': ['nav:account.profile', 'common:pageTabAction.personal'],
  '/account/profile/preferences': ['nav:account.profile', 'common:pageTabAction.preferences'],
  '/account/profile/backup-codes': ['nav:account.profile', 'common:pageTabAction.backup-codes'],
  '/account/profile/reset-password': ['nav:account.profile', 'common:pageTabAction.reset-password'],
  '/account/profile/close-account': ['nav:account.profile', 'common:pageTabAction.close-account'],
  '/account/identity': ['nav:account.identity'],
  '/account/contacts': ['nav:account.contacts'],
  '/account/contacts/create': ['nav:account.contacts', 'common:pageTabAction.create'],
  '/account/contacts/delete': ['nav:account.contacts', 'common:pageTabAction.delete'],
  '/account/contacts/edit': ['nav:account.contacts', 'common:pageTabAction.edit'],
  '/account/contacts/info': ['nav:account.contacts', 'common:pageTabAction.info'],
  '/account/delegates': ['nav:account.delegates'],
  '/account/delegates/create': ['nav:account.delegates', 'common:pageTabAction.create'],
  '/account/delegates/edit': ['nav:account.delegates', 'common:pageTabAction.edit'],
  '/account/delegates/revoke': ['nav:account.delegates', 'common:pageTabAction.revoke'],
  '/account/delegates/delete': ['nav:account.delegates', 'common:pageTabAction.delete'],
  '/account/subscription': ['nav:account.subscription'],
  '/account/subscription/cancel': ['nav:account.subscription', 'common:pageTabAction.cancel'],
  '/account/subscription/manage': ['nav:account.subscription', 'common:pageTabAction.manage'],
  '/account/subscription/payments-proxy': ['nav:account.subscription', 'common:pageTabAction.payments-proxy'],
  // finances
  '/finances': ['finances:PageTabs.MyFinances'],
  '/finances/summary': ['finances:PageTabs.MyFinances', 'common:pageTabAction.summary'],
  '/finances/accounts': rootPage[AssetType.CashAndBanking],
  '/finances/accounts/info': [...rootPage[AssetType.CashAndBanking], 'common:pageTabAction.info'],
  '/finances/accounts/edit': [...rootPage[AssetType.CashAndBanking], 'common:pageTabAction.edit'],
  '/finances/accounts/create': [...rootPage[AssetType.CashAndBanking], 'common:pageTabAction.create'],
  '/finances/accounts/delete': [...rootPage[AssetType.CashAndBanking], 'common:pageTabAction.delete'],
  '/finances/accounts/plaid-proxy': [...rootPage[AssetType.CashAndBanking], 'common:pageTabAction.list'],
  '/finances/tradit-invest': rootPage[AssetType.TraditionalInvestments],
  '/finances/tradit-invest/info': [...rootPage[AssetType.TraditionalInvestments], 'common:pageTabAction.info'],
  '/finances/tradit-invest/edit': [...rootPage[AssetType.TraditionalInvestments], 'common:pageTabAction.edit'],
  '/finances/tradit-invest/create': [...rootPage[AssetType.TraditionalInvestments], 'common:pageTabAction.create'],
  '/finances/tradit-invest/delete': [...rootPage[AssetType.TraditionalInvestments], 'common:pageTabAction.delete'],
  '/finances/tradit-invest/transaction': [...rootPage[AssetType.TraditionalInvestments], 'common:pageTabAction.list'],
  '/finances/other-invest': rootPage[AssetType.OtherInvestment],
  '/finances/other-invest/info': [...rootPage[AssetType.OtherInvestment], 'common:pageTabAction.info'],
  '/finances/other-invest/edit': [...rootPage[AssetType.OtherInvestment], 'common:pageTabAction.edit'],
  '/finances/other-invest/create': [...rootPage[AssetType.OtherInvestment], 'common:pageTabAction.create'],
  '/finances/other-invest/delete': [...rootPage[AssetType.OtherInvestment], 'common:pageTabAction.delete'],
  '/finances/crypto': rootPage[AssetType.Cryptocurrency],
  '/finances/crypto/create': [...rootPage[AssetType.Cryptocurrency], 'common:pageTabAction.create'],
  '/finances/crypto/delete': [...rootPage[AssetType.Cryptocurrency], 'common:pageTabAction.delete'],
  '/finances/crypto/transaction': [...rootPage[AssetType.Cryptocurrency], 'common:pageTabAction.list'],
  '/finances/insurance': rootPage[AssetType.Insurance],
  '/finances/insurance/info': [...rootPage[AssetType.Insurance], 'common:pageTabAction.info'],
  '/finances/insurance/edit': [...rootPage[AssetType.Insurance], 'common:pageTabAction.edit'],
  '/finances/insurance/create': [...rootPage[AssetType.Insurance], 'common:pageTabAction.create'],
  '/finances/insurance/delete': [...rootPage[AssetType.Insurance], 'common:pageTabAction.delete'],
  // properties
  '/properties': rootPage[AssetType.Property],
  '/properties/summary': [...rootPage[AssetType.Property], 'common:pageTabAction.summary'],
  '/properties/summary/list': [...rootPage[AssetType.Property], 'common:pageTabAction.list'],
  '/properties/summary/info': [...rootPage[AssetType.Property], 'common:pageTabAction.info'],
  '/properties/summary/edit': [...rootPage[AssetType.Property], 'common:pageTabAction.edit'],
  '/properties/summary/edit-assets': [...rootPage[AssetType.Property], 'common:pageTabAction.edit'],
  '/properties/summary/create': [...rootPage[AssetType.Property], 'common:pageTabAction.create'],
  '/properties/summary/delete': [...rootPage[AssetType.Property], 'common:pageTabAction.delete'],
  // collectables
  '/collectables': ['collectables:PageTabs.MyCollectables'],
  '/collectables/summary': ['collectables:PageTabs.MyCollectables', 'common:pageTabAction.summary'],
  '/collectables/art': rootPage[AssetType.Art],
  '/collectables/art/list': [...rootPage[AssetType.Art], 'common:pageTabAction.list'],
  '/collectables/art/info': [...rootPage[AssetType.Art], 'common:pageTabAction.info'],
  '/collectables/art/edit': [...rootPage[AssetType.Art], 'common:pageTabAction.edit'],
  '/collectables/art/create': [...rootPage[AssetType.Art], 'common:pageTabAction.create'],
  '/collectables/art/delete': [...rootPage[AssetType.Art], 'common:pageTabAction.delete'],
  '/collectables/other': rootPage[AssetType.OtherCollectables],
  '/collectables/other/list': [...rootPage[AssetType.OtherCollectables], 'common:pageTabAction.list'],
  '/collectables/other/info': [...rootPage[AssetType.OtherCollectables], 'common:pageTabAction.info'],
  '/collectables/other/edit': [...rootPage[AssetType.OtherCollectables], 'common:pageTabAction.edit'],
  '/collectables/other/create': [...rootPage[AssetType.OtherCollectables], 'common:pageTabAction.create'],
  '/collectables/other/delete': [...rootPage[AssetType.OtherCollectables], 'common:pageTabAction.delete'],
  '/collectables/wine': rootPage[AssetType.WineAndSpirits],
  '/collectables/wine/list': [...rootPage[AssetType.WineAndSpirits], 'common:pageTabAction.list'],
  '/collectables/wine/info': [...rootPage[AssetType.WineAndSpirits], 'common:pageTabAction.info'],
  '/collectables/wine/create': [...rootPage[AssetType.WineAndSpirits], 'common:pageTabAction.create'],
  '/collectables/wine/delete': [...rootPage[AssetType.WineAndSpirits], 'common:pageTabAction.delete'],
  '/collectables/wine/add-purchase': [...rootPage[AssetType.WineAndSpirits], 'common:pageTabAction.add-purchase'],
  '/collectables/wine/catalogue': [...rootPage[AssetType.WineAndSpirits], 'common:pageTabAction.catalogue'],
  '/collectables/wine/reports': [...rootPage[AssetType.WineAndSpirits], 'common:pageTabAction.reports'],
  '/collectables/wine/location-details': [
    ...rootPage[AssetType.WineAndSpirits],
    'common:pageTabAction.location-details'
  ],
  // belongings
  '/belongings': rootPage[AssetType.Belonging],
  '/belongings/summary': [...rootPage[AssetType.Belonging], 'common:pageTabAction.summary'],
  '/belongings/summary/list': [...rootPage[AssetType.Belonging], 'common:pageTabAction.list'],
  '/belongings/summary/info': [...rootPage[AssetType.Belonging], 'common:pageTabAction.info'],
  '/belongings/summary/edit': [...rootPage[AssetType.Belonging], 'common:pageTabAction.edit'],
  '/belongings/summary/create': [...rootPage[AssetType.Belonging], 'common:pageTabAction.create'],
  '/belongings/summary/delete': [...rootPage[AssetType.Belonging], 'common:pageTabAction.delete'],
  // groups
  '/groups': ['nav:groups'],
  '/groups/summary': ['nav:groups', 'common:pageTabAction.summary'],
  '/groups/summary/info': ['nav:groups', 'common:pageTabAction.info'],
  '/groups/summary/edit': ['nav:groups', 'common:pageTabAction.edit'],
  '/groups/summary/create': ['nav:groups', 'common:pageTabAction.create'],
  '/groups/summary/delete': ['nav:groups', 'common:pageTabAction.delete'],
  // documents
  '/documents': ['nav:documents'],
  '/documents/summary': ['nav:documents', 'common:pageTabAction.summary'],
  '/documents/summary/create': ['nav:documents', 'common:pageTabAction.create'],
  '/documents/summary/log': ['nav:documents', 'common:pageTabAction.log'],
  // wishlist
  '/wishlist': ['nav:wishlist'],
  '/wishlist/info': ['nav:wishlist', 'common:pageTabAction.info'],
  '/wishlist/edit': ['nav:wishlist', 'common:pageTabAction.edit'],
  '/wishlist/create': ['nav:wishlist', 'common:pageTabAction.create'],
  '/wishlist/delete': ['nav:wishlist', 'common:pageTabAction.delete'],
  // identity
  '/identity': ['nav:setup'],
  '/identity/invitation': ['nav:setup'],
  '/identity/select': ['nav:setup']
}
