import { useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { validation } from '@/constants/validation'
import type { PopupMethod } from '@/types/common'
import { cn } from '@/utils/classnames'
import { usePasswordLeak } from '@/hooks/usePasswordLeak'
import { Button, FormInput } from '@/components/base'
import { SupportIcon } from '@/components/icon'
import PasswordBreachedIcon from '@/components/icon/PasswordBreachedIcon'

export type LoginValues = {
  email: string
  password: string
}

const defaultValues: LoginValues = {
  email: '',
  password: ''
}

interface LoginFormProps {
  values?: LoginValues
  onSubmit: SubmitHandler<LoginValues>
  onPopupLogin: (method: PopupMethod) => Promise<void>
}

export function LoginForm({ values, onSubmit, onPopupLogin }: LoginFormProps) {
  const { t } = useTranslation()
  const { isPasswordLeaked, checkPasswordLeak } = usePasswordLeak()

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors }
  } = useForm<LoginValues>({ defaultValues, values })

  const [email, password] = watch(['email', 'password'])
  const hasErrors = Object.keys(errors).length > 0

  return (
    <form
      id={'login-form'}
      className={'w-full max-w-[440px] rounded border border-grey/50 p-[50px]'}
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Title */}
      <h1 className={'my-5 text-[30px] font-bold leading-[35px] text-white'}>{t('auth:SignInToAccount')}</h1>

      {/* Support */}
      <Button asChild={true} className={'absolute right-4 top-4'}>
        <a
          href={'https://myassets.com/contact-us'}
          target={'_blank'}
          rel={'noopener noreferrer'}
          title={t('auth:ContactUs')}
        >
          <SupportIcon className={'transition-colors hover:text-primary'} />
        </a>
      </Button>

      {/* Email */}
      <FormInput
        className={'mb-5'}
        control={control}
        name={'email'}
        placeholder={t('auth:Field.Email')}
        displayErrorMode={'tooltip'}
        rules={{
          required: validation.requiredEmail.message(),
          validate: {
            checkEmail: (value) => validation.email.pattern.test(value) || validation.email.message()
          }
        }}
        onBlur={() => !hasErrors && checkPasswordLeak(email, password)}
      />

      {/* Password */}
      <FormInput
        className={'mb-4'}
        control={control}
        name={'password'}
        type={'password'}
        placeholder={t('auth:Field.Password')}
        displayErrorMode={'tooltip'}
        rules={{
          required: validation.requiredPassword.message()
        }}
        onBlur={() => !hasErrors && checkPasswordLeak(email, password)}
      />

      {isPasswordLeaked && (
        <div className={'mb-5 mt-[-10px] flex w-full items-center gap-3 rounded bg-red-500 p-3 text-sm text-white'}>
          <PasswordBreachedIcon size={40} />
          <div>
            <p className={'font-bold'}>{t('auth:PasswordLeaked')}</p>
            <p>{t('auth:PasswordLeakedDescription')}</p>
          </div>
        </div>
      )}

      <Button
        id={'login_forgot_password'}
        asChild={true}
        className={'mb-3 w-full text-sm text-primary hover:text-secondary-hover'}
      >
        <Link href={'/auth/forgot/'}>{t('auth:ForgotPassword')}</Link>
      </Button>

      <Button
        className={'group mb-4 w-full'}
        id={'login_login'}
        variant={'solid'}
        size={'md'}
        type={'submit'}
        disabled={isSubmitting}
      >
        {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
        <span className={cn({ 'opacity-0': isSubmitting })}>{t('auth:SignIn')}</span>
      </Button>

      <Button
        id={'registration_create_account'}
        asChild={true}
        variant={'outline'}
        size={'md'}
        className={'mb-6 w-full'}
      >
        <Link href={'/auth/signup/'}>{t('auth:CreateNewAccount')}</Link>
      </Button>

      <div className={'mb-7 flex items-center gap-3'}>
        <div className={'h-px w-full bg-grey'} />
        <span className={'shrink-0 text-sm font-light text-grey'}>{t('auth:OrSignInWith')}</span>
        <div className={'h-px w-full bg-grey'} />
      </div>

      <SocialLoginSection onPopupLogin={onPopupLogin} />
    </form>
  )
}

function SocialLoginSection({ onPopupLogin }: Pick<LoginFormProps, 'onPopupLogin'>) {
  const { t } = useTranslation()
  const { handleSubmit, formState } = useForm()
  const [currentMethod, setCurrentMethod] = useState<PopupMethod | null>(null)
  const methods = [
    { key: 'Google' as PopupMethod, icon: '/images/auth/google.svg' },
    { key: 'Apple' as PopupMethod, icon: '/images/auth/apple.svg' }
  ]

  const onClick = async (method: PopupMethod) => {
    setCurrentMethod(method)
    await handleSubmit(async () => await onPopupLogin(method))()
    setCurrentMethod(null)
  }

  return (
    <fieldset
      className={'flex h-10 items-center gap-x-2 [&>button]:h-full [&>button]:flex-1'}
      disabled={formState.isSubmitting}
    >
      {methods.map(({ key, icon }) => (
        <Button key={key} className={'group relative border-gray-200'} size={'sm'} onClick={() => onClick(key)}>
          {currentMethod === key && (
            <Loader2Icon className={'absolute animate-spin opacity-0 group-disabled:opacity-100'} />
          )}
          <Image
            className={cn(currentMethod === key && 'group-disabled:opacity-0')}
            src={icon}
            alt={key}
            width={24}
            height={24}
          />
          <span className={cn('whitespace-nowrap', currentMethod === key && 'group-disabled:opacity-0')}>
            {t('auth:SignInWithPlatform', { platform: key })}
          </span>
        </Button>
      ))}
    </fieldset>
  )
}
