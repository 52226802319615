import { getAllFieldNames, getFieldsByDecorator } from "../decorators";
import { instanceToPlain, plainToInstance } from "class-transformer";
import {
  Annotations,
  getEncryptableObjectFields,
} from "../decorators/annotations";
import { EncryptionFieldKey, IVSaltFieldKey } from "../encryption/utils";
import { IAggregateData } from "../types/common";
import { EncryptionLib } from "../types/encryptionLib";
import { UpdateObject, splitObject } from "../utils";

type DEKExistsInFirestore = () => Promise<boolean>;

export type EncryptionManager = {
  current: Encryption;
  self: Encryption;
};

type EncryptableObject = Record<string, unknown>;

type EncryptedObject<Model> = Partial<{
  [k in keyof Model]: undefined extends Model[k]
    ? NonNullable<Model[k]> extends Array<infer E>
      ? E extends string | number | boolean | Date
        ? E[] | undefined
        : EncryptedObject<E>[] | undefined
      : NonNullable<Model[k]> extends string | number | boolean | Date
      ? Model[k]
      : EncryptedObject<NonNullable<Model[k]>> | undefined
    : Model[k] extends Array<infer E>
    ? E extends string | number | boolean | Date
      ? E[]
      : EncryptedObject<E>[]
    : Model[k] extends string | number | boolean | Date
    ? Model[k]
    : EncryptedObject<Model[k]>;
}> & { [EncryptionFieldKey]?: { data: string; [IVSaltFieldKey]: string } };
export type Encrypted<Model extends object> = EncryptedObject<Model> &
  (Model extends { id: string } ? { id: string } : {}) & //Convenience assumption: id will always be unencrypted
  (Model extends IAggregateData ? IAggregateData : {}); // Convenience assumption: version will always be unencrypted

export function isEncrypted(
  testedObject: object
): testedObject is Encrypted<object> {
  if (!testedObject) return false;
  if (typeof testedObject !== "object") return false;
  if (EncryptionFieldKey in testedObject) return true;
  return false;
}

export class Encryption {
  private checkDekExists: DEKExistsInFirestore;
  private encryptionLib: EncryptionLib;
  private dek: string | null;

  constructor(
    checkDekExists: DEKExistsInFirestore,
    encryptionLib: EncryptionLib
  ) {
    this.checkDekExists = checkDekExists;
    this.encryptionLib = encryptionLib;
    this.dek = null;
  }

  /**
   * Loads the DEK from local storage or firestore, if it doesn't exist, it will create one.
   */
  async loadDEK(uid: string, idToken: string) {
    const lib = this.encryptionLib;
    const [storedDEK, dekExists] = await Promise.all([
      lib.loadDEKFromStorage(uid),
      this.checkDekExists(),
    ]);

    this.dek = storedDEK;

    if (this.dek) {
      // If DEK exists in local storage, but not in Firestore, save it to Firestore
      if (!dekExists) {
        const pubKey = await lib.getPublicKeyForEncrypt(idToken);
        const encrypted = await lib.encryptWithRSK(this.dek, pubKey);
        await lib.saveDEKForUser(encrypted, idToken);
      }

      return;
    }

    // If DEK doesn't exist in local storage
    if (dekExists) {
      const { publicKey, privateKey } = await lib.generateNCK();
      const encryptedDek = await lib.getDEKForUser(publicKey, idToken, uid);

      this.dek = await lib.decryptWithNCK(encryptedDek, privateKey);
    } else {
      // If DEK hasn't been created yet
      this.dek = await lib.generateDEK();
      const pubKey = await lib.getPublicKeyForEncrypt(idToken);
      const encrypted = await lib.encryptWithRSK(this.dek, pubKey);

      await lib.saveDEKForUser(encrypted, idToken);
    }

    await lib.storeDEKIntoStorage(this.dek, uid);
  }

  async encryptAndStringify<T extends object>(
    data: T,
    iv: Uint8Array
  ): Promise<string> {
    if (!this.dek) {
      throw new Error("DEK not loaded");
    }
    return await this.encryptionLib.encryptWithDEK(
      JSON.stringify(data),
      this.dek,
      iv
    );
  }

  async decryptAndStringify<T extends object>(
    data: string,
    iv: Uint8Array
  ): Promise<T> {
    if (!this.dek) {
      throw new Error("DEK not loaded");
    }

    if (data.length > 0) {
      return JSON.parse(
        await this.encryptionLib.decryptWithDEK(data, this.dek, iv)
      );
    } else {
      return <T>{};
    }
  }

  /**
   * Asynchronously encrypts an object (and nested objects) using fields anonated with '@EncryptedField' and '@EncryptableObject'.
   *
   * @param {D extends { [k: string]: any }} doc - The object to be encrypted.
   * @param {new () => D} cls - The class of the object to be encrypted (this class defines the fields to be encrypted).
   * @return {Promise<Encrypted<D>>} A promise that resolves to the encrypted object.
   */
  async encryptObject<D extends object>(
    doc: D,
    cls?: new () => D
  ): Promise<Encrypted<D>> {
    // Need to check if doc has EncryptionFieldKey field to prevent duplicating encryption
    if (doc.hasOwnProperty(EncryptionFieldKey)) {
      return doc as Encrypted<D>;
    }

    //We want to use a well formed class instance to support nested objects with subclasses
    if (cls) {
      doc = plainToInstance(cls, doc, { exposeUnsetFields: false });
    }

    // Ensure there is always a class to build from
    const prototype = Object.getPrototypeOf(doc).constructor;

    const encryptableObjectFields = getEncryptableObjectFields(prototype);
    const notEncryptedFields: string[] = getFieldsByDecorator(
      Annotations.NotEncrypted,
      prototype
    );
    const encryptableFields: string[] = getFieldsByDecorator(
      Annotations.EncryptableObject,
      prototype
    );

    // Always ensure that base firestore fields are not encrypted, otherwise relationships could get messed up
    notEncryptedFields.push("id");
    notEncryptedFields.push("version");

    const [clean, toBeEncrypted] = splitObject(
      instanceToPlain(doc, { exposeUnsetFields: false }),
      [...notEncryptedFields, ...encryptableFields]
    );

    var nested: { [k: string]: Encrypted<object> | Encrypted<object>[] } = {};
    for (const key in encryptableObjectFields) {
      const value = (<Record<string, unknown>>doc)[key] as object;
      if (value !== undefined) {
        if (Array.isArray(value)) {
          nested[key] = await Promise.all(
            value.map((item) => this.encryptObject(item))
          );
        } else if (typeof value === "object") {
          nested[key] = await this.encryptObject(value);
        } else {
          throw new Error(
            "field defined as an EncryptableObject must be an object or an array of objects"
          );
        }
      }
    }

    const encrypted: Encrypted<D> = {
      ...clean,
      ...(nested as Encrypted<D>),
    };

    if (Object.keys(toBeEncrypted).length > 0) {
      const iv = this.generateNewIVSalt();
      encrypted[EncryptionFieldKey] = {
        data: await this.encryptAndStringify(toBeEncrypted, iv),
        [IVSaltFieldKey]: this.convertIVSaltToBase64(iv),
      };
    }

    return encrypted;
  }

  async encryptUpdate<D extends { [k: string]: any }>(
    update: UpdateObject<D>,
    original: D,
    cls: new () => D
  ): Promise<UpdateObject<Encrypted<D>>> {
    const fullUpdatedObject = { ...original, ...update };
    const fullEncryptedUpdate = await this.encryptObject(
      fullUpdatedObject,
      cls
    );

    // Extract only the fields that have changed
    const encryptedUpdate = Object.entries(fullEncryptedUpdate).reduce(
      (acc, [k, v]) => {
        if (v === undefined && original[k as keyof D] !== undefined)
          return { ...acc, [k]: null };
        if (v !== original[k as keyof D]) return { ...acc, [k]: v };
        return acc;
      },
      {} as UpdateObject<Encrypted<D>>
    );

    return encryptedUpdate;
  }

  /**
   * Asynchronously decrypts an object (and nested objects) using fields anonated with '@EncryptedField' and '@EncryptableObject'.
   *
   * @param {Encrypted<D extends { [k: string]: any }>} encryptedDoc - The object to be decrypted.
   * @param {new () => D} cls - The class of the object to be encrypted (this class defines the fields to be encrypted).
   * @return {Promise<D>} A promise that resolves to the encrypted object.
   */
  async decryptObject<D extends { [k: string]: any }>(
    encryptedDoc: Encrypted<D>,
    cls: new () => D
  ): Promise<D> {
    var nested: { [k: string]: object } = {};
    const encryptableObjectFields = getEncryptableObjectFields(cls);
    //#NOTE: avoid duplicate decryption
    if (
      !encryptedDoc ||
      (encryptedDoc[EncryptionFieldKey] == undefined &&
        Object.keys(encryptableObjectFields).length == 0)
    ) {
      return encryptedDoc as unknown as D;
    }

    for (const key in encryptableObjectFields) {
      const nestedValue = encryptedDoc[key] as Encrypted<any>;
      const nestedConstructor = encryptableObjectFields[key]!;

      if (Array.isArray(nestedValue)) {
        const arrayNested = nestedValue as Array<any>;
        if (arrayNested.length > 0) {
          nested[key] = await Promise.all(
            arrayNested.map((item) =>
              this.decryptObject(item, nestedConstructor)
            )
          );
        }
      } else {
        nested[key] = await this.decryptObject(nestedValue, nestedConstructor);
      }
    }

    const decryptedPart = encryptedDoc[EncryptionFieldKey]
      ? await this.decryptAndStringify(
          encryptedDoc[EncryptionFieldKey]["data"],
          this.convertBase64ToIVSalt(
            encryptedDoc[EncryptionFieldKey][IVSaltFieldKey]
          )
        )
      : {};

    const decrypted = {
      ...encryptedDoc,
      ...decryptedPart,
      ...nested,
    };
    delete decrypted[EncryptionFieldKey];

    return decrypted as unknown as D;
  }

  /**
   * Encrypts the specified fields in the target object.
   * @param target - The object containing the fields to be encrypted.
   * @returns A promise that resolves to the encrypted object.
   * @throws An error if the DEK (Data Encryption Key) is not loaded.
   */
  async encryptFields(target: any, iv: Uint8Array): Promise<any> {
    if (!this.dek) {
      throw new Error("DEK not loaded");
    }

    if (!target._encryptedFields) {
      return target;
    }

    const result = { ...target };

    const encryptedObj: GenericObj = {};

    for (const key of target._encryptedFields) {
      encryptedObj[key] = result[key];
      delete result[key];
    }

    const data = JSON.stringify(result);
    result._encrypted = await this.encryptionLib.encryptWithDEK(
      data,
      this.dek,
      iv
    );
    return result;
  }

  /**
   * Encrypts a given string data.
   * @param data - The string data to be encrypted.
   * @returns A promise that resolves to the encrypted string.
   * @throws An error if the DEK (Data Encryption Key) is not loaded.
   */
  async encryptString(data: string, iv: Uint8Array): Promise<string> {
    if (!this.dek) {
      throw new Error("DEK not loaded");
    }

    const result = await this.encryptionLib.encryptWithDEK(data, this.dek, iv);
    return result;
  }

  async encryptBytes(
    target: Blob | Uint8Array | ArrayBuffer,
    iv: Uint8Array
  ): Promise<ArrayBuffer> {
    if (!this.dek) {
      throw new Error("DEK not loaded");
    }

    return this.encryptionLib.encryptBytesWithDEK(target, this.dek, iv);
  }

  async decryptBytes(
    target: ArrayBuffer,
    iv: Uint8Array
  ): Promise<ArrayBuffer> {
    if (!this.dek) {
      throw new Error("DEK not loaded");
    }

    return await this.encryptionLib.decryptBytesWithDEK(target, this.dek, iv);
  }

  /**
   * Decrypts the encrypted data in the target object.
   * @param target - The object containing the encrypted data.
   * @returns A promise that resolves when the decryption is complete.
   * @throws An error if the DEK (Data Encryption Key) is not loaded.
   */
  async decryptData(target: any): Promise<any> {
    if (!this.dek) {
      throw new Error("DEK not loaded");
    }

    if (!target._encrypted) {
      return target;
    }

    const decrypted = await this.encryptionLib.decryptWithDEK(
      target._encrypted.data,
      this.dek,
      this.encryptionLib.convertBase64ToIVSalt(target._encrypted._ivSalt)
    );

    const decryptedObj: GenericObj = JSON.parse(decrypted);

    for (const key of Object.keys(decryptedObj)) {
      target[key as keyof typeof target] = decryptedObj[key];
    }
    delete target._encrypted;

    return target;
  }

  /**
   * Decrypts the encrypted string.
   * @param data - The string data to be decrypted.
   * @param iv - The initialization vector used for encryption.
   * @returns A promise that resolves when the decryption is complete.
   * @throws An error if the DEK (Data Encryption Key) is not loaded.
   */
  async decryptString(data: string, iv: Uint8Array): Promise<string> {
    if (!this.dek) {
      throw new Error("DEK not loaded");
    }

    const decrypted = await this.encryptionLib.decryptWithDEK(
      data,
      this.dek,
      iv
    );

    return decrypted;
  }

  /**
   * Randonly generate new IV Salt
   * @returns {Uint8Array} The new IV Salt
   */
  generateNewIVSalt(): Uint8Array {
    return this.encryptionLib.generateNewIVSalt();
  }

  /**
   * ConvertIVSaltToBase64
   * @param {Uint8Array} iv - The IV Salt
   * @returns {string} The base64 encoded IV Salt
   */
  convertIVSaltToBase64(iv: Uint8Array): string {
    return this.encryptionLib.convertIVSaltToBase64(iv);
  }

  /**
   * ConvertBase64ToIVSalt
   * @param {string} base64 - The base64 encoded IV Salt
   * @returns {Uint8Array} iv - The IV Salt
   */
  convertBase64ToIVSalt(base64: string): Uint8Array {
    return this.encryptionLib.convertBase64ToIVSalt(base64);
  }
}

export function getEncryptedFields(target: any) {
  return target._encryptedFields;
}

export function getEncryptedKeysArray<T extends object>(cls: new () => T) {
  const notEncryptedFields = getFieldsByDecorator(
    Annotations.NotEncrypted,
    cls
  );
  const encryptableObjFields = getFieldsByDecorator(
    Annotations.EncryptableObject,
    cls
  );
  const allFeilds = getAllFieldNames(cls);

  return allFeilds.filter(
    (f) => !notEncryptedFields.includes(f) && !encryptableObjFields.includes(f)
  );
}

export function fillBackEncryptedFields<D extends { [k: string]: any }>(
  currentData: D,
  updates: UpdateObject<D>,
  cls: new () => D
) {
  const encryptedKeysArray: (keyof D & keyof UpdateObject<D> & string)[] =
    getEncryptedKeysArray(cls);
  const encryptedFieldsUpdated = encryptedKeysArray.reduce(
    (result, key) => result || Object.keys(updates).includes(key),
    false
  );

  if (encryptedFieldsUpdated) {
    for (const key of encryptedKeysArray) {
      if (updates[key] !== null) {
        updates[key] ??= currentData[key];
      }
    }
  }

  return updates;
}

interface GenericObj {
  [key: string]: any;
}
