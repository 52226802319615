import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23eee%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M12%2022q-3.476-.875-5.738-3.988T4%2011.1V5l8-3%208%203v6.1q0%203.8-2.262%206.913T12%2022m-2-6h4a.97.97%200%200%200%20.713-.288A.96.96%200%200%200%2015%2015v-3a.97.97%200%200%200-.288-.712A.97.97%200%200%200%2014%2011v-1q0-.825-.587-1.412A1.93%201.93%200%200%200%2012%208q-.825%200-1.412.588Q10.002%209.177%2010%2010v1a.97.97%200%200%200-.712.288A.97.97%200%200%200%209%2012v3q0%20.425.288.713T10%2016m1-5v-1q0-.424.288-.712A.97.97%200%200%201%2012%209q.424%200%20.713.288A.96.96%200%200%201%2013%2010v1z%22%20%2F%3E%3C%2Fsvg%3E)
 */
export default function TwoFactorAuthIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={'0 0 24 24'}
      fill={'currentColor'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        d={
          'M12 22q-3.476-.875-5.738-3.988T4 11.1V5l8-3 8 3v6.1q0 3.8-2.262 6.913T12 22m-2-6h4a.97.97 0 0 0 .713-.288A.96.96 0 0 0 15 15v-3a.97.97 0 0 0-.288-.712A.97.97 0 0 0 14 11v-1q0-.825-.587-1.412A1.93 1.93 0 0 0 12 8q-.825 0-1.412.588Q10.002 9.177 10 10v1a.97.97 0 0 0-.712.288A.97.97 0 0 0 9 12v3q0 .425.288.713T10 16m1-5v-1q0-.424.288-.712A.97.97 0 0 1 12 9q.424 0 .713.288A.96.96 0 0 1 13 10v1z'
        }
      />
    </svg>
  )
}
