import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import * as d3 from 'd3'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { chartColors, emptyColors } from '@/constants/chartConfig'
import { cn } from '@/utils/classnames'
import { capitalize, formatNumber, formatNumberShort } from '@/utils/formatter'
import { useTooltip } from '@/hooks/useTooltip'
import { useAuthStore } from '@/store/authStore'
import TruncatedText from '@/components/base/TruncatedText'
import ResponsiveContainer from '@/components/chart/ResponsiveContainer'
import Tooltip from '@/components/chart/Tooltip'
import { PrivacyField } from '@/components/PrivacyField'

export type TreemapItem = {
  name: string
  value: number
}

type TooltipData = TreemapItem & {
  color: string
}

const mockData: TreemapItem[] = [
  { name: 'MyCollectables', value: 4000 },
  { name: 'MyFinances', value: 3000 },
  { name: 'MyProperties', value: 2000 },
  { name: 'MyBelongings', value: 1000 }
]

const emptyData: TreemapItem[] = [
  { name: 'Mock 1', value: 60 },
  { name: 'Mock 2', value: 25 },
  { name: 'Mock 3', value: 20 },
  { name: 'Mock 4', value: 5 }
]

const MINIMUM_PERCENTAGE = 0.01

interface TreemapChartProps {
  className?: string
  data?: TreemapItem[]
  unit?: string
  onNavigate?: (value: string) => void
  formatter?: (value: string, unit: string) => string
  parse?: (value: string) => string
}

export function TreemapChart({
  data = [],
  unit = 'USD',
  onNavigate,
  formatter = (value, unit) => `${unit} ${value}`,
  parse = (value) => value
}: TreemapChartProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const { tooltipData, showTooltip, hideTooltip } = useTooltip<TooltipData>()
  const isEmpty = data.every(({ value }) => value === 0)
  const dataSource = (isEmpty ? emptyData : data).sort((a, b) => Math.abs(b.value) - Math.abs(a.value))
  const colors = isEmpty ? emptyColors : chartColors
  const total = useMemo(() => d3.sum(dataSource, (d) => d.value), [dataSource])
  const adjustAreaList = useMemo(
    () =>
      dataSource.reduce((arr, item) => {
        if (item.value === 0) return arr
        const value = Math.abs(item.value)
        const minValue = Math.abs(total * MINIMUM_PERCENTAGE)
        return [...arr, Math.max(value, minValue)]
      }, [] as number[]),
    [dataSource, total]
  )
  const toolTipPercent = ((tooltipData?.data.value ?? 0) / total) * 100

  const handleMouseMove = (e: MouseEvent<SVGRectElement>, data: TooltipData) => {
    const left = e.clientX + 260
    const covered = left - window.innerWidth
    showTooltip({
      top: e.clientY - 50,
      left: covered > 0 ? e.clientX - covered : e.clientX,
      data
    })
  }

  return (
    <>
      <ResponsiveContainer width={'100%'} height={320}>
        {({ width, height }) => {
          const root = d3.hierarchy({ children: adjustAreaList }).sum((d: any) => d)
          const treemapRoot = d3.treemap<number>().size([width, height])(root as any)
          return (
            <svg width={width} height={height}>
              {treemapRoot.leaves().map((node, index) => (
                <LeafItem
                  key={index}
                  x={node.x0}
                  y={node.y0}
                  width={node.x1 - node.x0}
                  height={node.y1 - node.y0}
                  color={colors[index % colors.length]}
                  label={capitalize(parse(dataSource[index].name))}
                  value={formatter(
                    formatNumber(dataSource[index].value, preferences.numberFormat, { digits: 0 }),
                    unit
                  )}
                  isEmpty={isEmpty}
                  onNavigate={() => onNavigate && onNavigate(dataSource[index].name)}
                  onMouseMove={(e) =>
                    handleMouseMove(e, { ...dataSource[index], color: colors[index % colors.length] })
                  }
                  onMouseLeave={hideTooltip}
                />
              ))}

              {isEmpty && (
                <g transform={`translate(${width / 2}, ${height / 2})`}>
                  <text
                    className={'drop-shadow'}
                    y={6}
                    fill={'#fff'}
                    textAnchor={'middle'}
                    fontSize={'24'}
                    fontWeight={'bold'}
                  >
                    {t('NoData')}
                  </text>
                </g>
              )}
            </svg>
          )
        }}
      </ResponsiveContainer>
      {tooltipData && (
        <Tooltip top={tooltipData.top} left={tooltipData.left}>
          <div className={'flex items-center justify-between'}>
            <div className={'flex min-w-0 flex-1 items-center gap-x-1'}>
              <div className={'my-0.5 h-4 w-4 shrink-0 rounded'} style={{ backgroundColor: tooltipData.data.color }} />
              <TruncatedText as={'span'} className={'text-sm font-bold capitalize text-white'}>
                {parse(tooltipData.data.name)}
              </TruncatedText>
            </div>
          </div>
          <div className={'flex items-center justify-between'}>
            {toolTipPercent && (
              <TruncatedText as={'span'} className={'ml-5 text-sm font-bold capitalize text-white'}>
                {`${toolTipPercent.toFixed(1)}%`}
              </TruncatedText>
            )}
            <PrivacyField className={'flex-1 shrink-0 text-right text-sm font-bold text-white'}>
              {formatter(formatNumberShort(tooltipData.data.value), unit)}
            </PrivacyField>
          </div>
        </Tooltip>
      )}
    </>
  )
}

interface LeafItemProps {
  x: number
  y: number
  width: number
  height: number
  color: string
  label: string
  value: string
  isEmpty: boolean
  onNavigate?: () => void
  onMouseMove: (e: MouseEvent<SVGRectElement>) => void
  onMouseLeave: () => void
}

function LeafItem({
  x,
  y,
  width,
  height,
  color,
  label,
  value,
  isEmpty,
  onNavigate,
  onMouseMove,
  onMouseLeave
}: LeafItemProps) {
  const ref = useRef<SVGGElement>(null)
  const [textWidth, setTextWidth] = useState(0)
  const margin = 8
  const opacity = textWidth > width || isEmpty ? 0 : 1

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        const entry = entries[0]
        setTextWidth(entry.contentRect.width)
      }
    })
    if (ref.current) observer.observe(ref.current)
    return () => observer.disconnect()
  }, [])

  return (
    <g className={'cursor-pointer'} transform={`translate(${x},${y})`} onClick={onNavigate}>
      <rect
        className={cn(isEmpty && 'pointer-events-none')}
        width={width}
        height={height}
        fill={color}
        stroke={'#fff'}
        strokeWidth={4}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
      <g ref={ref} className={'pointer-events-none'} transform={`translate(${margin},${margin})`} opacity={opacity}>
        <text y={14} fill={'#fff'} fontSize={14} className={'font-medium'}>
          {label}
        </text>
        <text y={36} fill={'#fff'} fontSize={14} className={'font-medium'}>
          <PrivacyField>{value}</PrivacyField>
        </text>
      </g>
    </g>
  )
}
