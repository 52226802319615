import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import * as d3 from 'd3'
import { useTranslation } from 'react-i18next'

import { chartColors, emptyColors } from '@/constants/chartConfig'
import { cn } from '@/utils/classnames'
import { capitalize, formatNumberShort } from '@/utils/formatter'
import { useTooltip } from '@/hooks/useTooltip'
import TruncatedText from '@/components/base/TruncatedText'
import ResponsiveContainer from '@/components/chart/ResponsiveContainer'
import Tooltip from '@/components/chart/Tooltip'
import { PrivacyField } from '@/components/PrivacyField'

export type BubbleItem = {
  name: string
  value: number
}

type TooltipData = BubbleItem & {
  color: string
}

const emptyData: BubbleItem[] = [
  { name: 'Mock 1', value: 60 },
  { name: 'Mock 2', value: 25 },
  { name: 'Mock 3', value: 20 },
  { name: 'Mock 4', value: 5 }
]

function getTopSix(items: BubbleItem[], name: string) {
  const sortedItems = items.sort((a, b) => b.value - a.value)

  const topSix = sortedItems.slice(0, 6)
  const others = sortedItems.slice(6)
  const othersValue = others.reduce((acc, cur) => acc + cur.value, 0)

  if (others.length > 0) {
    topSix.push({ name, value: othersValue })
  }

  return topSix
}

interface BubbleChartProps {
  data?: BubbleItem[]
  width?: number
  height?: number
  unit?: string
  onNavigate?: (value: string, isOthers: boolean) => void
  formatter?: (value: string, unit: string) => string
}

const MINIMUM_PERCENTAGE = 0.005

export function BubbleChart({
  data = [],
  height = 240,
  unit = 'USD',
  onNavigate,
  formatter = (value, unit) => `${unit} ${value}`
}: BubbleChartProps) {
  const { t } = useTranslation()
  const { tooltipData, showTooltip, hideTooltip } = useTooltip<TooltipData>()
  const isEmpty = data.every(({ value }) => value === 0)
  const colors = isEmpty ? emptyColors : chartColors
  const dataSource = isEmpty ? emptyData : getTopSix(data, t('Others'))
  const total = useMemo(() => d3.sum(dataSource, (d) => d.value), [dataSource])
  const adjustRadiusList = useMemo(
    () =>
      dataSource.reduce((arr, item) => {
        if (item.value === 0) return arr
        const value = Math.abs(item.value)
        const minValue = Math.abs(total * MINIMUM_PERCENTAGE)
        return [...arr, Math.max(value, minValue)]
      }, [] as number[]),
    [dataSource, total]
  )
  const toolTipPercent = ((tooltipData?.data.value ?? 0) / total) * 100

  const handleMouseMove = (e: MouseEvent<SVGCircleElement>, data: TooltipData) => {
    const left = e.clientX + 260
    const covered = left - window.innerWidth
    showTooltip({
      top: e.clientY - 50,
      left: covered > 0 ? e.clientX - covered : e.clientX,
      data
    })
  }

  return (
    <div className={'relative flex justify-center'}>
      <ResponsiveContainer width={'100%'} height={height}>
        {({ width, height }) => {
          const root = d3.hierarchy({ children: adjustRadiusList }).sum((d: any) => d)
          const bubbleRoot =
            width >= 1 && height >= 1 ? d3.pack<BubbleItem>().size([width, height]).padding(4)(root as any) : null
          return (
            <svg width={width} height={height}>
              {bubbleRoot &&
                bubbleRoot.leaves().map((node, index) => {
                  return (
                    <BubbleItem
                      key={index}
                      cx={node.x}
                      cy={node.y}
                      radius={node.r}
                      color={colors[index % colors.length]}
                      label={capitalize(dataSource[index].name)}
                      isEmpty={isEmpty}
                      onNavigate={() =>
                        onNavigate && onNavigate(dataSource[index].name, dataSource[index].name === t('Others'))
                      }
                      onMouseMove={(e) =>
                        handleMouseMove(e, { ...dataSource[index], color: colors[index % colors.length] })
                      }
                      onMouseLeave={hideTooltip}
                    />
                  )
                })}

              {isEmpty && (
                <g transform={`translate(${width / 2},${height / 2})`}>
                  <text
                    className={'drop-shadow'}
                    y={6}
                    fill={'#fff'}
                    textAnchor={'middle'}
                    fontSize={'24'}
                    fontWeight={'bold'}
                  >
                    {t('NoData')}
                  </text>
                </g>
              )}
            </svg>
          )
        }}
      </ResponsiveContainer>
      {tooltipData && (
        <Tooltip top={tooltipData.top} left={tooltipData.left}>
          <div className={'flex items-center justify-between'}>
            <div className={'flex min-w-0 flex-1 items-center gap-x-1'}>
              <div className={'my-0.5 h-4 w-4 shrink-0 rounded'} style={{ backgroundColor: tooltipData.data.color }} />
              <TruncatedText className={'text-sm font-bold capitalize text-white'}>
                {tooltipData.data.name}
              </TruncatedText>
            </div>
          </div>
          <div className={'flex items-center justify-between'}>
            {toolTipPercent && (
              <TruncatedText as={'span'} className={'ml-5 text-sm font-bold capitalize text-white'}>
                {`${toolTipPercent.toFixed(1)}%`}
              </TruncatedText>
            )}
            <PrivacyField className={'flex-1 shrink-0 text-right text-sm font-bold text-white'}>
              {formatter(formatNumberShort(tooltipData.data.value), unit)}
            </PrivacyField>
          </div>
        </Tooltip>
      )}
    </div>
  )
}

interface BubbleItemProps {
  cx: number
  cy: number
  radius: number
  color: string
  label: string
  isEmpty: boolean
  onNavigate: () => void
  onMouseMove: (e: MouseEvent<SVGCircleElement>) => void
  onMouseLeave: () => void
}

function BubbleItem({ cx, cy, radius, color, label, isEmpty, onNavigate, onMouseMove, onMouseLeave }: BubbleItemProps) {
  const ref = useRef<SVGGElement>(null)
  const [textWidth, setTextWidth] = useState(0)
  const margin = 8
  const opacity = textWidth > (radius - margin) * 2 || isEmpty ? 0 : 1

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        const entry = entries[0]
        setTextWidth(entry.contentRect.width)
      }
    })
    if (ref.current) observer.observe(ref.current)
    return () => observer.disconnect()
  }, [])

  return (
    <g transform={`translate(${cx - radius},${cy - radius})`} onClick={onNavigate} className={'hover:cursor-pointer'}>
      <circle
        className={cn(isEmpty && 'pointer-events-none')}
        cx={radius}
        cy={radius}
        r={radius}
        fill={color}
        stroke={'#fff'}
        strokeWidth={1}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
      <g
        ref={ref}
        className={'pointer-events-none'}
        transform={`translate(${radius},${radius - 10})`}
        x={radius}
        y={radius - 10}
        opacity={opacity}
      >
        <text y={14} fill={'#fff'} textAnchor={'middle'}>
          {label}
        </text>
      </g>
    </g>
  )
}
