import {
  Amount,
  AssetType,
  AssetV2,
  MultiCurrencyAmount,
  compareGroupUpdate,
  Attachment,
  PathsOfAmountField,
  PeriodWithNumber,
} from "../common";
import { EncryptedType, RequireEncryptionFields } from "../../encryption/utils";
import { ErrorDataOutDated, InvalidInput } from "../error";
import {
  addDecimal,
  subDecimal,
  OptionalSimpleTypeKeysOf,
  SimpleTypeKeysOf,
  UpdateObject,
  buildObjectUpdate,
} from "../../utils";
import {
  Account,
  AccountState,
  AccountTransaction,
  Category,
  Command,
  Event,
} from "../cashAndBanking";
import { CoreFirestore, WithFieldValue } from "../../../coreFirebase";
import {
  CreditCardTypeVersion,
  VersionedType,
  VersionedTypeString,
  validateTypeUpToDate,
} from "../typeVersion";

export enum CreditCardType {
  VISA = "VISA",
  Mastercard = "Mastercard",
  AmericanExpress = "American Express",
  Discover = "Discover",
}

export interface CreditCard extends Account.Base {
  "@type": VersionedTypeString<VersionedType.CreditCard, 2>;
  subtype: Account.Type.CreditCardAccount;
  cardType: CreditCardType;

  //   @Encrypted
  cardLastFourDigits: string;
  creditLimit: Amount;
  //#NOTE AvailableCredit = creditLimit + value
  // - valuation is usually negative, AvailableCredit is ok to be negative
  validateFrom: Date;
  expiresEnd: Date;
  //   @Encrypted
  nameOnCard?: string;
  statementFrequency: PeriodWithNumber;
  paymentDate: Date;
}
export namespace CreditCard {
  export function assureVersion(
    input: CreditCard | Encrypted,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      CreditCardTypeVersion,
      errorOnCoreOutDated
    );
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.CreditCard);
  }

  export const amountPaths: readonly PathsOfAmountField<CreditCard>[] = [
    "creditLimit",
    "value",
  ] as const;
  export type Create = Pick<
    CreditCard,
    | "id"
    | "name"
    | "subtype"
    | "creditLimit"
    | "country"
    | "institution"
    | "cardType"
    | "cardLastFourDigits"
    | "validateFrom"
    | "notes"
    | "expiresEnd"
    | "nameOnCard"
    | "groupIds"
    | "statementFrequency"
    | "paymentDate"
    | "attachments"
    | "value"
    | "extSource"
    | "extId"
  >;
  export type Update = Pick<
    CreditCard,
    | "name"
    | "country"
    | "institution"
    | "cardType"
    | "cardLastFourDigits"
    | "creditLimit"
    | "notes"
    | "expiresEnd"
    | "statementFrequency"
    | "paymentDate"
    | "nameOnCard"
    | "groupIds"
    | "attachments"
  >;
  export type UpdateEncrypted = RequireEncryptionFields<
    EncryptedType<Update, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;
  export type EncryptedKeys =
    | AssetV2.EncryptedKeys
    | "cardLastFourDigits"
    | "nameOnCard";
  export type Encrypted = RequireEncryptionFields<
    EncryptedType<CreditCard, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;
  export type EncryptedPart = Pick<CreditCard, EncryptedKeys>;

  export function fromCreate(from: Create, ownerId: string): CreditCard {
    const data: WithFieldValue<CreditCard> = {
      ...from,
      version: 0,
      ownerId,
      createAt: CoreFirestore.serverTimestamp(),
      updateAt: CoreFirestore.serverTimestamp(),
      assetType: AssetType.CashAndBanking,
      "@type": CreditCardTypeVersion,
    };
    return data as CreditCard;
  }

  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<Update>[] = [
    "name",
    "institution",
    "cardType",
    "cardLastFourDigits",
  ];
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<Update>[] = [
    "country",
    "notes",
    "nameOnCard",
  ];
  export function intoUpdate(
    current: CreditCard,
    update: Update
  ): {
    updates: UpdateObject<Update>;
    metadata: {
      addedToGroup: AssetV2["groupIds"];
      removedFromGroup: AssetV2["groupIds"];
    };
  } {
    const metadata: any = {};
    const baseUpdateFields = buildObjectUpdate(
      current,
      update,
      NonOptionalSimpleTypeUpdatableKeys,
      OptionalSimpleTypeUpdatableKeys
    );

    if (!Amount.equal(current.creditLimit, update.creditLimit)) {
      baseUpdateFields.creditLimit = update.creditLimit;
    }
    if (current.expiresEnd.getTime() !== update.expiresEnd.getTime()) {
      baseUpdateFields.expiresEnd = update.expiresEnd;
    }
    if (current.paymentDate.getTime() !== update.paymentDate.getTime()) {
      baseUpdateFields.paymentDate = update.paymentDate;
    }
    if (
      current.statementFrequency.num !== update.statementFrequency.num ||
      current.statementFrequency.period !== update.statementFrequency.period
    ) {
      baseUpdateFields.statementFrequency = update.statementFrequency;
    }

    const { fieldUpdate: groupIdUpdate, groupChanges } = compareGroupUpdate(
      current.groupIds,
      update.groupIds
    );
    if (groupIdUpdate !== undefined) {
      baseUpdateFields.groupIds = groupIdUpdate;
    }
    if (groupChanges.addedToGroup)
      metadata.addedToGroup = groupChanges.addedToGroup;
    if (groupChanges.removedFromGroup)
      metadata.removedFromGroup = groupChanges.removedFromGroup;
    const { attachments, newImages } = Attachment.compareUpdate(
      current,
      update
    );
    if (newImages.length > 0) metadata.newImages = newImages;
    if (attachments !== undefined) baseUpdateFields.attachments = attachments;

    return { updates: baseUpdateFields, metadata };
  }

  export function validateEncryptedPart(
    data: UpdateObject<EncryptedPart> & {
      attachments?: Attachment.EncryptedPart[];
    },
    isCreate: boolean = false
  ) {
    const fourDigitsRegex = /^\d{4}$/;
    if (
      (isCreate || data.cardLastFourDigits) &&
      !fourDigitsRegex.test(data.cardLastFourDigits!)
    ) {
      throw new InvalidInput("Invalid card last four digits");
    }
    // optional fields
    if (data.attachments) {
      data.attachments.forEach((attachment) =>
        Attachment.validateEncryptedPart(attachment)
      );
    }
  }

  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean
  ) {
    for (const key of amountPaths) {
      if (data[key]) Amount.validate(key, data[key]!);
    }
    if (isCreate) {
      if (!data.validateFrom)
        throw new InvalidInput("Validate from is required");
      if (!data.expiresEnd) throw new InvalidInput("Expires end is required");
      if (!data.paymentDate) throw new InvalidInput("Payment date is required");
    }
    if ((isCreate || data.creditLimit) && data.creditLimit!.value < 0) {
      throw new InvalidInput("Credit limit cannot be negative");
    }
    if ((isCreate || data.value) && data.value!.value > 0) {
      throw new InvalidInput("Credit card cannot have positive value");
    }
    if (
      (isCreate || data.statementFrequency) &&
      data.statementFrequency!.num < 0
    ) {
      throw new InvalidInput("Statement frequency cannot be negative");
    }
  }

  export function handle(
    state: AccountState<Encrypted>,
    command: Command
  ): Event[] {
    const events: Event[] = [];
    switch (command.kind) {
      case Command.Kind.CreateAsset:
        {
          events.push({
            kind: Event.Kind.AssetCreated,
            executerId: command.executerId,
            asset: command.asset,
          });
          const asset = command.asset as Encrypted;
          if (asset.groupIds && asset.groupIds.length > 0) {
            events.push({
              kind: Event.Kind.GroupsUpdated,
              executerId: command.executerId,
              addIds: asset.groupIds,
              removedIds: [],
            });
          }
          const txId = asset.id;
          const txData = AccountTransaction.systemAccountCreation(
            asset.subtype,
            txId,
            asset.id,
            undefined,
            command.executerId,
            asset.value,
            //#HACK nothing encrypted, and this Transaction cannot update, the decryption will be skipped
            ""
          );
          events.push({
            kind: Event.Kind.TransactionAdded,
            executerId: command.executerId,
            parentId: asset.id,
            id: txId,
            data: txData,
            valueChange: AccountTransaction.getNumericSignedValue(txData),
          });
        }
        break;
      case Command.Kind.UpdateAsset:
        {
          if (state.account.closedWith) {
            throw new InvalidInput("Account is already closed");
          }
          const { executerId, asset, addedToGroup, removedFromGroup } = command;
          events.push({
            executerId: executerId,
            kind: Event.Kind.AssetUpdated,
            asset,
          });

          if (addedToGroup || removedFromGroup) {
            events.push({
              executerId: executerId,
              kind: Event.Kind.GroupsUpdated,
              addIds: addedToGroup ?? [],
              removedIds: removedFromGroup ?? [],
            });
          }
        }
        break;
      case Command.Kind.CloseAsset:
        if (state.account.value.value !== 0) {
          throw new InvalidInput("Account balance is not zero");
        }
        events.push({
          kind: Event.Kind.AccountClosed,
          executerId: command.executerId,
        });
        break;

      case Command.Kind.AddTransaction:
        if (state.account.closedWith) {
          throw new InvalidInput("Account is already closed");
        }
        if (state.account.extId || state.account.extSource) {
          throw new InvalidInput("CreditCard account is from external source");
        }
        //#NOTE value check, if required
        events.push({
          kind: Event.Kind.TransactionAdded,
          executerId: command.executerId,
          parentId: state.account.id,
          id: command.id,
          data: command.data,
          valueChange: AccountTransaction.getNumericSignedValue(command.data),
        });
        break;
      case Command.Kind.UpdateTransaction:
        {
          if (state.account.closedWith) {
            throw new InvalidInput("Account is already closed");
          }
          if (state.account.extId || state.account.extSource) {
            throw new InvalidInput(
              "CreditCard account is from external source"
            );
          }
          const currentTx = state.transactions[command.id];
          if (!currentTx) {
            throw new InvalidInput("Transaction not found");
          }
          if (currentTx.category === Category.SystemAccountCreation) {
            throw new InvalidInput(
              "Cannot update system account creation transaction"
            );
          }
          events.push({
            kind: Event.Kind.TransactionUpdated,
            executerId: command.executerId,
            parentId: state.account.id,
            id: command.id,
            update: command.update,
            valueChange: AccountTransaction.calculateValueChange(
              currentTx,
              command.update
            ),
          });
        }
        break;
      case Command.Kind.DeleteTransaction:
        {
          if (state.account.closedWith) {
            throw new InvalidInput("Account is already closed");
          }
          if (state.account.extId || state.account.extSource) {
            throw new InvalidInput(
              "CreditCard account is from external source"
            );
          }
          const currentTx = state.transactions[command.id];
          if (!currentTx) {
            throw new InvalidInput("Transaction not found");
          }
          if (currentTx.category === Category.SystemAccountCreation) {
            throw new InvalidInput(
              "Cannot update system account creation transaction"
            );
          }
          events.push({
            kind: Event.Kind.TransactionDeleted,
            executerId: command.executerId,
            parentId: state.account.id,
            id: command.id,
            valueChange: Amount.toNegative(
              AccountTransaction.getNumericSignedValue(currentTx)
            ),
          });
        }
        break;

      case Command.Kind.OverwriteValue: {
        if (
          state.account.extId === undefined ||
          state.account.extSource === undefined
        ) {
          throw new InvalidInput("Account is not from external source");
        }
        const event: Event.ValueUpdated = {
          kind: Event.Kind.ValueUpdated,
          executerId: command.executerId,
          valueChange: {},
        };
        if (command.primaryValue.currency != state.account.value.currency) {
          throw new InvalidInput("Currency mismatch");
        }
        event.valueChange[state.account.value.currency] = subDecimal(
          command.primaryValue.value,
          state.account.value.value
        );
        event.primaryValue = command.primaryValue;
        events.push(event);
        break;
      }
      default:
        throw new Error("unreachable");
    }
    return events;
  }

  export function updateValue(
    account: Encrypted,
    valueChange: MultiCurrencyAmount
  ) {
    account.value.value = addDecimal(
      account.value.value,
      valueChange[account.value.currency] || 0
    );
  }
}
