import { Amount, AssetType, Attachment, Optional } from "../common";
import { AssetV2 } from "../common";
import { applyUpdateToObject } from "../../utils";
import {
  AggregateBase,
  SupportActionAggregate,
  AggregateRoot,
  SupportSellAggregate,
  SupportValuationAggregate,
  handleAddInsurance,
  handleRemoveInsurance,
  setObjectDeleted,
} from "../aggregate";
import { InvalidInput } from "../error";
import { EventWithTime, preSealEvent, SharedEvent } from "../event";
import { OwnerDetail } from "./ownerDetail";
import { Command, Event } from "./command";

import { PropertyUtils } from "./propertyUtils";
import { Property } from "./property";
import { OwnershipType } from "./ownershipType";
import { Encrypted } from "../../database/encryption";
import { Acquisition } from "../common/acquisition";

export type PropertyAggregateRoot = AggregateRoot<
  Encrypted<Property>,
  Command,
  Event
>;

export class PropertyAggregate extends AggregateBase<
  PropertyUtils.State,
  Command,
  Event
> {
  state: PropertyUtils.State;
  kind: string;
  relatedReads?: PropertyUtils.RelatedReads;
  relatedUpdates: PropertyUtils.RelatedUpdates = {};

  constructor(
    state: PropertyUtils.State,
    relatedReads?: PropertyUtils.RelatedReads
  ) {
    super();
    this.state = state;
    this.kind = AssetType.Property;
    if (relatedReads) this.relatedReads = relatedReads;
  }

  handle(command: Command): EventWithTime<Event>[] {
    switch (command.kind) {
      case Command.Kind.CreateAsset:
        return this.handleCreateAsset(command).map(preSealEvent);
      case Command.Kind.UpdateAsset:
        return this.handleUpdateAsset(command).map(preSealEvent);
      case Command.Kind.DeleteAsset:
        return this.handleDeleteAsset(command).map(preSealEvent);
      case Command.Kind.ArchiveAsset:
        return this.handleArchiveAsset(command).map(preSealEvent);
      case Command.Kind.RestoreArchivedAsset:
        return this.handleRestoreArchivedAsset(command.executerId).map(
          preSealEvent
        );
      case Command.Kind.AddInsurance:
        if (this.state.ownershipType === OwnershipType.Rent)
          throw new InvalidInput("Cannot add insurance to rental property");
        return handleAddInsurance(
          (<OwnerDetail>this.state.detail).acquisition?.insuranceIds,
          command
        ).map(preSealEvent);
      case Command.Kind.RemoveInsurance:
        if (this.state.ownershipType === OwnershipType.Rent)
          throw new InvalidInput(
            "Cannot remove insurance from to rental property"
          );
        return handleRemoveInsurance(
          (<OwnerDetail>this.state.detail).acquisition?.insuranceIds,
          command
        ).map(preSealEvent);

      case Command.Kind.AddValuation:
        return SupportValuationAggregate.handleAdd<any>(this, command);
      case Command.Kind.UpdateValuation:
        return SupportValuationAggregate.handleUpdate<any>(this, command);
      case Command.Kind.DeleteValuation:
        return SupportValuationAggregate.handleDelete<any>(this, command);
      case Command.Kind.MarkAsSold:
        return SupportSellAggregate.handleAdd<any>(this, command);
      case Command.Kind.UpdateSoldInfo:
        return SupportSellAggregate.handleUpdate<any>(this, command);
      case Command.Kind.DeleteSoldInfo:
        return SupportSellAggregate.handleDelete<any>(this, command);

      case Command.Kind.AddAction:
        return SupportActionAggregate.handleAdd<
          Encrypted<Property>,
          PropertyUtils.SupportActionsEncrypted
        >(this, command);
      case Command.Kind.UpdateAction:
        return SupportActionAggregate.handleUpdate<
          Encrypted<Property>,
          PropertyUtils.SupportActionsUpdate
        >(this, command);
      case Command.Kind.DeleteAction:
        return SupportActionAggregate.handleDelete(this, command);
    }
  }

  apply({ data: event, time }: EventWithTime<Event>): this {
    switch (event.kind) {
      case Event.Kind.AssetCreated:
        this.state = { ...event.asset, items: this.state.items };
        if (
          (<OwnerDetail>this.state.detail).acquisition &&
          (<OwnerDetail>this.state.detail).acquisition.insuranceIds
        )
          this.relatedUpdates.addedInsuranceIds = (<OwnerDetail>(
            this.state.detail
          )).acquisition.insuranceIds;
        this.state.createAt = time;
        this.state.updateAt = time;
        break;
      case Event.Kind.AssetUpdated:
        applyUpdateToObject(this.state, event.asset);
        this.state.updateAt = time;
        break;
      case Event.Kind.AssetDeleted:
        if (this.state.groupIds && this.state.groupIds.length > 0)
          //#HACK: type of groupIds is mistakenly set to Encrypted<string[]> | undefined due to Dan's Encrypted type, we do a force type casting here
          this.relatedUpdates.removedGroupIds = this.state.groupIds as
            | string[]
            | undefined;
        if (
          this.state.ownershipType == OwnershipType.Own &&
          (<Optional<OwnerDetail>>this.state.detail)?.acquisition.insuranceIds
        )
          this.relatedUpdates.removedInsuranceIds = (<Optional<OwnerDetail>>(
            this.state.detail
          ))?.acquisition.insuranceIds;
        this.state = setObjectDeleted(this.state);
        break;
      case Event.Kind.AssetArchived:
        this.state.archived = true;
        this.state.updateAt = time;
        break;
      case Event.Kind.ArchivedAssetRestored:
        delete this.state.archived;
        this.state.updateAt = time;
        break;
      case Event.Kind.ValueUpdated:
        this.state.value = event.current;
        if (event.valuationId) this.state.valueSourceId = event.valuationId;
        else delete this.state.valueSourceId;
        this.state.updateAt = time;
        break;
      case Event.Kind.GroupsUpdated:
        if (event.addIds.length > 0)
          this.relatedUpdates.addedGroupIds = event.addIds;
        if (event.removedIds.length > 0)
          this.relatedUpdates.removedGroupIds = event.removedIds;
        break;
      case Event.Kind.InsuranceUpdated:
        if (event.previous && event.previous.length > 0) {
          if (event.current) {
            const removedInsuranceIds: string[] = [];
            event.previous.forEach((id) => {
              if (event.current!.includes(id)) return;
              removedInsuranceIds.push(id);
            });
            if (removedInsuranceIds.length > 0) {
              this.relatedUpdates.removedInsuranceIds = removedInsuranceIds;
            }
          } else {
            this.relatedUpdates.removedInsuranceIds = event.previous;
          }
        } else if (event.current) {
          this.relatedUpdates.addedInsuranceIds = event.current;
        }

        if (this.state.ownershipType === OwnershipType.Own) {
          if ((<OwnerDetail>this.state.detail).acquisition) {
            (<OwnerDetail>this.state.detail).acquisition.insuranceIds =
              event.current;
          } else {
            (<OwnerDetail>this.state.detail).acquisition = {
              ...Acquisition.defaultValue(),
              insuranceIds: event.current,
            };
          }
        }
        break;
      case Event.Kind.ValuationAdded:
        SupportValuationAggregate.applyAdded<any>(this, event);
        break;
      case Event.Kind.ValuationUpdated:
        SupportValuationAggregate.applyUpdated<any>(this, event);
        break;
      case Event.Kind.ValuationDeleted:
        SupportValuationAggregate.applyDeleted<any>(this, event);
        break;
      case Event.Kind.SoldInfoAdded:
        SupportSellAggregate.applyAdded<any>(this, event);
        break;
      case Event.Kind.SoldInfoUpdated:
        SupportSellAggregate.applyUpdated<any>(this, event);
        break;
      case Event.Kind.SoldInfoDeleted:
        SupportSellAggregate.applyDeleted<any>(this, event);
        break;

      case Event.Kind.ActionAdded:
        SupportActionAggregate.applyAdded<
          Encrypted<Property>,
          PropertyUtils.SupportActionsEncrypted
        >(this, event);
        break;
      case Event.Kind.ActionUpdated:
        SupportActionAggregate.applyUpdated<
          Encrypted<Property>,
          PropertyUtils.SupportActionsUpdate
        >(this, event);
        break;
      case Event.Kind.ActionDeleted:
        SupportActionAggregate.applyDeleted(this, event);
        break;
    }
    return this;
  }

  private handleCreateAsset({
    asset,
    executerId,
    valuation,
  }: Command.CreateAsset): Event[] {
    if (asset.ownershipType === OwnershipType.Own) {
      if (!valuation)
        throw new InvalidInput(
          "Valuation is required when creating owned property"
        );
      asset.valueSourceId = valuation.id;
    }

    const events: Event[] = [
      {
        executerId,
        kind: Event.Kind.AssetCreated,
        asset,
      },
    ];

    if (asset.ownershipType === OwnershipType.Own) {
      events.push(
        {
          executerId,
          kind: Event.Kind.ValuationAdded,
          data: valuation!,
        },
        {
          executerId,
          kind: Event.Kind.ValueUpdated,
          current: asset.value as Amount,
          valuationId: valuation!.id,
        }
      );
    }

    if (asset.groupIds && asset.groupIds.length > 0) {
      events.push({
        executerId,
        kind: Event.Kind.GroupsUpdated,
        addIds: asset.groupIds,
        removedIds: [],
      });
    }
    if (asset.ownershipType === OwnershipType.Own && asset.detail) {
      if ((<OwnerDetail>asset.detail).ownership) {
        events.push({
          executerId,
          kind: Event.Kind.ShareholderUpdated,
          current: (<OwnerDetail>asset.detail).ownership,
        });
      }
      if ((<OwnerDetail>asset.detail).beneficiary) {
        events.push({
          executerId,
          kind: Event.Kind.BeneficiaryUpdated,
          current: (<OwnerDetail>asset.detail).beneficiary,
        });
      }
    }
    if (asset.attachments) {
      events.push(
        ...SharedEvent.attachmentEventOnCreate(
          executerId,
          asset.attachments as Attachment.Encrypted[],
          asset.mainImage
        )
      );
    }
    return events;
  }
  private handleUpdateAsset({
    executerId,
    asset,
    addedToGroup,
    removedFromGroup,
    newImages,
    newMainImage,
  }: Command.UpdateAsset): Event[] {
    AssetV2.checkUpdate(this.state);
    const events: Event[] = [];
    if (asset.value) {
      events.push({
        executerId,
        kind: Event.Kind.ValueUpdated,
        previous: this.state.value as Amount,
        current: asset.value as Amount,
      });
      delete asset.value;
    }
    events.push({
      executerId,
      kind: Event.Kind.AssetUpdated,
      asset,
    });

    if (addedToGroup || removedFromGroup) {
      events.push({
        executerId,
        kind: Event.Kind.GroupsUpdated,
        addIds: addedToGroup ?? [],
        removedIds: removedFromGroup ?? [],
      });
    }
    if (newImages) {
      events.push({
        executerId,
        kind: Event.Kind.ImageAdded,
        images: newImages,
      });
    }
    if (newMainImage) {
      events.push({
        executerId,
        kind: Event.Kind.MainImageSet,
        previous: this.state.mainImage,
        current: newMainImage,
      });
    }
    if (
      this.state.ownershipType === OwnershipType.Own ||
      asset.ownershipType === OwnershipType.Own
    ) {
      const previousInsuranceIds = (<Optional<any>>this.state.detail)
        ?.acquisition?.insuranceIds;
      const currentInsuranceIds = (<Optional<any>>asset.detail)?.acquisition
        ?.insuranceIds;

      const maybeEvent = SharedEvent.insuranceEventOnUpdate(
        executerId,
        previousInsuranceIds,
        currentInsuranceIds
      );
      if (maybeEvent) events.push(maybeEvent);

      if (asset.detail) {
        const previousDetail = <OwnerDetail>this.state.detail;
        const currentDetail = <OwnerDetail>asset.detail;
        if (previousDetail.ownership || currentDetail.ownership) {
          events.push({
            executerId,
            kind: Event.Kind.ShareholderUpdated,
            previous: previousDetail.ownership,
            current: currentDetail.ownership,
          });
        }
        if (previousDetail.beneficiary || currentDetail.beneficiary) {
          events.push({
            executerId,
            kind: Event.Kind.BeneficiaryUpdated,
            previous: previousDetail.beneficiary,
            current: currentDetail.beneficiary,
          });
        }
      }
    }

    return events;
  }

  private handleDeleteAsset({ executerId }: Command.DeleteAsset): Event[] {
    AssetV2.checkDelete(this.state);
    if (this.state.items.length > 0)
      throw new InvalidInput("Can't delete property with items on it");
    return [
      {
        executerId,
        kind: Event.Kind.AssetDeleted,
      },
    ];
  }

  private handleArchiveAsset({ executerId }: Command.ArchiveAsset): Event[] {
    if (this.state.archived === true)
      throw new InvalidInput("Property is already archived");
    if (this.state.items.length > 0)
      throw new InvalidInput("Can't archive property with items on it");
    return [
      {
        executerId,
        kind: Event.Kind.AssetArchived,
      },
    ];
  }

  private handleRestoreArchivedAsset(executerId: string): Event[] {
    if (this.state.archived !== true)
      throw new InvalidInput("Property is not archived");

    return [{ executerId, kind: Event.Kind.ArchivedAssetRestored }];
  }
}
