import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useRef,
  type ComponentType,
  type MutableRefObject,
  type PropsWithChildren
} from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { GoogleMap, MarkerF } from '@react-google-maps/api'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { AssetType, Currency, LocationType } from 'core/remodel/types/common'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { commonQuery, fetchAttachmentIv, fetchExchangeRates, fetchThumbnailUrl } from '@/api/CommonService'
import { fetchPropertyAssetsBreakdown, fetchPropertyInfo, propertyQuery } from '@/api/PropertyService'
import { placeholderImageMap } from '@/constants/assets'
import type { LatLng, LocationSummary } from '@/types/common'
import { cn } from '@/utils/classnames'
import { formatNumber } from '@/utils/formatter'
import { ImageSizes } from '@/utils/imageTools'
import { useAuthStore } from '@/store/authStore'
import { useMap } from '@/store/map'
import TruncatedText from '@/components/base/TruncatedText'
import { Carousel, CarouselItem } from '@/components/Carousel'
import { EncryptedImage } from '@/components/EncryptedImage'
import { BelongingIcon, BottlesIcon, DoubleBedIcon, LocationIcon } from '@/components/icon'
import { PrivacyField } from '@/components/PrivacyField'

const MapInternalContext = createContext<MutableRefObject<google.maps.Map | null> | null>(null)

function useMapInternalContext() {
  const context = useContext(MapInternalContext)
  if (context === null) throw Error('`useMapInternalContext` must to be used within `NewMapWidget`')
  return context
}

export function MapWidget({ children }: PropsWithChildren) {
  const mapRef = useRef<google.maps.Map | null>(null)

  return (
    <MapInternalContext.Provider value={mapRef}>
      <div className={'relative h-96 w-full bg-gray-200'}>{children}</div>
    </MapInternalContext.Provider>
  )
}

const DEFAULT_LAT_LNG: LatLng = { lat: 0, lng: 0 }

interface MapWidgetViewProps {
  locations: LocationSummary[]
}

export const MapWidgetView = memo(({ locations }: MapWidgetViewProps) => {
  const { isLoaded } = useMap()
  const mapRef = useMapInternalContext()

  const mapOptions: google.maps.MapOptions = useMemo(() => {
    // there should be a default center, otherwise the map will not be displayed
    const center = locations[0]?.item.latLng ?? DEFAULT_LAT_LNG
    return {
      center,
      fullscreenControl: false,
      zoomControlOptions: { position: 3 },
      streetViewControl: false,
      scrollwheel: false,
      mapTypeControl: false,
      zoom: 4,
      minZoom: 3
    }
  }, [locations])

  const sorted = useMemo(() => {
    return locations.toSorted((a, b) => {
      const aValue = a.info?.value.value ?? 0
      const bValue = b.info?.value.value ?? 0
      return bValue - aValue
    })
  }, [locations])

  const handleLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUnmount = useCallback(() => {
    mapRef.current = null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFocus = useCallback((latLng: LatLng) => {
    if (!latLng || !mapRef.current) return
    mapRef.current.panTo(latLng)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    isLoaded && (
      <GoogleMap
        onLoad={handleLoad}
        onUnmount={handleUnmount}
        mapContainerClassName={'h-full w-full'}
        options={mapOptions}
      >
        {sorted.map((location, index) => {
          const latLng = location.item.latLng
          return latLng && <MarkerF key={index} position={latLng} onClick={() => handleFocus(latLng)} />
        })}
      </GoogleMap>
    )
  )
})
MapWidgetView.displayName = 'MapWidgetView'

const RESPONSIVE = [
  { breakpoint: 1280, count: 3 },
  { breakpoint: 768, count: 2 },
  { breakpoint: 576, count: 1 },
  { breakpoint: 0, count: 1 }
]

interface MapWidgetCarouselProps {
  locations: LocationSummary[]
  currency?: Currency
  renderItem: ComponentType<MapWidgetCardProps>
  onItemClick?: (id: string) => void
}

export function MapWidgetCarousel({
  locations,
  currency,
  renderItem: RenderItem,
  onItemClick
}: MapWidgetCarouselProps) {
  const mapRef = useMapInternalContext()

  const sorted = useMemo(() => {
    return locations.toSorted((a, b) => {
      const aValue = a.info?.value.value ?? 0
      const bValue = b.info?.value.value ?? 0
      return bValue - aValue
    })
  }, [locations])

  const handleFocus = useCallback((latLng: LatLng) => {
    if (!latLng || !mapRef.current) return
    mapRef.current.panTo(latLng)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={'absolute inset-x-4 bottom-4 mx-4'}>
      <Carousel className={cn({ 'justify-center': sorted.length === 1 })} responsive={RESPONSIVE}>
        {sorted.map((location) => {
          const { id, latLng } = location.item
          return (
            <CarouselItem key={id}>
              <RenderItem
                currency={currency}
                onClick={() => onItemClick?.(id)}
                onFocus={() => latLng && handleFocus(latLng)}
                {...location}
              />
            </CarouselItem>
          )
        })}
      </Carousel>
    </div>
  )
}

interface MapWidgetEmptyProps {
  open: boolean
  title: string
  subtitle: string
}

export function MapWidgetEmpty({ open, title, subtitle }: MapWidgetEmptyProps) {
  return (
    open && (
      <div className={'absolute inset-0'}>
        <Image className={'object-cover'} src={'/images/properties/mapPlaceholder.jpg'} alt={'Empty Map'} fill={true} />
        <div className={'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white '}>
          <div className={'text-center text-2xl font-bold'}>{title}</div>
          <div className={'text-center'}>{subtitle}</div>
        </div>
      </div>
    )
  )
}

export interface MapWidgetCardProps extends LocationSummary {
  onFocus?: () => void
  onClick?: () => void
  currency?: Currency
}

const PLACEHOLDER = placeholderImageMap['Property']

export function PropertyCard({ item, onFocus, currency }: MapWidgetCardProps) {
  const id = item.id
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const { data: property } = useSWR(id && [propertyQuery.info, id], fetchPropertyInfo(database!))
  const { data: assetsBreakdown } = useSWR(
    id && [propertyQuery.assetsBreakdown, id],
    fetchPropertyAssetsBreakdown(database!)
  )
  const { data: mainImageUrl } = useSWR(
    property?.mainImage && [commonQuery.thumbnailUrl, item.id, property.mainImage, ImageSizes.Large],
    fetchThumbnailUrl(database!)
  )
  const { data: mainImageIv } = useSWR(
    property?.mainImage && [commonQuery.attachmentIV, AssetType.Property, item.id, property.mainImage],
    fetchAttachmentIv(database!)
  )
  const targetCurrency = currency ?? preferences.baseCurrency!
  const { data: baseToTargetRate } = useSWR(
    [commonQuery.exchangeRates, preferences.baseCurrency!, targetCurrency],
    fetchExchangeRates(database!)
  )
  const propertyCurrency = property?.value?.currency ?? preferences.baseCurrency!
  const { data: propertyToTargetRate } = useSWR(
    [commonQuery.exchangeRates, propertyCurrency, targetCurrency],
    fetchExchangeRates(database!)
  )

  const formatCurrencyValue = useCallback(
    (value: number, rate: number = 1) =>
      `${targetCurrency} ${formatNumber(value * rate, preferences.numberFormat, { digits: 0 })}`,
    [preferences, targetCurrency]
  )

  const propertyValue = formatCurrencyValue(property?.value?.value ?? 0, propertyToTargetRate?.rate ?? 1)

  const assetsValue = useMemo(() => {
    if (!assetsBreakdown) return formatCurrencyValue(0)
    const netValue =
      assetsBreakdown.assets.reduce((acc, { value }) => acc + value.value, 0) -
      assetsBreakdown.liabilities.reduce((acc, { value }) => acc + value.value, 0)
    return formatCurrencyValue(netValue, baseToTargetRate?.rate ?? 1)
  }, [assetsBreakdown, baseToTargetRate, formatCurrencyValue])

  return (
    <Link
      className={'flex h-32 shrink-0 rounded bg-white text-text transition-colors hover:bg-gray-200'}
      href={{ pathname: '/properties/summary/info/', query: { id } }}
      onMouseEnter={onFocus}
    >
      <div className={'relative aspect-square h-full bg-grey/20'}>
        <EncryptedImage
          className={'object-cover'}
          src={mainImageUrl}
          base64IV={mainImageIv}
          alt={item.id}
          fill={true}
          placeholder={PLACEHOLDER}
        />
      </div>
      {property && (
        <div className={'flex w-full flex-col items-start justify-between border-l p-2'}>
          <div className={'flex w-full flex-col gap-1.5'}>
            <div className={'flex w-full flex-wrap items-center justify-between text-sm font-medium'}>
              <p>{property.name}</p>
              <p className={'text-primary'}>{t(`properties:PropertyTypeOptions.${property.subtype}`)}</p>
            </div>
            <div className={'flex items-center gap-1.5'}>
              <DoubleBedIcon />
              <TruncatedText as={'span'} className={'text-xs/[14px]'}>
                {`${property.configuration?.bedroom.length ?? 0} ${t('Field.Bedrooms')}`}
              </TruncatedText>
            </div>
          </div>
          <div className={'flex w-full flex-col gap-y-1 text-xs'}>
            <div className={'flex items-center justify-between gap-x-1'}>
              <span className={'shrink-0 text-[#6D83AC]'}>{t('properties:PropertyValue')}</span>
              <TruncatedText as={'span'} className={'font-medium'}>
                <PrivacyField>{propertyValue}</PrivacyField>
              </TruncatedText>
            </div>
            <div className={'flex items-center justify-between gap-x-1'}>
              <span className={'shrink-0 text-[#6D83AC]'}>{t('properties:AssetValue')}</span>
              <TruncatedText as={'span'} className={'font-medium'}>
                <PrivacyField>{assetsValue}</PrivacyField>
              </TruncatedText>
            </div>
          </div>
        </div>
      )}
    </Link>
  )
}

export function WineCard({ item, info, onFocus, onClick }: MapWidgetCardProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const { data: mainImage } = useSWR(
    info?.imageId && [commonQuery.thumbnailUrl, item.id, info.imageId, ImageSizes.Large],
    fetchThumbnailUrl(database!)
  )
  const { data: mainImageIv } = useSWR(
    info?.imageId && [commonQuery.attachmentIV, AssetType.Property, item.id, info.imageId],
    fetchAttachmentIv(database!)
  )
  const number = info?.number
  const currency = info?.value.currency ?? preferences.baseCurrency
  const value = formatNumber(info?.value.value ?? 0, preferences.numberFormat, { digits: 0 })
  const isProperty = info?.type === LocationType.MyProperty

  return (
    <div
      className={'flex h-32 cursor-pointer overflow-hidden rounded bg-white transition-colors hover:bg-gray-200'}
      onFocus={onFocus}
      onClick={onClick}
    >
      <div className={'relative aspect-square h-full bg-grey/20'}>
        {isProperty ? (
          <EncryptedImage
            className={'object-cover'}
            src={mainImage}
            base64IV={mainImageIv}
            alt={'Location'}
            fill={true}
            placeholder={PLACEHOLDER}
          />
        ) : (
          <div className={'absolute inset-0 flex items-center justify-center bg-primary'}>
            <LocationIcon className={'text-white'} size={48} />
          </div>
        )}
      </div>
      <div className={'flex flex-1 flex-col justify-between border-l p-2 text-text'} onMouseEnter={onFocus}>
        <p className={'text-sm'}>{info?.name}</p>
        {info?.address && (
          <div className={'flex items-center gap-x-2 text-[#6D83AC]'}>
            <LocationIcon size={16} />
            <TruncatedText className={'w-0 flex-1  text-xs'}>{info.address}</TruncatedText>
          </div>
        )}
        <div className={'flex items-center justify-between gap-x-1'}>
          <div className={'flex shrink-0 items-center gap-x-1 text-[#6D83AC]'}>
            <BottlesIcon size={16} />
            <span className={'text-xs'}>{t('collectables:BottlesWithNumber', { number })}</span>
          </div>
          <TruncatedText as={'span'} className={' text-sm'}>
            <PrivacyField>{`${currency} ${value}`}</PrivacyField>
          </TruncatedText>
        </div>
      </div>
    </div>
  )
}

export function BelongingCard({ item, info, onFocus, onClick }: MapWidgetCardProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const { data: mainImage } = useSWR(
    info?.imageId && [commonQuery.thumbnailUrl, item.id, info.imageId, ImageSizes.Large],
    fetchThumbnailUrl(database!)
  )
  const { data: mainImageIv } = useSWR(
    info?.imageId && [commonQuery.attachmentIV, AssetType.Property, item.id, info.imageId],
    fetchAttachmentIv(database!)
  )

  const number = info?.number
  const currency = info?.value.currency ?? preferences.baseCurrency
  const value = formatNumber(info?.value.value ?? 0, preferences.numberFormat, { digits: 0 })
  const isProperty = info?.type === LocationType.MyProperty

  return (
    <div className={'flex h-32 overflow-hidden rounded bg-white transition-colors hover:bg-gray-200'} onFocus={onFocus}>
      <div className={'relative aspect-square h-full bg-grey/20'}>
        {isProperty ? (
          <EncryptedImage
            className={'object-cover'}
            src={mainImage}
            base64IV={mainImageIv}
            alt={info.name}
            fill={true}
            placeholder={PLACEHOLDER}
          />
        ) : (
          <div className={'absolute inset-0 flex items-center justify-center bg-primary'}>
            <LocationIcon className={'text-white'} size={48} />
          </div>
        )}
      </div>
      <div className={'flex flex-1 flex-col justify-between border-l p-2 text-text'} onMouseEnter={onFocus}>
        <p className={'text-sm'}>{info?.name}</p>
        {info?.address && (
          <div className={'flex items-center gap-x-2 text-[#6D83AC]'}>
            <LocationIcon size={16} />
            <TruncatedText className={'w-0 flex-1 text-xs'}>
              <PrivacyField>{info.address}</PrivacyField>
            </TruncatedText>
          </div>
        )}
        <div className={'flex items-center justify-between gap-x-1'}>
          <div className={'flex shrink-0 items-center gap-x-1 text-[#6D83AC]'}>
            <BelongingIcon size={16} />
            <span className={'text-xs'}>{`${number} ` + t('Assets')}</span>
          </div>
          <TruncatedText as={'span'} className={'text-sm'}>
            <PrivacyField>{`${currency} ${value}`}</PrivacyField>
          </TruncatedText>
        </div>
      </div>
    </div>
  )
}

export function OtherCard({ item, info, onFocus, onClick }: MapWidgetCardProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const { data: mainImage } = useSWR(
    info?.imageId && [commonQuery.thumbnailUrl, item.id, info.imageId, ImageSizes.Large],
    fetchThumbnailUrl(database!)
  )
  const { data: mainImageIv } = useSWR(
    info?.imageId && [commonQuery.attachmentIV, AssetType.Property, item.id, info.imageId],
    fetchAttachmentIv(database!)
  )

  const number = info?.number
  const currency = info?.value.currency ?? preferences.baseCurrency
  const value = formatNumber(info?.value.value ?? 0, preferences.numberFormat, { digits: 0 })
  const isProperty = info?.type === LocationType.MyProperty

  return (
    <div
      className={'flex h-32 overflow-hidden rounded bg-white transition-colors hover:bg-gray-200'}
      onFocus={onFocus}
      onClick={onClick}
    >
      <div className={'relative aspect-square h-full bg-grey/20'}>
        {isProperty ? (
          <EncryptedImage
            className={'object-cover'}
            src={mainImage}
            base64IV={mainImageIv}
            alt={info.name}
            fill={true}
            placeholder={PLACEHOLDER}
          />
        ) : (
          <div className={'absolute inset-0 flex items-center justify-center bg-primary'}>
            <LocationIcon className={'text-white'} size={48} />
          </div>
        )}
      </div>
      <div className={'flex flex-1 flex-col justify-between border-l p-2 text-text'} onMouseEnter={onFocus}>
        <p className={'text-sm'}>{info?.name}</p>
        {info?.address && (
          <div className={'flex items-center gap-x-2 text-[#6D83AC]'}>
            <LocationIcon size={16} />
            <TruncatedText className={'w-0 flex-1 text-xs'}>
              <PrivacyField>{info.address}</PrivacyField>
            </TruncatedText>
          </div>
        )}
        <div className={'flex items-center justify-between gap-x-1'}>
          <div className={'flex shrink-0 items-center gap-x-1 text-[#6D83AC]'}>
            <BelongingIcon size={16} />
            <span className={'text-xs'}>{`${number} ` + t('Assets')}</span>
          </div>
          <TruncatedText as={'span'} className={'text-sm'}>
            <PrivacyField>{`${currency} ${value}`}</PrivacyField>
          </TruncatedText>
        </div>
      </div>
    </div>
  )
}
