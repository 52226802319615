import { useEffect, useMemo, useRef, useState } from 'react'
import { FileIcon, MoreVerticalIcon } from 'lucide-react'
import { useController, type Control, type FieldPath, type FieldValues } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import useSWR from 'swr'

import type { FullMetadata } from 'core/coreFirebase'
import { IVAttachmentFileSaltFieldKey } from 'core/remodel/types/common'
import { commonQuery, fetchStorageLimit, getMetadata, uploadFile } from '@/api/CommonService'
import { MAX_ACCOUNT_STORAGE_SPACE_MB, MAX_DOCUMENT_SIZE_MB } from '@/constants/preference'
import { isDocument } from '@/constants/upload'
import { isAllowedFileType } from '@/utils/checker'
import { formatBytes, round10 } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Modal,
  Progress,
  Separator
} from '@/components/base'
import TruncatedText from '@/components/base/TruncatedText'
import Confirm from '@/components/Confirm'
import DropArea from '@/components/DropArea'
import { FileCorrupted, PlusIcon, UploadCloudIcon } from '@/components/icon'
import RenameModal, { RenameFileValues } from '@/components/RenameModal'

type FileMetadata = {
  key: string
  name: string
}

interface UploadFilesProps<Values extends FieldValues, Path extends FieldPath<Values>> {
  assetId: string | null
  control: Control<Values>
  name: Path
}

export default function UploadFiles<Values extends FieldValues, Path extends FieldPath<Values>>({
  assetId,
  control,
  name
}: UploadFilesProps<Values, Path>) {
  const isSynced = useRef(false)
  const [isOpen, setIsOpen] = useState(false)
  const [progresses, setProgresses] = useState<Record<string, number>>({})
  const [instances, setInstances] = useState<Record<string, FullMetadata>>({})
  const { field } = useController({ control, name })
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const [uploadErrorMsg, setUploadErrorMsg] = useState<string | null>(null)
  const { data: accountStorage = { usage: 0, limit: MAX_ACCOUNT_STORAGE_SPACE_MB * Math.pow(1024, 2) } } = useSWR(
    [commonQuery.storageLimit],
    fetchStorageLimit(database!)
  )
  const storageSpacePercent = useMemo(
    () => round10((accountStorage.usage / accountStorage.limit) * 100),
    [accountStorage]
  )

  const onClose = () => setIsOpen(false)

  const uploadAttach = (file: File) => {
    if (assetId === null) return
    if (!isAllowedFileType(file.type)) {
      setUploadErrorMsg(t('validation:UnsupportedFileType'))
      return null
    }

    const fileId = database!.genAssetId()
    const encryption = database!.Encryption.current
    const iv = encryption.generateNewIVSalt()
    const encodedIV = encryption.convertIVSaltToBase64(iv)
    setProgresses((prev) => ({ ...prev, [fileId]: 0 }))

    uploadFile(database!, assetId, fileId, file, iv)
      .then((resp) => {
        setInstances((prev) => ({ ...prev, [fileId]: resp.metadata }))
        setProgresses((prev) => ({ ...prev, [fileId]: 100 }))
      })
      .catch((e) => {
        console.error(e)
        setTimeout(() => deleteAttach(fileId), 500)
      })

    return { key: fileId, encodedIV }
  }
  const sizeCheck = (newFiles: File[]) => {
    if (accountStorage.usage >= accountStorage.limit) {
      setUploadErrorMsg(t('validation:OverAccountMaxUploadSize', { value: MAX_ACCOUNT_STORAGE_SPACE_MB / 1024 }))
      return false
    }
    const isSizeValidForFile = newFiles.every((file) => {
      const isDoc = isDocument(file)
      if (isDoc) {
        return file.size <= MAX_DOCUMENT_SIZE_MB * Math.pow(1024, 2)
      }
      return true
    })
    const addedSize = newFiles.reduce((acc, cur) => acc + cur.size, 0)
    const isSizeValidForAccount =
      ((accountStorage?.usage ?? 0) + addedSize) / Math.pow(1024, 2) <= MAX_ACCOUNT_STORAGE_SPACE_MB
    if (!isSizeValidForAccount) {
      setUploadErrorMsg(t('validation:OverAccountMaxUploadSize', { value: MAX_ACCOUNT_STORAGE_SPACE_MB / 1024 }))
    } else if (!isSizeValidForFile) {
      setUploadErrorMsg(t('validation:OverDocumentMaxUploadSize', { value: MAX_DOCUMENT_SIZE_MB }))
    }
    return isSizeValidForAccount && isSizeValidForFile
  }

  const appendAttachs = (files: File[]) => {
    if (!sizeCheck(files)) return
    const { value, onChange } = field
    const validFiles = files.filter((file) => isAllowedFileType(file.type))

    if (validFiles.length !== files.length) {
      setUploadErrorMsg(t('validation:UnsupportedFileType'))
      return
    }

    const attachs = validFiles.map((file) => {
      const result = uploadAttach(file)
      if (result === null) return null

      return {
        key: result?.key,
        name: file.name,
        [IVAttachmentFileSaltFieldKey]: result?.encodedIV
      }
    })
    onChange([...attachs, ...(value ?? [])])
  }

  const renameAttach = (data: RenameFileValues) => {
    const { value, onChange } = field

    onChange(
      value.map((file: FileMetadata) => {
        if (file.key === data.fileKey) {
          return {
            ...file,
            name: data.newFileName
          }
        }
        return file
      })
    )
  }

  const deleteAttach = (fileId: string) => {
    if (assetId === null) return

    const { value, onChange } = field
    delete instances[fileId]
    onChange(value.filter((file: FileMetadata) => file.key !== fileId))
  }

  // only remove data from attachments array, no need to delete the ones in database
  const removeData = (fileId: string) => {
    const { value, onChange } = field
    delete instances[fileId]
    onChange(value.filter((file: FileMetadata) => file.key !== fileId))
  }

  useEffect(() => {
    const syncQueue = (field.value ?? []).filter(
      (file: FileMetadata) => !(file.key in instances) && !(file.key in progresses)
    )
    const shouldSync = database && isSynced.current === false && syncQueue.length > 0
    if (!shouldSync) return

    const getInstance = async (key: string) => {
      try {
        if (assetId === null) return
        const attachMeta = await getMetadata(database!)([commonQuery.metadata, assetId, key])
        setInstances((prev) => ({ ...prev, [key]: attachMeta }))
      } catch (e) {
        setInstances((prev) => ({ ...prev, [key]: { contentType: 'corrupted file', size: 0 } as FullMetadata }))
        console.log(e)
      }
    }

    const getInstances = async () => {
      setTimeout(() => {
        Promise.all(
          syncQueue
            .filter((attach: FileMetadata) => !(attach.key in instances) && !(attach.key in progresses))
            .map((attach: FileMetadata) => getInstance(attach.key))
        )
      }, 2000)
    }

    getInstances()
    isSynced.current = true
  }, [assetId, database, field.value, instances, progresses, t])

  return (
    <>
      <div className={'flex flex-col items-start gap-y-1'}>
        <label className={'text-xs text-[#414554]'}>{t('Field.Files')}</label>
        <div className={'flex items-center gap-x-2'}>
          <Button className={'p-1 text-xs'} variant={'outline'} onClick={() => setIsOpen(true)}>
            <PlusIcon className={'mr-1'} size={16} />
            <span>{t('Upload')}</span>
          </Button>
          {field.value.length > 0 && (
            <span className={'text-xs text-text'}>{`${field.value.length} ${t('FilesUploaded')}`}</span>
          )}
        </div>
      </div>

      {isOpen && (
        <Modal onBackdropClick={onClose}>
          <Modal.Header className={'bg-primary'}>
            <label className={'text-sm font-medium uppercase text-white'}>{t('Field.Files')}</label>
            <Modal.CloseButton className={'text-white'} onClose={onClose} />
          </Modal.Header>
          <Modal.Content className={'grid-cols-1'}>
            <DropArea className={'flex flex-col items-center gap-4'} onDrop={appendAttachs}>
              <label className={'group flex w-full cursor-pointer flex-col items-center'} htmlFor={'attachments'}>
                <UploadCloudIcon
                  className={'text-primary transition-colors ease-out group-hover:text-primary-hover'}
                  size={64}
                />
                <Trans
                  t={t}
                  i18nKey={'DropFiles'}
                  parent={(props: any) => <p className={'text-sm text-primary'} {...props} />}
                  components={[<span key={0} className={'underline'} />]}
                />
                <input
                  id={'attachments'}
                  className={'sr-only'}
                  name={'attachments'}
                  type={'file'}
                  multiple={true}
                  onChange={(e) => {
                    if (e.target.files) {
                      const files = Array.from(e.target.files)
                      appendAttachs(files)
                    }
                  }}
                />
              </label>
              <Progress value={storageSpacePercent} />
              <span className={'text-xs text-gray-800'}>{t('StorageSpace', { percent: storageSpacePercent })}</span>
              <Separator />
              <ul className={'h-80 w-full space-y-1 overflow-auto'}>
                {field.value.map((file: FileMetadata, index: number) => {
                  const instance = instances[file.key]
                  const progress = progresses[file.key]
                  const isProgressing = progress !== undefined && progress !== 100

                  return instance ? (
                    instance?.contentType === 'corrupted file' ? (
                      <li key={file.key} className={'flex items-center gap-2 border-b border-gray-200 pb-2 pt-4'}>
                        <FileCorrupted className={'h-8 w-8 text-primary'} />
                        <div className={'flex-1 text-sm'}>
                          <TruncatedText className={'w-60 whitespace-nowrap text-gray-600'}>{file.name}</TruncatedText>
                          <span className={'text-grey'}>{t('Corrupted')}</span>
                        </div>
                        <DropMenu
                          isCorrupted
                          file={file}
                          onRemoveDoc={() => removeData(file.key)}
                          onRename={renameAttach}
                          onRemove={() => deleteAttach(file.key)}
                        />
                      </li>
                    ) : (
                      <li key={file.key} className={'flex items-center gap-2 border-b border-gray-200 pb-2 pt-4'}>
                        <FileIcon className={'text-primary'} size={32} />
                        <div className={'flex-1 text-sm'}>
                          <TruncatedText className={'w-60 whitespace-nowrap text-gray-600'}>{file.name}</TruncatedText>
                          <span className={'text-grey'}>{formatBytes(instance.size)}</span>
                        </div>
                        <DropMenu file={file} onRename={renameAttach} onRemove={() => deleteAttach(file.key)} />
                      </li>
                    )
                  ) : isProgressing ? (
                    <li key={file.key} className={'flex items-center gap-2 border-b border-gray-200 pb-2 pt-4'}>
                      <FileIcon className={'text-primary'} size={32} />
                      <div className={'flex-1 text-sm'}>
                        <TruncatedText className={'w-60 whitespace-nowrap text-gray-600'}>{file.name}</TruncatedText>
                        <span className={'text-grey'}>{t('Encrypting')}</span>
                        <Progress value={progress} />
                      </div>
                    </li>
                  ) : (
                    <li key={file.key} className={'flex items-center gap-2 border-b border-gray-200 pb-2 pt-4'}>
                      <FileIcon className={'text-primary'} size={32} />
                      <div className={'flex-1 text-sm'}>
                        <TruncatedText className={'w-60 whitespace-nowrap text-gray-600'}>{file.name}</TruncatedText>
                        <span className={'text-grey'}>{t('Uploading')}</span>
                      </div>
                    </li>
                  )
                })}
              </ul>
              {uploadErrorMsg && (
                <Confirm
                  title={t('UploadError')}
                  content={uploadErrorMsg}
                  onCancel={() => setUploadErrorMsg(null)}
                  cancelLabel={t('Cancel')}
                  onConfirm={() => setUploadErrorMsg(null)}
                  confirmLabel={t('Confirm')}
                />
              )}
            </DropArea>
          </Modal.Content>
        </Modal>
      )}
    </>
  )
}

type FileAction = 'Rename' | 'Delete' | 'RemoveDoc' | null

interface DropMenuProps {
  file: FileMetadata
  onRename: (data: RenameFileValues) => void
  onRemove: () => void
  isCorrupted?: boolean
  onRemoveDoc?: () => void
}

function DropMenu({ file, isCorrupted, onRename, onRemove, onRemoveDoc }: DropMenuProps) {
  const { t } = useTranslation()
  const [action, setAction] = useState<FileAction>(null)

  const handleClose = () => setAction(null)
  const handleDelete = () => {
    onRemove()
    handleClose()
  }
  const handleRemoveDoc = () => {
    onRemoveDoc?.()
    handleClose()
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild={true}>
          <Button className={'border-gray-200 p-1 text-primary hover:bg-primary hover:text-white'}>
            <MoreVerticalIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align={'end'}>
          {isCorrupted ? (
            <DropdownMenuItem onSelect={() => setAction('RemoveDoc')}>{t('Remove')}</DropdownMenuItem>
          ) : (
            <>
              <DropdownMenuItem onSelect={() => setAction('Rename')}>{t('Rename')}</DropdownMenuItem>
              <DropdownMenuItem onSelect={() => setAction('Delete')}>{t('Delete')}</DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      <RenameModal isOpen={action === 'Rename'} file={file} onRename={onRename} onCancel={handleClose} />
      {action === 'Delete' && (
        <Confirm
          title={t('Delete')}
          content={t('documents:AskDeleteDocuments', { name: file.name })}
          onCancel={handleClose}
          cancelLabel={t('Cancel')}
          onConfirm={handleDelete}
          confirmLabel={t('Delete')}
        />
      )}
      {action === 'RemoveDoc' && (
        <Confirm
          title={t('Remove')}
          content={t('documents:AskRemoveMetadata', { name: file.name })}
          onCancel={handleClose}
          cancelLabel={t('Cancel')}
          onConfirm={handleRemoveDoc}
          confirmLabel={t('Remove')}
        />
      )}
    </>
  )
}
