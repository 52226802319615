import { useCallback, useEffect, useMemo, useState } from 'react'
import { Loader2Icon } from 'lucide-react'
import { Path, useFieldArray, useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { artMediumValues, artStyleValues, ArtType, artTypeValues, type Art } from 'core/remodel/types/arts'
import {
  AttachmentKind,
  Currency,
  LocationType,
  MeasurementUnits,
  measurementUnitValues,
  TimeZone
} from 'core/remodel/types/common'
import { AcquisitionType, acquisitionTypeValues } from 'core/remodel/types/common/acquisition'
import { AssetType, CustomizedType } from 'core/remodel/types/enums'
import { CountryOptions, locationTypeValues } from 'core/remodel/types/options'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { addCustomType, fetchCustomTypes, typeQuery } from '@/api/CommonService'
import {
  addContact,
  addContactPosition,
  addContactRoom,
  contactQuery,
  fetchContactOptions,
  fetchContactRoomOptions,
  fetchContactRoomPositionOptions
} from '@/api/ContactService'
import { fetchGroupOptions, groupQuery } from '@/api/GroupService'
import {
  addPropertyPosition,
  addPropertyRoom,
  fetchPropertyOptions,
  fetchPropertyPositionOptions,
  fetchPropertyRoomOptions,
  propertyQuery
} from '@/api/PropertyService'
import { fallbackCurrency } from '@/constants/preference'
import { cmToIn, inToCm, kgToLb, lbToKg } from '@/constants/unit'
import { cn } from '@/utils/classnames'
import { makeOptions, sortAlphabetically, startOfDayInTz } from '@/utils/formatter'
import { useManualEditTracker } from '@/hooks/useManualEditTracker'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  FormAutocomplete,
  FormCheckbox,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormInput,
  FormInsurancePicker,
  FormNumberInput,
  FormPercentInput,
  FormPriceInput,
  FormQuantityInput,
  FormSelect,
  FormTextarea,
  Input,
  SelectItem,
  TypePicker
} from '@/components/base'
import { ArtistAutocomplete } from '@/components/ArtistAutocomplete'
import AttachmentPanel from '@/components/AttachmentPanel'
import { AttachmentIcon, PlusIcon, StarIcon, XIcon } from '@/components/icon'
import NewAddress from '@/components/NewAddress'
import { TabPanel, type Tab } from '@/components/TabPanel'

export type ArtValues = Art

const getDefaultValues = (currency: Currency = fallbackCurrency, timeZone: TimeZone): Partial<ArtValues> => ({
  assetType: AssetType.Art,
  // primary details
  artist: '',
  name: '',
  subtype: ArtType.Architecture,
  artStyle: undefined,
  purchaseDate: startOfDayInTz(new Date(), timeZone),
  price: { currency, value: 0 }, // item price
  number: 1,
  purchasePrice: { currency, value: 0 }, // purchase price: default to number * item price
  totalCost: { currency, value: 0 }, // total cost = (purchase price/ hammer price) + (other costs...etc)
  value: { currency, value: 0 },
  groupIds: [],
  medium: undefined,
  personalRefNo: '',
  notes: '',
  // acquisition
  acquisition: {
    acquisitionType: AcquisitionType.Direct,
    sellerId: '',
    invoiceNumber: '',
    insuranceIds: [],
    priceAsValue: false,
    otherCost: { currency, value: 0 },
    discountAdjustment: { currency, value: 0 },
    auctionDetail: {
      auctionNumber: '',
      hammerPrice: { currency, value: 0 },
      taxPayable: { currency, value: 0 },
      buyerPremium: { currency, value: 0 },
      lotNumber: '',
      paddleNumber: ''
    },
    initialValuationAtPurchase: { currency, value: 0 },
    notes: ''
  },
  // location
  location: {
    locationType: LocationType.MyProperty,
    locationId: '',
    roomId: '',
    position: '',
    notes: ''
  },
  // attributes
  measurementUnits: MeasurementUnits.Metric,
  height: 0,
  width: 0,
  depth: 0,
  weight: 0,
  dimensionsSummary: '',
  edition: '',
  attributeNotes: '',
  creationCompletionYear: startOfDayInTz(new Date(), timeZone),
  provenance: '',
  geography: '',
  inscription: '',
  description: '',
  catalogueText: '',
  // ownership
  ownership: { myOwnership: 100, shareholder: [] },
  beneficiary: [],
  // attachments
  attachments: [],
  mainImage: undefined
})

const preprocessValues = (values: ArtValues): ArtValues => {
  const { acquisition, value, totalCost } = values
  const newPriceAsValue =
    acquisition?.priceAsValue && value.currency === totalCost.currency && value.value === totalCost.value
  return {
    ...values,
    acquisition: {
      ...acquisition,
      acquisitionType: acquisition?.acquisitionType ?? AcquisitionType.Direct,
      priceAsValue: newPriceAsValue ?? false
    }
  }
}

interface ArtFormProps {
  mode?: 'Create' | 'Edit'
  assetId: string | null
  values?: ArtValues
  tabsAttachment?: boolean
  onSubmit: SubmitHandler<ArtValues>
  onCancel: () => void
}

export function ArtForm({
  mode = 'Create',
  assetId,
  values,
  tabsAttachment = false,
  onCancel,
  onSubmit
}: ArtFormProps) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const defaultValues = useMemo(
    () => getDefaultValues(preferences.baseCurrency, preferences.timeZone),
    [preferences.baseCurrency, preferences.timeZone]
  )
  const preprocessedValues = useMemo(() => values && preprocessValues(values), [values])
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    watch,
    reset,
    setValue
  } = useForm<ArtValues>({ defaultValues, values: preprocessedValues })
  const { trackManualEdit, isManuallyEdited } = useManualEditTracker<ArtValues>()
  const shouldUpdateCurrentValue = !isManuallyEdited('value') && mode === 'Create'
  const shouldUpdateInitialValue = !isManuallyEdited('acquisition.initialValuationAtPurchase') && mode === 'Create'

  // price fields
  const numberOfItems = watch('number')
  const itemPrice = watch('price.value')
  const purchasePrice = watch('purchasePrice.value')
  const discountAdjustment = watch('acquisition.discountAdjustment.value')
  const hammerPrice = watch('acquisition.auctionDetail.hammerPrice.value')
  const taxPayable = watch('acquisition.auctionDetail.taxPayable.value')
  const buyerPremium = watch('acquisition.auctionDetail.buyerPremium.value')
  const otherCost = watch('acquisition.otherCost.value')
  const priceAsValue = watch('acquisition.priceAsValue')
  const totalCost = watch('totalCost.value')
  const totalCostCurrency = watch('totalCost.currency')
  // acquisition fields
  const acquisitionType = watch('acquisition.acquisitionType')
  const isAuction = acquisitionType === AcquisitionType.Auction
  const isDirect = acquisitionType === AcquisitionType.Direct
  // location fields
  const locationType = watch('location.locationType')
  const isProperty = locationType === LocationType.MyProperty
  const isAddress = locationType === LocationType.Address
  const isNewAddress = locationType === LocationType.NewAddress
  const locationId = watch('location.locationId')
  const roomId = watch('location.roomId')
  // attributes fields
  const measurementUnits = watch('measurementUnits')
  const myOwnership = watch('ownership.myOwnership')
  const shareholder = watch('ownership.shareholder')
  const beneficiary = watch('beneficiary')
  const weight = watch('weight')
  const height = watch('height')
  const width = watch('width')
  const depth = watch('depth')
  const artistId = watch('artistId')

  const shareholderArray = useFieldArray({
    control,
    name: 'ownership.shareholder',
    rules: {
      validate: {
        percent: (owners) => owners.reduce((prev, { percent }) => prev + percent, myOwnership) <= 100,
        notEmpty: (owners) => owners.every((owner) => owner.contactId !== '')
      }
    }
  })
  const beneficiaryArray = useFieldArray({
    control,
    name: 'beneficiary',
    rules: {
      validate: {
        percent: (owners) => owners.reduce((prev, { percent }) => prev + percent, 0) <= 100,
        notEmpty: (owners) => owners.every((owner) => owner.contactId !== '')
      }
    }
  })
  const controlledShareholder = shareholderArray.fields.map((f, i) => ({ ...f, ...shareholder[i] }))
  const controlledBeneficiary = beneficiaryArray.fields.map((f, i) => ({ ...f, ...beneficiary?.[i] }))
  const remainingOwnership = useMemo(() => {
    const shared = controlledShareholder.reduce((acc, curr) => acc + curr.percent, 0)
    const total = shared + myOwnership
    return 100 - total
  }, [myOwnership, controlledShareholder])
  const remainingBeneficiary = useMemo(() => {
    const total = controlledBeneficiary.reduce((acc, curr) => acc + curr.percent, 0)
    return 100 - total
  }, [controlledBeneficiary])

  // Fetch options using SWR
  const { data: propertyOptions, isLoading: loadingProperties } = useSWR(
    [propertyQuery.options],
    fetchPropertyOptions(database!)
  )
  const { data: contactOptions, isValidating: loadingContacts } = useSWR(
    [contactQuery.options],
    fetchContactOptions(database!)
  )
  const { data: groupOptions, isValidating: loadingGroups } = useSWR([groupQuery.options], fetchGroupOptions(database!))
  const { data: propertyRoomOptions, isValidating: loadingPropertyRooms } = useSWR(
    [propertyQuery.rooms, locationId],
    fetchPropertyRoomOptions(database!)
  )
  const { data: propertyPositionOptions, isValidating: loadingPropertyPositions } = useSWR(
    [propertyQuery.positions, locationId, roomId],
    fetchPropertyPositionOptions(database!)
  )
  const { data: contactRoomOptions, isValidating: loadingContactRooms } = useSWR(
    [contactQuery.rooms, locationId],
    fetchContactRoomOptions(database!)
  )
  const { data: contactPositionOptions, isValidating: loadingContactPositions } = useSWR(
    [contactQuery.positions, locationId, roomId],
    fetchContactRoomPositionOptions(database!)
  )
  const { data: mediums, isValidating: loadingMediums } = useSWR(
    [typeQuery.artMedium, CustomizedType.ArtMedium],
    fetchCustomTypes(database!)
  )

  // Create options
  const measurementOptions = makeOptions(measurementUnitValues, (key) => `MeasurementUnits.${key}`)
  const acquisitionTypeOptions = makeOptions(acquisitionTypeValues, (key) => `AcquisitionTypeOptions.${key}`)
  const locationTypeOptions = makeOptions(locationTypeValues, (key) => `LocationTypeOptions.${key}`)
  const artTypeOptions = makeOptions(artTypeValues, (key) => `collectables:ArtTypeOptions.${key}`)
  const artStyleOptions = makeOptions(artStyleValues, (key) => `collectables:ArtStyleOptions.${key}`)
  const roomOptions = locationType === LocationType.MyProperty ? propertyRoomOptions : contactRoomOptions
  const loadingRooms = locationType === LocationType.MyProperty ? loadingPropertyRooms : loadingContactRooms
  const positionOptions = locationType === LocationType.MyProperty ? propertyPositionOptions : contactPositionOptions
  const loadingPositions = locationType === LocationType.MyProperty ? loadingPropertyPositions : loadingContactPositions

  const mediumOptions: { label: string; value: string }[] = useMemo(() => {
    const base = makeOptions(artMediumValues, (key) => `collectables:ArtMediumOptions.${key}`)
    const added = mediums?.map((medium) => ({ label: medium, value: medium })) ?? []
    return sortAlphabetically({ data: [...base, ...added], selector: (d) => d.label, locale: 'en' })
  }, [mediums])

  // Define tabs
  const tabs: Tab[] = [
    { key: 'primary', label: t('PrimaryDetails'), desc: t('Required'), icon: <StarIcon /> },
    {
      key: 'additional',
      label: t('AdditionalDetails'),
      desc: t('Optional'),
      icon: <PlusIcon />,
      subTabs: [
        { key: 'acquisition', label: t('Acquisition') },
        { key: 'location', label: t('Location') },
        { key: 'attributes', label: t('Attributes') },
        { key: 'ownership', label: t('Ownership') }
      ]
    },
    { key: 'attachments', label: t('Attachments'), icon: <AttachmentIcon /> }
  ]
  const defaultTab = tabsAttachment ? 'attachments' : tabs[0].key

  const addRoom = async (roomName: string) => {
    switch (locationType) {
      case LocationType.MyProperty:
        const PropertyRoomId = await addPropertyRoom(database!, locationId, roomName)
        return PropertyRoomId
      case LocationType.Address:
      case LocationType.NewAddress:
        const ContactRoomId = await addContactRoom(database!, locationId, roomName)
        return ContactRoomId
    }
  }

  const addPosition = async (positionName: string) => {
    switch (locationType) {
      case LocationType.MyProperty:
        await addPropertyPosition(database!, locationId, roomId!, positionName)
        return positionName
      case LocationType.Address:
      case LocationType.NewAddress:
        await addContactPosition(database!, locationId, roomId!, positionName)
        return positionName
    }
  }

  const addNewContact = async (firstName: string) => await addContact(database!, { firstName })

  const handleLocationCancel = () => {
    setValue('location.locationId', '')
    setValue('location.roomId', '')
    setValue('location.position', '')
  }

  const onUnitChanged = (prev: string, next: string) => {
    if (prev === MeasurementUnits.Metric && next === MeasurementUnits.Imperial) {
      setValue('weight', (weight ?? 0) * kgToLb)
      setValue('height', (height ?? 0) * cmToIn)
      setValue('width', (width ?? 0) * cmToIn)
      setValue('depth', (depth ?? 0) * cmToIn)
    }
    if (prev === MeasurementUnits.Imperial && next === MeasurementUnits.Metric) {
      setValue('weight', (weight ?? 0) * lbToKg)
      setValue('height', (height ?? 0) * inToCm)
      setValue('width', (width ?? 0) * inToCm)
      setValue('depth', (depth ?? 0) * inToCm)
    }
  }

  const ShareholderUnselectContact = useCallback(
    (index: number) => {
      const otherShareHolders = [...controlledShareholder.slice(0, index), ...controlledShareholder.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherShareHolders.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledShareholder]
  )

  const beneficiaryUnselectContact = useCallback(
    (index: number) => {
      const otherBeneficiaries = [...controlledBeneficiary.slice(0, index), ...controlledBeneficiary.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherBeneficiaries?.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledBeneficiary]
  )

  const handleFieldValueChange = useCallback(
    (fieldName: Path<ArtValues>, value: number) => {
      switch (fieldName) {
        case 'number':
          setValue('purchasePrice.value', itemPrice * value)
          setValue('acquisition.auctionDetail.hammerPrice.value', itemPrice * value)
          shouldUpdateCurrentValue && setValue('value.value', itemPrice * value)
          shouldUpdateInitialValue && setValue('acquisition.initialValuationAtPurchase.value', itemPrice * value)
          break
        case 'price.value':
          setValue('purchasePrice.value', value * numberOfItems)
          setValue('acquisition.auctionDetail.hammerPrice.value', value * numberOfItems)
          shouldUpdateCurrentValue && setValue('value.value', value * numberOfItems)
          shouldUpdateInitialValue && setValue('acquisition.initialValuationAtPurchase.value', value * numberOfItems)
          break
        case 'purchasePrice.value':
          setValue('acquisition.auctionDetail.hammerPrice.value', value)
          shouldUpdateInitialValue && setValue('acquisition.initialValuationAtPurchase.value', value)
          break
        case 'acquisition.auctionDetail.hammerPrice.value':
          shouldUpdateInitialValue && setValue('acquisition.initialValuationAtPurchase.value', value)
          break
      }
    },
    [setValue, itemPrice, shouldUpdateCurrentValue, shouldUpdateInitialValue, numberOfItems]
  )

  const handleFieldCurrencyChange = useCallback(
    (_fieldName: Path<ArtValues>, currency: Currency) => {
      const currencyFields: Path<ArtValues>[] = [
        'price.currency',
        'purchasePrice.currency',
        'acquisition.discountAdjustment.currency',
        'acquisition.otherCost.currency',
        'totalCost.currency',
        'acquisition.auctionDetail.hammerPrice.currency',
        'acquisition.auctionDetail.taxPayable.currency',
        'acquisition.auctionDetail.buyerPremium.currency'
      ]
      shouldUpdateCurrentValue && currencyFields.push('value.currency')
      shouldUpdateInitialValue && currencyFields.push('acquisition.initialValuationAtPurchase.currency')

      currencyFields.forEach((field) => setValue(field, currency))
    },
    [setValue, shouldUpdateCurrentValue, shouldUpdateInitialValue]
  )

  useEffect(() => {
    const basePrice = isDirect ? purchasePrice : hammerPrice
    const additionalCosts = isDirect ? otherCost - discountAdjustment : taxPayable + buyerPremium + otherCost
    setValue('totalCost.value', Math.round((basePrice + additionalCosts) * 100) / 100)
  }, [isDirect, discountAdjustment, taxPayable, buyerPremium, purchasePrice, hammerPrice, setValue, otherCost])

  useEffect(() => {
    if (priceAsValue) {
      setValue('value.value', totalCost)
      setValue('value.currency', totalCostCurrency)
    }
  }, [priceAsValue, totalCost, totalCostCurrency, setValue])

  useEffect(() => {
    if (mode === 'Edit') {
      reset(preprocessedValues, { keepDirtyValues: true })
    }
  }, [mode, reset, preprocessedValues])
  return (
    <>
      <TabPanel defaultValue={defaultTab}>
        {/* left */}
        <TabPanel.SideNav tabs={tabs} />
        {/* right */}
        <form
          className={'flex h-[95vh] grow flex-col justify-between gap-4 md:h-[700px]'}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={'grow overflow-auto px-4 pt-4'}>
            <TabPanel.Section className={'gap-x-0'} value={'primary'}>
              <div className={'grid w-[322px] grid-cols-1 gap-4 md:w-[528px] md:grid-cols-2'}>
                <div className={'flex flex-col gap-4'}>
                  <ArtistAutocomplete
                    control={control}
                    name={'artist'}
                    label={t('collectables:Field.ArtistName')}
                    artistId={artistId}
                    onSelectId={(id) => setValue('artistId', id)}
                    rules={{
                      required: t('validation:SpecifyField', { field: '$t(collectables:Field.ArtistName)' })
                    }}
                  />
                  <FormInput
                    control={control}
                    name={'name'}
                    label={t('collectables:Field.ArtTitle')}
                    type={'text'}
                    rules={{
                      required: t('validation:SpecifyField', { field: '$t(collectables:Field.ArtTitle)' }),
                      maxLength: {
                        value: 100,
                        message: t('validation:SymbolsLimit', {
                          field: '$t(collectables:Field.ArtTitle)',
                          number: 100
                        })
                      }
                    }}
                  />
                  <FormAutocomplete
                    control={control}
                    name={'subtype'}
                    label={t('collectables:Field.ArtType')}
                    options={artTypeOptions}
                    placeholder={t('TypeToSearch')}
                    rules={{
                      required: t('validation:SpecifyField', { field: '$t(collectables:Field.ArtType)' })
                    }}
                  />
                  <FormAutocomplete
                    control={control}
                    name={'artStyle'}
                    label={t('collectables:Field.ArtStyle')}
                    options={artStyleOptions}
                    placeholder={t('TypeToSearch')}
                    rules={{
                      required: t('validation:SpecifyField', { field: '$t(collectables:Field.ArtStyle)' })
                    }}
                  />
                  <FormSelect
                    control={control}
                    name={'location.locationType'}
                    label={t('Field.LocationType')}
                    onSelected={() => {
                      setValue('location.locationId', '')
                      setValue('location.roomId', '')
                      setValue('location.position', '')
                    }}
                    rules={{
                      required: t('validation:SpecifyField', { field: '$t(Field.LocationType)' })
                    }}
                  >
                    {locationTypeOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </FormSelect>
                  {isProperty && (
                    <FormAutocomplete
                      control={control}
                      name={'location.locationId'}
                      label={t('Field.LocationOfAsset')}
                      options={propertyOptions}
                      placeholder={t('TypeToSearch')}
                      isLoading={loadingProperties}
                      onChanged={() => {
                        setValue('location.roomId', '')
                        setValue('location.position', '')
                      }}
                      rules={{ required: t('validation:SpecifyField', { field: '$t(Field.LocationOfAsset)' }) }}
                      onCancel={handleLocationCancel}
                    />
                  )}
                  {isAddress && (
                    <FormAutocomplete
                      control={control}
                      name={'location.locationId'}
                      label={t('Field.LocationOfAsset')}
                      options={contactOptions}
                      placeholder={t('TypeToSearch')}
                      isLoading={loadingContacts}
                      rules={{ required: t('validation:SpecifyField', { field: '$t(Field.LocationOfAsset)' }) }}
                      onCancel={handleLocationCancel}
                    />
                  )}
                  {isNewAddress && (
                    <>
                      <Button
                        className={'group justify-start gap-x-3 justify-self-start'}
                        onClick={() => setIsOpen(true)}
                        disabled={locationId.length > 0}
                      >
                        <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                          <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                        </div>
                        <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                          {t('CreateNewAddress')}
                        </span>
                      </Button>
                      {locationId.length > 0 && (
                        <div className={'flex items-center justify-between'}>
                          <p className={'text-sm'}>
                            {contactOptions?.find(({ value }) => value === locationId)?.label ?? '-'}
                          </p>
                          <Button
                            className={'rounded-full transition-colors hover:bg-grey/20'}
                            onClick={handleLocationCancel}
                          >
                            <XIcon className={'text-primary'} />
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                  <FormInput
                    control={control}
                    name={'personalRefNo'}
                    label={t('Field.PersonalRefNo')}
                    type={'text'}
                    placeholder={t('Field.AddRefNumber')}
                  />
                </div>
                <div className={'flex flex-col gap-4'}>
                  <FormDatePicker
                    control={control}
                    name={'purchaseDate'}
                    label={t('Field.PurchaseDate')}
                    rules={{ required: t('validation:SpecifyField', { field: '$t(Field.PurchaseDate)' }) }}
                    format={preferences.dateFormat}
                    timeZone={preferences.timeZone}
                  />
                  <FormQuantityInput
                    control={control}
                    name={'number'}
                    label={t('Field.NumberOfItems')}
                    rules={{ required: t('validation:SpecifyField', { field: '$t(Field.NumberOfItems)' }) }}
                    min={1}
                    max={9999}
                    onChanged={(value) => handleFieldValueChange('number', value)}
                  />
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'price.currency', value: 'price.value' }}
                    label={t('Field.ItemPrice')}
                    rules={{
                      value: {
                        required: t('validation:SpecifyField', { field: '$t(Field.ItemPrice)' }),
                        validate: { greaterThanZero: (value) => value > 0 }
                      }
                    }}
                    format={preferences.numberFormat}
                    digits={2}
                    onChangedValue={(value) => handleFieldValueChange('price.value', value)}
                    onChangedCurrency={(value) => handleFieldCurrencyChange('price.currency', value)}
                  />
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'purchasePrice.currency', value: 'purchasePrice.value' }}
                    label={t('Field.PurchasePrice')}
                    rules={{
                      value: {
                        required: t('validation:SpecifyField', { field: '$t(Field.PurchasePrice)' }),
                        validate: { greaterThanZero: (value) => value > 0 }
                      }
                    }}
                    format={preferences.numberFormat}
                    digits={2}
                    onChangedValue={(value) => handleFieldValueChange('purchasePrice.value', value)}
                    onChangedCurrency={(value) => handleFieldCurrencyChange('purchasePrice.currency', value)}
                  />
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'value.currency', value: 'value.value' }}
                    label={t('Field.CurrentValuation')}
                    info={t('UsedForValuation')}
                    format={preferences.numberFormat}
                    digits={2}
                    onFocus={() => trackManualEdit('value')}
                  />
                  <FormAutocomplete
                    control={control}
                    name={'groupIds'}
                    label={t('Field.Groups')}
                    options={groupOptions}
                    placeholder={t('TypeToSearch')}
                    isMulti={true}
                    isLoading={loadingGroups}
                  />
                </div>
              </div>
              <div className={'col-span-2 flex flex-col gap-4'}>
                <FormTextarea control={control} name={'notes'} label={t('Field.PrimaryNotes')} />
              </div>
            </TabPanel.Section>
            <TabPanel.Section value={'additional.acquisition'}>
              <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
                <div className={'flex flex-col gap-4'}>
                  <FormSelect control={control} name={'acquisition.acquisitionType'} label={t('Field.AcquisitionType')}>
                    {acquisitionTypeOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </FormSelect>
                  <FormCreatableAutocomplete
                    control={control}
                    name={'acquisition.sellerId'}
                    label={t('Field.Seller')}
                    placeholder={t('TypeToSearchOrCreate')}
                    options={contactOptions}
                    onCreate={addNewContact}
                    isLoading={loadingContacts}
                  />
                  {isAuction && (
                    <>
                      <FormInput
                        control={control}
                        name={'acquisition.auctionDetail.auctionNumber'}
                        label={t('Field.AuctionNameNumber')}
                      />
                      <FormInput
                        control={control}
                        name={'acquisition.auctionDetail.paddleNumber'}
                        label={t('Field.PaddleNumber')}
                      />
                      <FormInput
                        control={control}
                        name={'acquisition.auctionDetail.lotNumber'}
                        label={t('Field.LotNumber')}
                      />
                    </>
                  )}
                  <FormInput
                    control={control}
                    name={'acquisition.invoiceNumber'}
                    label={t('Field.InvoiceNumber')}
                    rules={{
                      maxLength: {
                        value: 100,
                        message: t('validation:SymbolsLimit', {
                          field: '$t(Field.InvoiceNumber)',
                          number: 100
                        })
                      }
                    }}
                  />
                  <FormInsurancePicker
                    control={control}
                    name={'acquisition.insuranceIds'}
                    label={t('Field.Insurances')}
                  />
                  <FormPriceInput
                    control={control}
                    name={{
                      currency: 'acquisition.initialValuationAtPurchase.currency',
                      value: 'acquisition.initialValuationAtPurchase.value'
                    }}
                    label={t('Field.InitialValuationAtPurchase')}
                    format={preferences.numberFormat}
                    digits={2}
                    onFocus={() => trackManualEdit('acquisition.initialValuationAtPurchase')}
                  />
                </div>
                <div className={'flex flex-col gap-4'}>
                  <FormDatePicker
                    control={control}
                    name={'purchaseDate'}
                    label={t('Field.PurchaseDate')}
                    rules={{
                      required: t('validation:SpecifyField', { field: '$t(Field.PurchaseDate)' })
                    }}
                    format={preferences.dateFormat}
                    timeZone={preferences.timeZone}
                  />
                  {isDirect && (
                    <>
                      <FormPriceInput
                        control={control}
                        name={{ currency: 'purchasePrice.currency', value: 'purchasePrice.value' }}
                        label={t('Field.PurchasePrice')}
                        rules={{
                          value: {
                            required: t('validation:SpecifyField', { field: '$t(Field.PurchasePrice)' }),
                            validate: { greaterThanZero: (value) => value > 0 }
                          }
                        }}
                        format={preferences.numberFormat}
                        digits={2}
                        onChangedValue={(value) => handleFieldValueChange('purchasePrice.value', value)}
                        onChangedCurrency={(value) => handleFieldCurrencyChange('purchasePrice.currency', value)}
                      />
                      <FormPriceInput
                        control={control}
                        name={{
                          currency: 'acquisition.discountAdjustment.currency',
                          value: 'acquisition.discountAdjustment.value'
                        }}
                        label={t('collectables:Field.DiscountPriceAdjustment')}
                        format={preferences.numberFormat}
                        digits={2}
                        disabled={{ currency: true }}
                      />
                    </>
                  )}
                  {isAuction && (
                    <>
                      <FormPriceInput
                        control={control}
                        name={{
                          currency: 'acquisition.auctionDetail.hammerPrice.currency',
                          value: 'acquisition.auctionDetail.hammerPrice.value'
                        }}
                        label={t('Field.HammerPrice')}
                        format={preferences.numberFormat}
                        digits={2}
                        onChangedValue={(value) =>
                          handleFieldValueChange('acquisition.auctionDetail.hammerPrice.value', value)
                        }
                        onChangedCurrency={(value) =>
                          handleFieldCurrencyChange('acquisition.auctionDetail.hammerPrice.currency', value)
                        }
                      />
                      <FormPriceInput
                        control={control}
                        name={{
                          currency: 'acquisition.auctionDetail.taxPayable.currency',
                          value: 'acquisition.auctionDetail.taxPayable.value'
                        }}
                        label={t('Field.TaxPayable')}
                        format={preferences.numberFormat}
                        digits={2}
                        disabled={{ currency: true }}
                      />
                      <FormPriceInput
                        control={control}
                        name={{
                          currency: 'acquisition.auctionDetail.buyerPremium.currency',
                          value: 'acquisition.auctionDetail.buyerPremium.value'
                        }}
                        label={t('Field.BuyerPremium')}
                        format={preferences.numberFormat}
                        digits={2}
                        disabled={{ currency: true }}
                      />
                    </>
                  )}
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'acquisition.otherCost.currency', value: 'acquisition.otherCost.value' }}
                    label={t('Field.OtherCostsEg')}
                    format={preferences.numberFormat}
                    digits={2}
                    disabled={{ currency: true }}
                  />
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'totalCost.currency', value: 'totalCost.value' }}
                    label={t('Field.TotalCost')}
                    format={preferences.numberFormat}
                    digits={2}
                    disabled={{ currency: true, value: true }}
                    allowNegative={true}
                  />
                  <FormCheckbox
                    control={control}
                    name={'acquisition.priceAsValue'}
                    label={t('TotalCostAsAssetValue')}
                  />
                </div>
              </div>
              <div className={'col-span-1'}>
                <FormTextarea control={control} name={'acquisition.notes'} label={t('Field.Notes')} />
              </div>
            </TabPanel.Section>
            <TabPanel.Section value={'additional.location'}>
              <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
                <div className={'flex flex-col gap-4'}>
                  <FormSelect
                    control={control}
                    name={'location.locationType'}
                    label={t('Field.LocationType')}
                    onSelected={() => {
                      setValue('location.locationId', '')
                      setValue('location.roomId', '')
                      setValue('location.position', '')
                    }}
                    rules={{ required: t('validation:SpecifyField', { field: '$t(Field.LocationType)' }) }}
                  >
                    {locationTypeOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </FormSelect>
                  {isProperty && (
                    <FormAutocomplete
                      control={control}
                      name={'location.locationId'}
                      label={t('Field.LocationOfAsset')}
                      options={propertyOptions}
                      placeholder={t('TypeToSearch')}
                      isLoading={loadingProperties}
                      onChanged={() => {
                        setValue('location.roomId', '')
                        setValue('location.position', '')
                      }}
                      rules={{ required: t('validation:SpecifyField', { field: '$t(Field.LocationOfAsset)' }) }}
                      onCancel={handleLocationCancel}
                    />
                  )}
                  {isAddress && (
                    <FormAutocomplete
                      control={control}
                      name={'location.locationId'}
                      label={t('Field.LocationOfAsset')} // TODO: cross-icon to cancel select
                      options={contactOptions}
                      placeholder={t('TypeToSearch')}
                      isLoading={loadingContacts}
                      onChanged={() => {
                        setValue('location.roomId', '')
                        setValue('location.position', '')
                      }}
                      rules={{ required: t('validation:SpecifyField', { field: '$t(Field.LocationOfAsset)' }) }}
                      onCancel={handleLocationCancel}
                    />
                  )}
                  {isNewAddress && (
                    <>
                      <Button
                        className={'group justify-start gap-x-3 justify-self-start'}
                        onClick={() => setIsOpen(true)}
                        disabled={locationId.length > 0}
                      >
                        <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                          <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                        </div>
                        <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                          {t('CreateNewAddress')}
                        </span>
                      </Button>
                      {locationId.length > 0 && (
                        <div className={'flex items-center justify-between'}>
                          <p className={'text-sm'}>
                            {contactOptions?.find(({ value }) => value === locationId)?.label ?? '-'}
                          </p>
                          <Button
                            className={'rounded-full transition-colors hover:bg-grey/20'}
                            onClick={() => setValue('location.locationId', '')}
                          >
                            <XIcon className={'text-primary'} />
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className={'flex flex-col gap-4'}>
                  <FormCreatableAutocomplete
                    control={control}
                    name={'location.roomId'}
                    label={t('Field.Room')}
                    options={roomOptions}
                    onCreate={(name) => addRoom(name)}
                    placeholder={t('SearchOrCreate')}
                    isLoading={loadingRooms}
                    isDisabled={!locationId}
                    onChanged={() => setValue('location.position', '')}
                  />
                  <FormCreatableAutocomplete
                    control={control}
                    name={'location.position'}
                    label={t('Field.Position')}
                    options={positionOptions}
                    onCreate={(name) => addPosition(name)}
                    placeholder={t('SearchOrCreate')}
                    isLoading={loadingPositions}
                    isDisabled={!roomId}
                  />
                </div>
              </div>
              <div className={'col-span-1'}>
                <FormTextarea
                  control={control}
                  name={'location.notes'}
                  label={t('Field.Notes')}
                  rules={{
                    maxLength: {
                      value: 1000,
                      message: t('validation:SymbolsLimit', {
                        field: '$t(Field.Notes)',
                        number: 1000
                      })
                    }
                  }}
                />
              </div>
            </TabPanel.Section>
            <TabPanel.Section value={'additional.attributes'}>
              <div className={'grid grid-cols-1 gap-4  md:grid-cols-2'}>
                <div className={'flex flex-col gap-4'}>
                  <TypePicker
                    control={control}
                    name={'measurementUnits'}
                    label={t('Field.MeasurementUnits')}
                    options={measurementOptions}
                    onChanged={onUnitChanged}
                  />
                  <FormNumberInput
                    control={control}
                    name={'height'}
                    label={t('Field.Height')}
                    prefix={measurementUnits === MeasurementUnits.Metric ? 'cm' : 'in'}
                    format={preferences.numberFormat}
                    digits={2}
                    rules={{
                      validate: {
                        maximum: (value) => {
                          const maximum = measurementUnits === MeasurementUnits.Metric ? 254_000 : 100_000
                          return value ? value <= maximum : true
                        }
                      }
                    }}
                  />
                  <FormNumberInput
                    control={control}
                    name={'width'}
                    label={t('Field.Width')}
                    prefix={measurementUnits === MeasurementUnits.Metric ? 'cm' : 'in'}
                    format={preferences.numberFormat}
                    digits={2}
                    rules={{
                      validate: {
                        maximum: (value) => {
                          const maximum = measurementUnits === MeasurementUnits.Metric ? 254_000 : 100_000
                          return value ? value <= maximum : true
                        }
                      }
                    }}
                  />
                  <FormNumberInput
                    control={control}
                    name={'depth'}
                    label={t('Field.Depth')}
                    prefix={measurementUnits === MeasurementUnits.Metric ? 'cm' : 'in'}
                    format={preferences.numberFormat}
                    digits={2}
                    rules={{
                      validate: {
                        maximum: (value) => {
                          const maximum = measurementUnits === MeasurementUnits.Metric ? 254_000 : 100_000
                          return value ? value <= maximum : true
                        }
                      }
                    }}
                  />
                  <FormNumberInput
                    control={control}
                    name={'weight'}
                    label={t('Field.Weight')}
                    prefix={measurementUnits === MeasurementUnits.Metric ? 'kg' : 'lb'}
                    format={preferences.numberFormat}
                    digits={2}
                    rules={{
                      validate: {
                        maximum: (value) => {
                          const maximum = measurementUnits === MeasurementUnits.Metric ? 45_359.237 : 100_000
                          return value ? value <= maximum : true
                        }
                      }
                    }}
                  />
                  <FormInput
                    control={control}
                    name={'dimensionsSummary'}
                    label={t('collectables:Field.DimensionsSummary')}
                  />
                  <FormCreatableAutocomplete
                    control={control}
                    name={'medium'}
                    label={t('collectables:Field.Medium')}
                    options={mediumOptions}
                    placeholder={t('SearchOrCreate')}
                    onCreate={(name) => addCustomType(database!, CustomizedType.ArtMedium, name)}
                    isLoading={loadingMediums}
                  />
                </div>
                <div className={'flex flex-col gap-4'}>
                  <FormDatePicker
                    control={control}
                    name={'creationCompletionYear'}
                    label={t('collectables:Field.CreationCompletionYear')}
                    format={preferences.dateFormat}
                    timeZone={preferences.timeZone}
                  />
                  <FormInput control={control} name={'edition'} type={'text'} label={t('collectables:Field.Edition')} />
                  <FormInput control={control} name={'provenance'} label={t('collectables:Field.Provenance')} />
                  <FormAutocomplete
                    control={control}
                    name={'geography'}
                    label={t('collectables:Field.Geography')}
                    options={CountryOptions}
                    placeholder={t('TypeToSearch')}
                  />
                  <FormInput
                    control={control}
                    name={'inscription'}
                    label={t('collectables:Field.Inscription')}
                    rules={{
                      maxLength: {
                        value: 100,
                        message: t('validation:SymbolsLimit', {
                          field: '$t(collectables:Field.Inscription)',
                          number: 100
                        })
                      }
                    }}
                  />
                  <FormInput
                    control={control}
                    name={'description'}
                    type={'text'}
                    label={t('collectables:Field.Description')}
                    rules={{
                      maxLength: {
                        value: 1000,
                        message: t('validation:SymbolsLimit', {
                          field: '$t(collectables:Field.Description)',
                          number: 1000
                        })
                      }
                    }}
                  />
                  <FormInput
                    control={control}
                    name={'catalogueText'}
                    type={'text'}
                    label={t('collectables:Field.CatalogueText')}
                    rules={{
                      maxLength: {
                        value: 1000,
                        message: t('validation:SymbolsLimit', {
                          field: '$t(collectables:Field.CatalogueText)',
                          number: 1000
                        })
                      }
                    }}
                  />
                </div>
              </div>
              <div className={'col-span-1'}>
                <FormTextarea control={control} name={'attributeNotes'} label={t('Field.Notes')} />
              </div>
            </TabPanel.Section>
            <TabPanel.Section value={'additional.ownership'} className={'items-start'}>
              <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
                <div className={'space-y-4'}>
                  <div className={'grid gap-1'}>
                    <div className={'flex items-center justify-between pr-10'}>
                      <span className={'text-sm'}>{t('Field.Ownership')}</span>
                      {remainingOwnership === 0 && (
                        <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                      )}
                      {remainingOwnership > 0 && (
                        <span className={'text-xs text-[#d89423]'}>
                          {t('RemainingPercent', { percent: remainingOwnership })}
                        </span>
                      )}
                      {remainingOwnership < 0 && (
                        <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>
                      )}
                    </div>
                    <div className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                      <Input className={'h-[38px] border'} value={t('Field.MyOwnership')} disabled={true} />
                      <FormPercentInput control={control} name={`ownership.myOwnership`} />
                    </div>
                  </div>
                  <div className={'grid gap-1'}>
                    <span className={'text-sm'}>{t('Field.Shareholder')}</span>
                    <ul className={'space-y-2 empty:hidden'}>
                      {shareholderArray.fields.map((shareholder, index) => (
                        <li key={shareholder.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                          <FormCreatableAutocomplete
                            control={control}
                            name={`ownership.shareholder.${index}.contactId`}
                            options={ShareholderUnselectContact(index)}
                            onCreate={addNewContact}
                            isLoading={loadingContacts}
                            placeholder={t('SearchOrCreate')}
                          />
                          <FormPercentInput control={control} name={`ownership.shareholder.${index}.percent`} />
                          <Button
                            className={'rounded-full transition-colors hover:bg-grey/20'}
                            onClick={() => shareholderArray.remove(index)}
                          >
                            <XIcon className={'text-primary'} />
                          </Button>
                        </li>
                      ))}
                    </ul>
                    <Button
                      className={'group justify-start gap-x-3 justify-self-start py-2'}
                      onClick={() => shareholderArray.append({ contactId: '', percent: 0 })}
                    >
                      <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                        <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                      </div>
                      <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                        {t('AddShareholders')}
                      </span>
                    </Button>
                    {/* TODO: Treat Asset Value As */}
                  </div>
                </div>
                <div className={'space-y-1'}>
                  <div className={'flex items-center justify-between pr-10'}>
                    <span className={'text-sm'}>{t('Field.Beneficiary')}</span>
                    {remainingBeneficiary === 0 && (
                      <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                    )}
                    {remainingBeneficiary > 0 && (
                      <span className={'text-xs text-[#d89423]'}>
                        {t('RemainingPercent', { percent: remainingBeneficiary })}
                      </span>
                    )}
                    {remainingBeneficiary < 0 && (
                      <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>
                    )}
                  </div>
                  <ul className={'space-y-2 empty:hidden'}>
                    {beneficiaryArray.fields.map((beneficiary, index) => (
                      <li key={beneficiary.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                        <FormCreatableAutocomplete
                          control={control}
                          name={`beneficiary.${index}.contactId`}
                          options={beneficiaryUnselectContact(index)}
                          onCreate={addNewContact}
                          isLoading={loadingContacts}
                          placeholder={t('SearchOrCreate')}
                        />
                        <FormPercentInput control={control} name={`beneficiary.${index}.percent`} />
                        <Button
                          className={'rounded-full transition-colors hover:bg-grey/20'}
                          onClick={() => beneficiaryArray.remove(index)}
                        >
                          <XIcon className={'text-primary'} />
                        </Button>
                      </li>
                    ))}
                  </ul>
                  <Button
                    className={'group justify-start gap-x-3 justify-self-start py-2'}
                    onClick={() => beneficiaryArray.append({ contactId: '', percent: 0 })}
                  >
                    <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                      <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                    </div>
                    <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                      {t('AddBeneficiaries')}
                    </span>
                  </Button>
                </div>
              </div>
            </TabPanel.Section>
            <TabPanel.Section value={'attachments'} className={'h-full md:grid-cols-1'}>
              {assetId && (
                <AttachmentPanel
                  assetId={assetId}
                  control={control}
                  name={{ mainImage: 'mainImage', attachments: 'attachments' }}
                  widgetOptions={[
                    AttachmentKind.AssetImage,
                    AttachmentKind.PrimaryDetails,
                    AttachmentKind.Acquisition,
                    AttachmentKind.Location,
                    AttachmentKind.Attributes,
                    AttachmentKind.Ownership,
                    AttachmentKind.Beneficiaries
                  ]}
                />
              )}
            </TabPanel.Section>
          </div>
          <fieldset className={'flex justify-center gap-2 p-4 md:justify-end'} disabled={isSubmitting}>
            <Button
              id={'collectables_add_art_cancel'}
              className={'min-w-[130px]'}
              variant={'outline'}
              size={'md'}
              onClick={onCancel}
            >
              {t('Cancel')}
            </Button>
            <Button
              id={'collectables_add_art_create'}
              className={'group relative min-w-[130px]'}
              variant={'solid'}
              size={'md'}
              type={'submit'}
              disabled={!isValid || !locationId}
            >
              {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
              <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
            </Button>
          </fieldset>
        </form>
      </TabPanel>

      <NewAddress
        isOpen={isOpen}
        onSubmitted={(id) => setValue('location.locationId', id)}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

export function ArtFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}
