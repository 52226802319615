import { FieldDecorator } from "./fieldDecorator";
import {
  IsIn,
  IsString as IsStringUnconstrained,
  isString,
  IsOptional as IsOptionalBase,
  MaxLength,
  MinLength,
  ValidationOptions,
  validateSync,
} from "class-validator";
import { Amount, Beneficiary, Owner, Ownership } from "../types/common";
import { ClassConstructor, plainToInstance } from "class-transformer";
import { InvalidInput } from "../types/error";
import { validateStringNotEmpty } from "../utils";
export * from "class-validator";

const DefaultStringLength = 1000;
const LargeStringLength = 5000;
const SmallStringLength = 100;
const AmountMin = 0;
const AmountMax = 999999999999;

/**
 * These are the validations that are checked by firestoreRules.validations.ts - but other class-validator decorators can be used
 * for runtime validation
 */
export enum Validations {
  isDate = "isDate",
  isAmount = "isAmount",
  isEnum = "isEnum",
  isString = "isString",
  IsStringNotEmpty = "IsStringNotEmpty",
  isInt = "isInt",
  isNumber = "isNumber",
  maxLength = "maxLength",
  minLength = "minLength",
  min = "min",
  max = "max",
  minDate = "minDate",
  maxDate = "maxDate",
  beforeNow = "beforeNow",
  afterNow = "afterNow",
  greaterThanOriginal = "greaterThanOriginal",
  lessThanOriginal = "lessThanOriginal",
  isBoolean = "isBoolean",
  isArray = "isArray",
  isObject = "isObject",
  isReferenceId = "isReferenceId",
  isValidObject = "isValidObject",
  isOptional = "isOptional",
  isOptionalOnUpdate = "isOptionalOnUpdate",
  IsOptionalBeforeEncrypt = "IsOptionalBeforeEncrypt",
  isIn = "isIn",
  isValidOwnerShip = "isValidOwnerShip",
  isValidBeneficiary = "isValidBeneficiary",
}

/*
  These are used to group the validation decorators for different situation
*/
export enum ValidationGroup {
  OnCreate = "OnCreate",
  OnUpdate = "OnUpdate",
}

export function BeforeNow(options: ValidationOptions) {
  return FieldDecorator(Validations.beforeNow, options, undefined, {
    validate(date: Date) {
      return date < new Date();
    },
  });
}

export function AfterNow(options: ValidationOptions) {
  return FieldDecorator(Validations.afterNow, options, undefined, {
    validate(date: Date) {
      return date > new Date();
    },
  });
}

//Override of 'IsOptional' in class-validator because it seems to have a bug where the decorator.name is not set properly
function IsOptionalGeneric(name: Validations, options?: ValidationOptions) {
  return (t: Object, key: string | symbol) => {
    FieldDecorator(name, options, undefined)(t, key as string);
    IsOptionalBase(options)(t, key);
  };
}

export function IsOptional(options?: ValidationOptions) {
  return IsOptionalGeneric(Validations.isOptional, options);
}

export function IsOptionalOnUpdate(
  options: ValidationOptions = { groups: [] }
) {
  return IsOptional({
    ...options,
    groups: [ValidationGroup.OnUpdate],
  });
}

export function GreaterThanOriginal(options?: ValidationOptions) {
  return FieldDecorator(Validations.greaterThanOriginal, options);
}

export function LessThanOriginal(options?: ValidationOptions) {
  return FieldDecorator(Validations.lessThanOriginal, options);
}

export function IsExactly(value: any) {
  return IsIn([value]);
}

export function IsAmount(
  {
    min = AmountMin,
    max = AmountMax,
    includeMin = true,
    includeMax = true,
    ...options
  }: {
    min?: number;
    max?: number;
    includeMin?: boolean;
    includeMax?: boolean;
  } & ValidationOptions = {
    min: AmountMin,
    max: AmountMax,
    includeMin: true,
    includeMax: true,
  }
) {
  return FieldDecorator(
    Validations.isAmount,
    options,
    { min, max },
    {
      validate(amount: Amount) {
        const regex = /^-?\d{1,12}(\.\d{1,2})?$/;
        return (
          (includeMin ? amount.value >= min : amount.value > min) &&
          (includeMax ? amount.value <= max : amount.value < max) &&
          regex.test(amount.value.toString())
        );
      },
    }
  );
}

export const IsShortString: typeof IsString = (opts) =>
  IsString({ maxLength: SmallStringLength, ...opts });
export const IsLongString: typeof IsString = (opts) =>
  IsString({ maxLength: LargeStringLength, ...opts });
export const IsReferenceId = IsShortString; //Could strengthen this validation later

export function IsString(
  {
    minLength,
    maxLength = DefaultStringLength,
    ...validationOptions
  }: { minLength?: number; maxLength?: number } & ValidationOptions = {
    maxLength: DefaultStringLength,
  }
) {
  return (t: Object, key: string | symbol) => {
    IsStringUnconstrained(validationOptions)(t, key);
    if (maxLength) MaxLength(maxLength, validationOptions)(t, key);
    if (minLength) MinLength(minLength, validationOptions)(t, key);
  };
}

export function IsStringNotEmpty(options?: ValidationOptions) {
  return FieldDecorator(Validations.IsStringNotEmpty, options, undefined, {
    validate(value: string) {
      if (!isString(value)) {
        return false;
      } else {
        return validateStringNotEmpty(value);
      }
    },
  });
}

//#NOTE: Only used for generating Firestore security rules
export function IsValidObject<T extends object>(
  cls: new () => T,
  options?: ValidationOptions
) {
  return FieldDecorator(Validations.isValidObject, options, cls);
}

//#NOTE: reference to the 'validate' function in Owner namespace
function ownerValidate(reservedPercentage: number, owners: Owner[]) {
  if (reservedPercentage < 0 || reservedPercentage > 100) {
    return false;
  }
  const total = owners.reduce(
    (acc, owner) => acc + owner.percent,
    reservedPercentage
  );
  return total <= 100;
}

export function isValidOwnerShip(options?: ValidationOptions) {
  return FieldDecorator(Validations.isValidOwnerShip, options, undefined, {
    validate(value: Ownership) {
      return ownerValidate(value.myOwnership, value.shareholder);
    },
  });
}

export function isValidBeneficiary(options?: ValidationOptions) {
  return FieldDecorator(Validations.isValidBeneficiary, options, undefined, {
    validate(value: Beneficiary) {
      return ownerValidate(0, value);
    },
  });
}

export function validateWithGroups<D extends object>(
  data: D,
  cls: ClassConstructor<D>,
  groupOpts: ValidationGroup[] = []
) {
  const instance = plainToInstance(cls, data);

  //#NOTE: forbidUnknownValues: false is important here, due to the issue: https://github.com/nestjs/nest/issues/10683
  //#NOTE: `always: true` will enable all the validation without any groups on it
  const errors = validateSync(instance, {
    groups: groupOpts,
    forbidUnknownValues: false,
    always: true,
  });

  if (errors.length > 0) {
    const errorsString = errors.map((error) => error.toString()).join(", ");
    throw new InvalidInput(
      `Error target: ${JSON.stringify(errors[0].target)}\n` + errorsString
    );
  }
}
