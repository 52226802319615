import { memo, useEffect, useState } from 'react'
import Image from 'next/image'

import { useAuthStore } from '@/store/authStore'

interface EncryptedImageProps {
  className?: string
  src?: string
  base64IV?: string
  alt: string
  fill?: boolean
  placeholder?: string
}

/**
 * Renders an encrypted image that is fetched from a URL, decrypted, and displayed.
 *
 * @param className - The CSS class name for the image container.
 * @param src - The URL of the encrypted image.
 * @param base64IV - The base64-encoded IV salt for encrypting/decrypting the image.
 * @param alt - The alternative text for the image.
 * @param fill - Whether the image should fill its container.
 * @param placeholder - (NOT encrypted) The URL of a placeholder image to display while the encrypted image is being fetched and decrypted.
 * @returns The rendered encrypted image component.
 */

export const EncryptedImage = memo<EncryptedImageProps>(({ className, src, base64IV, alt, fill, placeholder }) => {
  const [decryptedUrl, setDecryptedUrl] = useState<string | null>(null)

  useEffect(() => {
    const decryptImage = async () => {
      try {
        const { database } = useAuthStore.getState()
        const resp = await fetch(src!)
        const encrypted = await resp.arrayBuffer()
        const iv = database!.Encryption.current.convertBase64ToIVSalt(base64IV!)
        const decrypted = await database!.Encryption.current.decryptBytes(encrypted, iv)
        const blob = new Blob([decrypted], { type: 'image/jpeg' })
        const url = URL.createObjectURL(blob)
        setDecryptedUrl((prev) => {
          if (prev) URL.revokeObjectURL(prev)
          return url
        })
      } catch (error) {
        console.error(`Failed to fetch and decrypt image: ${error}`)
      }
    }

    if (src && base64IV) decryptImage()
  }, [base64IV, src])

  const url = src && decryptedUrl ? decryptedUrl : placeholder

  return url && <Image className={className} src={url} alt={alt} fill={fill} draggable={false} />
})

EncryptedImage.displayName = 'EncryptedImage'
