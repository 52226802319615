import { useEffect, useState } from 'react'
import Image from 'next/image'
import { TotpMultiFactorGenerator, type TotpSecret } from 'firebase/auth'
import { motion } from 'framer-motion'
import { Loader2Icon } from 'lucide-react'
import QRCode from 'qrcode'
import { Trans, useTranslation } from 'react-i18next'
import { mutate } from 'swr'

import { cn } from '@/utils/classnames'
import { parseErrorMessage } from '@/utils/parseErrorMessages'
import { useToast } from '@/hooks/useToast'
import { useAuthStore } from '@/store/authStore'
import { authenticatorComponents } from '@/pages/account/profile/totp'
import { Policies } from '@/pages/auth/login'
import { VerifyCode, VerifyFormValues } from '@/pages/auth/two-factor/totp'
import { Button } from '@/components/base'

type TotpStep = 'qrcode' | 'enroll'

export default function EnrollTotp() {
  const { toast } = useToast()
  const { t } = useTranslation()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [step, setStep] = useState<TotpStep>('qrcode')
  const [dataUrl, setDataUrl] = useState<string | null>(null)
  const [secret, setSecret] = useState<TotpSecret | null>(null)
  const logout = useAuthStore((state) => state.logout)
  const database = useAuthStore((state) => state.database)

  const handleClose = async () => {
    await database!.Account.updatePreferences({ remindMFA: new Date() })
    mutate('checkEnrollTotp')
  }

  const handleContinue = () => setStep('enroll')

  const handleBack = () => setStep('qrcode')

  const onEnroll = async ({ verificationCode }: VerifyFormValues) => {
    try {
      setSubmitting(true)
      if (secret === null) throw Error('No totp secret') // enroll
      await database!.Account.enrollTotp(secret, verificationCode)
      handleClose()
    } catch (e) {
      const errorMessage = parseErrorMessage(e, 'Unknown error: Enroll Totp')
      toast({ variant: 'error', description: errorMessage })
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    const onVerify = async () => {
      try {
        const user = database!.Account.getUser()
        const session = await database!.Account.getMultiFactorSession()
        const secret = await TotpMultiFactorGenerator.generateSecret(session)
        const uri = secret.generateQrCodeUrl(user.email, 'MyAssets')
        const dataUrl = await QRCode.toDataURL(uri, { margin: 0 })
        setSecret(secret)
        setDataUrl(dataUrl)
      } catch (e) {
        const errorMessage = parseErrorMessage(e, 'Unknown error: Generate Totp QRCode')
        toast({ variant: 'error', description: errorMessage })
      }
    }
    onVerify()
  }, [database, toast])

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={'relative grid h-screen w-full place-items-center bg-background'}
    >
      <Image
        src={'/images/auth/circuit.svg'}
        alt={'Ornament'}
        className={'absolute right-0 top-1/2 w-auto -translate-y-1/2'}
        width={0}
        height={0}
        priority={true}
      />
      {submitting ? (
        <Loader2Icon className={'absolute animate-spin text-primary'} />
      ) : (
        <div>
          <div className={'flex w-full max-w-[440px] flex-col gap-4 rounded border border-grey/50 p-[50px] text-white'}>
            <>
              {step === 'qrcode' && (
                <form className={'space-y-4'} onSubmit={handleContinue}>
                  <div className={'space-y-2.5 pt-5'}>
                    <h4 className={'text-3xl font-semibold'}>{t('account:Setup2FAHint')}</h4>
                    <Trans
                      i18nKey={'2FADescription'}
                      parent={(props: any) => <p className={'text-sm'} {...props} />}
                      components={{ bold: <span className={'font-bold'} /> }}
                    />
                    <Trans
                      i18nKey={'account:ScanQRCode'}
                      parent={(props: any) => <p className={'text-sm'} {...props} />}
                      components={authenticatorComponents}
                    />
                    <Trans
                      i18nKey={'Find2FA'}
                      parent={(props: any) => <p className={'text-sm'} {...props} />}
                      components={{ bold: <span className={'font-bold'} /> }}
                    />
                    <div className={'flex flex-col items-center gap-y-4 pt-5'}>
                      {dataUrl ? (
                        <Image src={dataUrl} alt={'QR Code'} width={152} height={152} />
                      ) : (
                        <div className={'h-[152px] w-[152px] animate-pulse bg-gray-200'} />
                      )}
                      <div className={'w-full space-y-1'}>
                        <p className={'text-center text-xs'}>{t('account:OrSecretKey')}</p>
                        <div
                          className={cn('h-8 w-full place-content-center border-b border-primary bg-[#F6F6F6]', {
                            'animate-pulse': !secret?.secretKey
                          })}
                        >
                          <span className={'text-sm text-black/50'}>{secret?.secretKey}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button className={'w-full'} variant={'solid'} size={'md'} type={'submit'}>
                    {t('Continue')}
                  </Button>
                  <Button className={'w-full'} variant={'outline'} size={'md'} onClick={handleClose}>
                    {t('Skip')}
                  </Button>
                </form>
              )}
              {step === 'enroll' && (
                <>
                  <VerifyCode onSubmit={onEnroll} />
                  <Button variant={'ghost'} size={'md'} onClick={handleBack}>
                    {t('GoBack')}
                  </Button>
                </>
              )}
            </>
          </div>
          <Policies />
        </div>
      )}
    </motion.main>
  )
}
