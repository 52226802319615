import {
  Wine,
  WineAggregateState,
  WineCatalogueMinInfo,
  WinePurchase,
  Command,
  Event,
  OneBottleOfWine,
  Removal,
  WineReportBuilder,
  WineForReport,
  WineStatus,
  WineStateWriter,
} from "../types/wineAndSprits";
import { AggregateRoot, newRepo as newAggregateRepo } from "../types/aggregate";
import { LocationType, Optional, Attachment, Currency } from "../types/common";
import { AllowedDecimalPlaces, OmitKeys, addDecimal } from "../utils";
import { PropertiesRepo } from "./properties";
import {
  BottleLocationNode,
  BottlesInLocation,
  WineSummary,
} from "../types/wineSummary";
import { ExchangeRate } from "../database/exchangeRate";
import {
  AsyncTask,
  AsyncTaskExecutor,
  IAsyncTaskExecutor,
} from "../types/asyncTask";
import {
  RelationSearchKeyword,
  RoleToAsset,
  fromRelationsOfAsset,
  isRole,
  toKeywordWithId,
} from "../types/relations";
import { DataPoisoned, InvalidInput } from "../types/error";
import { FullRefs, Refs, getAssetsByIds } from "../refs";
import { TastingNote } from "../types/actions/tastingNote";
import {
  actionRefFromAssetDocRef,
  buildAddActionCommand,
  buildDeleteActionCommand,
  buildUpdateActionCommand,
  doGetAllActions,
} from "./actions";
import { Action, TastingNoteBundle } from "../types/actions";
import { ActionType } from "../types/actions/base";
import Decimal from "decimal.js";
import { EncryptionManager } from "./encryption";
import { AssetType } from "../types/enums";
import { DbSharedFields } from "../types/database";
import { SummaryManager } from "../types/summaryManager";
import {
  CoreFirestore,
  DocumentReference,
  Transaction,
  checkAndGetData,
  checkDuplicated,
  getQueriedData,
} from "../../coreFirebase";
import { TypeResult } from "../types/typeVersion";
import { CashAndBankingRepo } from "./cashAndBanking";
import { LocationInfo } from "../types/relations/locationInfo";

const DeleteBatchLimit = 50;

export class WineAndSpiritsRepo {
  protected readonly refs: FullRefs;
  protected readonly exRate: ExchangeRate;
  protected readonly summaryManager: SummaryManager;

  readonly Encryption: EncryptionManager;

  constructor(shared: DbSharedFields) {
    this.exRate = shared.exRate;
    this.refs = shared.refs;
    this.Encryption = shared.encryption;
    this.summaryManager = shared.summaryManager;
  }

  async getSyncedSummary(currency?: Currency) {
    const summary = (
      await this.summaryManager.get(AssetType.WineAndSpirits).syncAndGetData()
    ).summary;
    this.exRate.checkInitialized();
    const exRate = await this.exRate.getToTargetExchangeRates(
      currency || this.exRate.BaseCurrency!
    );
    return WineSummary.toDisplay(summary, exRate);
  }

  // This function only returns locationId, locationType, itemNumber, value.
  // One needs to get other info from Wine or Contact api.
  async getSyncedLocation(currency?: Currency) {
    const [wines, locationRelation] = await Promise.all([
      this.summaryManager
        .get(AssetType.WineAndSpirits)
        .syncAndGetData()
        .then((v) => v.summary.wines),
      CoreFirestore.getDocsFromCollection(
        this.refs.currentRefs.Relations,
        CoreFirestore.where("assetType", "==", AssetType.WineAndSpirits)
      ).then(getQueriedData),
    ]);
    this.exRate.checkInitialized();
    const exRate = await this.exRate.getToTargetExchangeRates(
      currency || this.exRate.BaseCurrency!
    );

    const locations: { [id: string]: WineSummary.LocationInfo } = {};
    locationRelation.forEach((v) => {
      const {
        id: purchaseId,
        secondaryId: wineId,
        relatedTargets,
      } = fromRelationsOfAsset(v);
      if (!wineId) throw new DataPoisoned("wineId not found in relation");
      Object.values(relatedTargets).forEach((target) => {
        // filter out location relations
        if (!isRole(target, RoleToAsset.AssetLocation)) return;
        const location = target.relations[RoleToAsset.AssetLocation]!.location;
        const numOfBottles =
          target.relations[RoleToAsset.AssetLocation]!.bottles?.length;
        if (!numOfBottles)
          throw new DataPoisoned("bottles not found in relation");
        if (!wines[wineId]) throw new DataPoisoned("Wine not found");
        const purchase = wines[wineId].purchases.find(
          (p) => p.id === purchaseId
        );
        if (!purchase) throw new DataPoisoned("Purchase not found");
        const locationInfo: WineSummary.LocationInfo = {
          id: location.locationId,
          locationType: location.locationType,
          itemNumber: numOfBottles,
          value: {
            currency: exRate.targetCurrency,
            //#QUESTION should we consider ownership here?
            value: new Decimal(numOfBottles)
              .mul(purchase.valuePerBottle.value)
              .mul(exRate.rates[purchase.valuePerBottle.currency].rate)
              // .mul(purchase.ownedPercentage)
              // .div(100)
              .toDecimalPlaces(AllowedDecimalPlaces)
              .toNumber(),
          },
        };
        if (locations[location.locationId]) {
          locations[location.locationId].itemNumber += locationInfo.itemNumber;
          locations[location.locationId].value.value = addDecimal(
            locations[location.locationId].value.value,
            locationInfo.value.value
          );
        } else {
          locations[location.locationId] = locationInfo;
        }
      });
    });

    return Object.values(locations);
  }

  // Needs to get room name from property
  async getBottlesInLocation(
    locationId: string
  ): Promise<BottlesInLocation.DisplayNode> {
    const locationRelation = await CoreFirestore.getDocsFromCollection(
      this.refs.currentRefs.Relations,
      CoreFirestore.orderBy(
        toKeywordWithId(RelationSearchKeyword.WineBottleLocation, locationId),
        "desc"
      )
    ).then(getQueriedData);
    const wineInLocation: BottleLocationNode = {
      located: {},
      notLocated: { bottles: 0 },
    };

    locationRelation.forEach((v) => {
      const { secondaryId: wineId, relatedTargets } = fromRelationsOfAsset(v);
      if (!wineId) throw new DataPoisoned("wineId not found in relation");
      const relation = relatedTargets[locationId];
      if (!isRole(relation, RoleToAsset.AssetLocation)) return;
      const bottles = relation.relations[RoleToAsset.AssetLocation]!.bottles;
      if (!bottles) throw new DataPoisoned("bottles not found in relation");
      for (const bottle of bottles) {
        if (bottle.roomId) {
          if (!wineInLocation.located[bottle.roomId])
            wineInLocation.located[bottle.roomId] = { bottles: 0 };
          const roomInfo = wineInLocation.located[bottle.roomId];
          roomInfo.bottles += 1;
          if (!roomInfo.child)
            roomInfo.child = { located: {}, notLocated: { bottles: 0 } };
          if (bottle.position) {
            if (!roomInfo.child.located[bottle.position])
              roomInfo.child.located[bottle.position] = { bottles: 0 };
            const positionInfo = roomInfo.child.located[bottle.position];
            positionInfo.bottles += 1;
            if (!positionInfo.details) positionInfo.details = {};
            if (!positionInfo.details[wineId]) positionInfo.details[wineId] = 0;
            positionInfo.details[wineId] += 1;
          } else {
            roomInfo.child.notLocated.bottles += 1;
            if (!roomInfo.child.notLocated.details)
              roomInfo.child.notLocated.details = {};
            if (!roomInfo.child.notLocated.details[wineId])
              roomInfo.child.notLocated.details[wineId] = 0;
            roomInfo.child.notLocated.details[wineId] += 1;
          }
        } else {
          wineInLocation.notLocated.bottles += 1;
          if (!wineInLocation.notLocated.details)
            wineInLocation.notLocated.details = {};
          if (!wineInLocation.notLocated.details[wineId])
            wineInLocation.notLocated.details[wineId] = 0;
          wineInLocation.notLocated.details[wineId] += 1;
        }
      }
    });

    return this.bottleLocationToDisplayRecursive(wineInLocation);
  }

  private bottleLocationToDisplayRecursive(node: BottleLocationNode) {
    const result: BottlesInLocation.DisplayNode = {};
    if (Object.keys(node.located).length > 0) {
      result.located = [];
      Object.entries(node.located).map(([id, info]) => {
        const displayInfo: BottlesInLocation.DisplayInfo = {
          location: id,
          bottles: info.bottles,
        };
        if (info.details) {
          displayInfo.details = Object.entries(info.details).map(
            ([wineDocId, bottles]) => ({ wineDocId, bottles })
          );
        }
        if (info.child) {
          displayInfo.child = this.bottleLocationToDisplayRecursive(info.child);
        }
        result.located!.push(displayInfo);
      });
    }
    if (node.notLocated.bottles > 0) {
      result.notLocated = {
        bottles: node.notLocated.bottles,
        details: Object.entries(node.notLocated.details!).map(
          ([wineDocId, bottles]) => ({ wineDocId, bottles })
        ),
      };
    }
    return result;
  }

  private actionDocRef(id: string, actionId: string) {
    return CoreFirestore.docFromCollection(
      this.refs.currentRefs.getActionCollectionRef<WineAggregateState.SupportActionsEncrypted>(
        AssetType.WineAndSpirits,
        id
      ),
      actionId
    );
  }

  // #NOTE read before this function and set/update after this function
  private async handleRelatedAggregates(
    transaction: Transaction,
    ar: AggregateRoot<WineAggregateState, Command, Event>,
    isDelete?: boolean
  ) {
    const asset = ar.state().wine;
    const data = ar.relatedUpdates() as WineAggregateState.RelatedUpdates;
    const repoAndAggregates: Wine.RelatedAggregates = {};
    if (isDelete) {
      repoAndAggregates.cashAndBanking = {
        repo: await CashAndBankingRepo.newRepo(
          this.refs.currentRefs,
          transaction
        ),
        aggregates: await CashAndBankingRepo.newArAndUpdateAllocatedLiability(
          this.refs.currentRefs,
          transaction,
          this.refs.selfRefs.userId,
          asset.id
        ),
      };
    }
    if (repoAndAggregates.cashAndBanking) {
      const accountData = repoAndAggregates.cashAndBanking;
      accountData.aggregates.forEach((aggregate) => {
        accountData.repo.commitWithState(aggregate);
      });
    }

    if (data.setActions) {
      data.setActions.forEach((action) => {
        const docRef = this.actionDocRef(asset.id, action.id);
        action.ownerId = this.refs.currentRefs.userId;
        transaction.set(docRef, action);
      });
    }
    if (data.removedActionIds) {
      data.removedActionIds.forEach((id) => {
        const docRef = this.actionDocRef(asset.id, id);
        transaction.delete(docRef);
      });
    }
  }

  async getAllWines(): Promise<Wine[]> {
    const collectionRef =
      this.refs.currentRefs.getAssetCollectionRef<Wine.Encrypted>(
        AssetType.WineAndSpirits
      );
    const wines = await Promise.all(
      (
        await CoreFirestore.getDocsFromCollection(
          collectionRef,
          CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId)
        ).then(getQueriedData)
      ).map((v) => Wine.decrypt(Wine.convertDate(v), this.Encryption.current))
    );
    return wines.sort((a, b) => b.updateAt.getTime() - a.updateAt.getTime());
  }

  async getAllPurchasesOfWine(wineId: string) {
    const collectionRef =
      this.refs.currentRefs.getAssetCollectionRef<WinePurchase.Encrypted>(
        AssetType.WinePurchases
      );

    const purchases = await Promise.all(
      (
        await CoreFirestore.getDocsFromCollection(
          collectionRef,
          CoreFirestore.where("wineId", "==", wineId)
        ).then(getQueriedData)
      ).map((v) =>
        WinePurchase.decrypt(
          WinePurchase.convertDate(v),
          this.Encryption.current
        )
      )
    );
    return purchases.sort(
      (a, b) => b.updateAt.getTime() - a.updateAt.getTime()
    );
  }

  async getWinesByIds(ids: string[]): Promise<Wine[]> {
    const result = await getAssetsByIds<Wine.Encrypted>(
      this.refs.currentRefs,
      AssetType.WineAndSpirits,
      ids.map((v) => Wine.checkAndBuildWineId(this.refs.currentRefs.userId, v))
    );
    return Promise.all(
      result.map((v) =>
        Wine.decrypt(Wine.convertDate(v), this.Encryption.current)
      )
    );
  }

  async getWineById(id: string): Promise<Wine> {
    const wineId = Wine.checkAndBuildWineId(this.refs.currentRefs.userId, id);
    const docRef = this.refs.currentRefs.getAssetDocRef<Wine.Encrypted>(
      AssetType.WineAndSpirits,
      wineId
    );
    return await CoreFirestore.getDoc(docRef)
      .then(checkAndGetData)
      .then((v) => Wine.decrypt(Wine.convertDate(v), this.Encryption.current));
  }

  async getWinePurchasesByIds(ids: string[]): Promise<WinePurchase[]> {
    const result = await getAssetsByIds<WinePurchase.Encrypted>(
      this.refs.currentRefs,
      AssetType.WinePurchases,
      ids
    );
    return Promise.all(
      result.map((v) =>
        WinePurchase.decrypt(
          WinePurchase.convertDate(v),
          this.Encryption.current
        )
      )
    );
  }

  async getWinePurchaseById(id: string): Promise<WinePurchase> {
    const docRef = this.refs.currentRefs.getAssetDocRef<WinePurchase.Encrypted>(
      AssetType.WinePurchases,
      id
    );
    return await CoreFirestore.getDoc(docRef)
      .then(checkAndGetData)
      .then((v) =>
        WinePurchase.decrypt(
          WinePurchase.convertDate(v),
          this.Encryption.current
        )
      );
  }

  async add(
    wineData: OmitKeys<WineCatalogueMinInfo, "labeledVariety">,
    inputId: string,
    newPurchase: WinePurchase.CreateFields,
    purchaseId: string,
    personalRefNo: Optional<string>,
    attachments: Attachment[],
    mainImage?: string
  ) {
    const wineDocId = Wine.checkAndBuildWineId(
      this.refs.currentRefs.userId,
      inputId
    );
    const wineId = wineDocId.split("_")[1];
    wineData.wineId = wineId;
    newPurchase.wineId = wineId;
    const wineRef = this.refs.currentRefs.getAssetDocRef<Wine.Encrypted>(
      AssetType.WineAndSpirits,
      wineDocId
    );
    const purchaseRef =
      this.refs.currentRefs.getAssetDocRef<WinePurchase.Encrypted>(
        AssetType.WinePurchases,
        purchaseId
      );

    await CoreFirestore.runTransaction(async (transaction) => {
      await transaction.get(purchaseRef).then(checkDuplicated);

      let wine = (await transaction.get(wineRef)).data();
      if (wine) {
        const result = Wine.assureVersion(wine);
        if (result === TypeResult.DataOutDated) Wine.handleOutDated();
      } else {
        wine = Wine.defaultStateValue(this.refs.currentRefs.userId, wineDocId);
      }
      const state: WineAggregateState = {
        wine,
        purchases: {},
      };

      const repo = await WineAndSpiritsRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      if (
        newPurchase.bottles.length > 0 &&
        newPurchase.bottles[0].location.locationType == LocationType.MyProperty
      ) {
        await PropertiesRepo.checkActiveAndGet(
          this.refs.currentRefs,
          transaction,
          newPurchase.bottles[0].location.locationId
        );
      }
      const purchase = WinePurchase.fromCreate(
        newPurchase,
        this.refs.currentRefs.userId
      );
      WinePurchase.validateEncryptedObj(purchase, true);
      const encryptedPurchase = await WinePurchase.encrypt(
        purchase,
        this.Encryption.current
      );

      const ar = WineAggregateState.newAggregateRoot(state);
      ar.handle(
        Command.addPurchase(
          this.refs.selfRefs.userId,
          {
            ...wineData,
            labeledVariety: WineCatalogueMinInfo.getVarietal(wineData),
          },
          encryptedPurchase,
          personalRefNo,
          await Attachment.encryptArray(attachments, this.Encryption.current),
          mainImage
        )
      );
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async update(
    inputId: string,
    purchaseId: string,
    updateReq: WinePurchase.UpdateInReq,
    personalRefNo: Optional<string>,
    attachments: Attachment[],
    mainImage?: string
  ) {
    const wineId = Wine.checkAndBuildWineId(
      this.refs.currentRefs.userId,
      inputId
    );
    const wineRef = this.refs.currentRefs.getAssetDocRef<Wine.Encrypted>(
      AssetType.WineAndSpirits,
      wineId
    );
    const purchaseRef =
      this.refs.currentRefs.getAssetDocRef<WinePurchase.Encrypted>(
        AssetType.WinePurchases,
        purchaseId
      );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentWineUndecrypted = await transaction
        .get(wineRef)
        .then(checkAndGetData);
      const result = Wine.assureVersion(currentWineUndecrypted);
      if (result === TypeResult.DataOutDated) Wine.handleOutDated();
      const currentPurchaseUndecrypted = await transaction
        .get(purchaseRef)
        .then(checkAndGetData);
      const pResult = WinePurchase.assureVersion(currentPurchaseUndecrypted);
      if (pResult === TypeResult.DataOutDated) WinePurchase.handleOutDated();

      const { addBottles, location, ...rest } = updateReq;
      const updateObj: WinePurchase.UpdateInCommand = rest;

      if (location) {
        if (location.locationType == LocationType.MyProperty)
          await PropertiesRepo.checkActiveAndGet(
            this.refs.currentRefs,
            transaction,
            location.locationId
          );
        updateObj.location = await LocationInfo.encrypt(
          location,
          this.Encryption.current
        );
      }

      const currentWine = await Wine.decrypt(
        Wine.convertDate(currentWineUndecrypted),
        this.Encryption.current
      );
      const currentPurchase = await WinePurchase.decrypt(
        WinePurchase.convertDate(currentPurchaseUndecrypted),
        this.Encryption.current
      );
      const repo = await WineAndSpiritsRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const newBottles = [];
      if (currentPurchase.bottles.length == 0)
        throw new DataPoisoned("No bottles in purchase");
      if (addBottles) {
        const currentLocation =
          currentPurchase.bottles.find(
            (bottle) => bottle.status !== WineStatus.Consumed
          )?.location || currentPurchase.bottles[0].location;

        const newBottleLocation = location ? location : currentLocation;

        for (let i = 0; i < addBottles; i++) {
          newBottles.push(
            await OneBottleOfWine.encrypt(
              {
                bottleId: CoreFirestore.genAssetId(),
                status: WineStatus.Pending,
                location: newBottleLocation!,
              },
              this.Encryption.current
            )
          );
        }
        updateObj.addBottles = newBottles;
      }

      const ar = WineAggregateState.newAggregateRoot({
        wine: await Wine.encrypt(currentWine, this.Encryption.current),
        purchases: {
          [purchaseId]: await WinePurchase.encrypt(
            currentPurchase,
            this.Encryption.current
          ),
        },
      });
      ar.handle(
        Command.updatePurchase(
          this.refs.selfRefs.userId,
          purchaseId,
          updateObj,
          currentWine,
          personalRefNo,
          attachments.length == 0
            ? null
            : await Attachment.encryptArray(
                attachments,
                this.Encryption.current
              ),
          mainImage ? mainImage : null
        )
      );
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  private doDeletePurchase(
    wineRef: DocumentReference<Wine.Encrypted>,
    ids: string[]
  ) {
    return CoreFirestore.runTransaction(async (transaction) => {
      const currentWine = await transaction.get(wineRef).then(checkAndGetData);
      const result = Wine.assureVersion(currentWine);
      if (result === TypeResult.DataOutDated) Wine.handleOutDated();
      const repo = await WineAndSpiritsRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = WineAggregateState.newAggregateRoot({
        wine: currentWine,
        purchases: {},
      });
      ar.handle(
        Command.deletePurchase(this.refs.selfRefs.userId, ids, currentWine)
      );
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }
  private doDelete(wineRef: DocumentReference<Wine.Encrypted>): AsyncTask {
    return AsyncTask.once(() =>
      CoreFirestore.runTransaction(async (transaction) => {
        const currentWine = await transaction
          .get(wineRef)
          .then(checkAndGetData);
        const result = Wine.assureVersion(currentWine);
        if (result === TypeResult.DataOutDated) Wine.handleOutDated();
        const repo = await WineAndSpiritsRepo.newRepo(
          this.refs.currentRefs,
          transaction
        );

        const ar = WineAggregateState.newAggregateRoot({
          wine: currentWine,
          purchases: {},
        });
        ar.handle(Command.deleteWine(this.refs.selfRefs.userId));
        const events = ar.applyAllChanges();
        await this.handleRelatedAggregates(transaction, ar, true);
        //commit
        repo.manualCommit(ar, events);
      })
    );
  }

  async delete(inputId: string): Promise<IAsyncTaskExecutor> {
    const wineId = Wine.checkAndBuildWineId(
      this.refs.currentRefs.userId,
      inputId
    );
    const wineRef = this.refs.currentRefs.getAssetDocRef<Wine.Encrypted>(
      AssetType.WineAndSpirits,
      wineId
    );

    const preview = await CoreFirestore.getDoc(wineRef).then(checkAndGetData);
    if (preview.purchases.length == 0)
      return new AsyncTaskExecutor([this.doDelete(wineRef)]);
    else {
      const itemIds = preview.purchases.map((v) => v.id);
      const tasks: AsyncTask[] = [];
      while (itemIds.length > 0) {
        const currentItemIds = itemIds.splice(0, DeleteBatchLimit);
        tasks.push(
          AsyncTask.once(() => this.doDeletePurchase(wineRef, currentItemIds))
        );
      }
      return new AsyncTaskExecutor([...tasks, this.doDelete(wineRef)]);
    }
  }

  async deletePurchase(inputId: string, purchaseId: string) {
    const wineId = Wine.checkAndBuildWineId(
      this.refs.currentRefs.userId,
      inputId
    );
    const wineRef = this.refs.currentRefs.getAssetDocRef<Wine.Encrypted>(
      AssetType.WineAndSpirits,
      wineId
    );
    return await this.doDeletePurchase(wineRef, [purchaseId]);
  }

  async getWineReportBuilder(): Promise<WineReportBuilder> {
    const coll = this.refs.currentRefs.getAssetCollectionRef<Wine.Encrypted>(
      AssetType.WineAndSpirits
    );
    const snapshot = await CoreFirestore.getDocsFromCollection(
      coll,
      CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId)
    );
    const wines: WineForReport[] = [];
    snapshot.forEach((doc) => {
      const wine = doc.data();
      wines.push(
        WineForReport.fromWine(
          wine,
          this.exRate.multipleCurrencyValueToBase(wine.value),
          this.exRate
        )
      );
    });

    return new WineReportBuilder(wines, this.exRate.BaseCurrency! as Currency);
  }

  async relocateBottle(
    inputId: string,
    purchaseId: string,
    bottleIds: string[],
    newLocation: LocationInfo
  ) {
    const wineId = Wine.checkAndBuildWineId(
      this.refs.currentRefs.userId,
      inputId
    );
    const wineRef = this.refs.currentRefs.getAssetDocRef<Wine.Encrypted>(
      AssetType.WineAndSpirits,
      wineId
    );
    const purchaseRef =
      this.refs.currentRefs.getAssetDocRef<WinePurchase.Encrypted>(
        AssetType.WinePurchases,
        purchaseId
      );

    await CoreFirestore.runTransaction(async (transaction) => {
      if (newLocation.locationType == LocationType.MyProperty)
        await PropertiesRepo.checkActiveAndGet(
          this.refs.currentRefs,
          transaction,
          newLocation.locationId
        );
      const currentWineUndecrypted = await transaction
        .get(wineRef)
        .then(checkAndGetData);
      const result = Wine.assureVersion(currentWineUndecrypted);
      if (result === TypeResult.DataOutDated) Wine.handleOutDated();
      const currentPurchaseUndecrypted = await transaction
        .get(purchaseRef)
        .then(checkAndGetData);
      const pResult = WinePurchase.assureVersion(currentPurchaseUndecrypted);
      if (pResult === TypeResult.DataOutDated) WinePurchase.handleOutDated();
      const currentWine = await Wine.decrypt(
        Wine.convertDate(currentWineUndecrypted),
        this.Encryption.current
      );
      const currentPurchase = await WinePurchase.decrypt(
        WinePurchase.convertDate(currentPurchaseUndecrypted),
        this.Encryption.current
      );
      const repo = await WineAndSpiritsRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = WineAggregateState.newAggregateRoot({
        wine: await Wine.encrypt(currentWine, this.Encryption.current),
        purchases: {
          [purchaseId]: await WinePurchase.encrypt(
            currentPurchase,
            this.Encryption.current
          ),
        },
      });
      ar.handle(
        Command.relocateBottle(
          this.refs.selfRefs.userId,
          purchaseId,
          bottleIds,
          currentWineUndecrypted,
          await LocationInfo.encrypt(newLocation, this.Encryption.current)
        )
      );
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async removeBottle(
    inputId: string,
    purchaseId: string,
    bottleIds: string[],
    removal: Removal
  ) {
    const wineId = Wine.checkAndBuildWineId(
      this.refs.currentRefs.userId,
      inputId
    );
    const wineRef = this.refs.currentRefs.getAssetDocRef<Wine.Encrypted>(
      AssetType.WineAndSpirits,
      wineId
    );
    const purchaseRef =
      this.refs.currentRefs.getAssetDocRef<WinePurchase.Encrypted>(
        AssetType.WinePurchases,
        purchaseId
      );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentWineUndecrypted = await transaction
        .get(wineRef)
        .then(checkAndGetData);
      const result = Wine.assureVersion(currentWineUndecrypted);
      if (result === TypeResult.DataOutDated) Wine.handleOutDated();
      const currentPurchaseUndecrypted = await transaction
        .get(purchaseRef)
        .then(checkAndGetData);
      const pResult = WinePurchase.assureVersion(currentPurchaseUndecrypted);
      if (pResult === TypeResult.DataOutDated) WinePurchase.handleOutDated();
      const currentWine = await Wine.decrypt(
        Wine.convertDate(currentWineUndecrypted),
        this.Encryption.current
      );
      const currentPurchase = await WinePurchase.decrypt(
        WinePurchase.convertDate(currentPurchaseUndecrypted),
        this.Encryption.current
      );
      const repo = await WineAndSpiritsRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = WineAggregateState.newAggregateRoot({
        wine: await Wine.encrypt(currentWine, this.Encryption.current),
        purchases: {
          [purchaseId]: await WinePurchase.encrypt(
            currentPurchase,
            this.Encryption.current
          ),
        },
      });
      ar.handle(
        Command.removeBottle(
          this.refs.selfRefs.userId,
          purchaseId,
          bottleIds,
          await Removal.encrypt(removal, this.Encryption.current),
          currentWine
        )
      );
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async getActionById(inputId: string, actionId: string): Promise<Action> {
    const wineId = Wine.checkAndBuildWineId(
      this.refs.currentRefs.userId,
      inputId
    );
    const docRef = this.refs.currentRefs.getAssetDocRef<Wine.Encrypted>(
      AssetType.WineAndSpirits,
      wineId
    );
    const actionCollectionRef = actionRefFromAssetDocRef(docRef);
    const actionDocRef = CoreFirestore.docFromCollection(
      actionCollectionRef,
      actionId
    );
    return await CoreFirestore.getDoc(actionDocRef)
      .then(checkAndGetData)
      .then((v) => Action.decryptAndConvertDate(v, this.Encryption.current));
  }

  async getAllActions(inputId: string): Promise<Action[]> {
    const wineId = Wine.checkAndBuildWineId(
      this.refs.currentRefs.userId,
      inputId
    );
    const docRef = this.refs.currentRefs.getAssetDocRef<Wine.Encrypted>(
      AssetType.WineAndSpirits,
      wineId
    );
    return doGetAllActions(
      docRef,
      this.refs.currentRefs.userId,
      this.Encryption.current
    );
  }

  async addTastingNote(
    inputId: string,
    createFields: TastingNote.CreateFields
  ) {
    const wineId = Wine.checkAndBuildWineId(
      this.refs.currentRefs.userId,
      inputId
    );
    const wineRef = this.refs.currentRefs.getAssetDocRef<Wine.Encrypted>(
      AssetType.WineAndSpirits,
      wineId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentWineUndecrypted = await transaction
        .get(wineRef)
        .then(checkAndGetData);
      const result = Wine.assureVersion(currentWineUndecrypted);
      if (result === TypeResult.DataOutDated) Wine.handleOutDated();
      const currentWine = await Wine.decrypt(
        Wine.convertDate(currentWineUndecrypted),
        this.Encryption.current
      );

      const command = await buildAddActionCommand<TastingNoteBundle>(
        wineRef,
        transaction,
        this.refs.currentRefs.userId,
        this.refs.selfRefs.userId,
        createFields,
        this.Encryption.current,
        TastingNote.fromCreate,
        TastingNote.validateEncryptedPart,
        TastingNote.encrypt
      );

      const repo = await WineAndSpiritsRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = WineAggregateState.newAggregateRoot({
        wine: await Wine.encrypt(currentWine, this.Encryption.current),
        purchases: {},
      });
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async updateTastingNote(inputId: string, updateFields: TastingNote.Update) {
    const wineId = Wine.checkAndBuildWineId(
      this.refs.currentRefs.userId,
      inputId
    );
    const wineRef = this.refs.currentRefs.getAssetDocRef<Wine.Encrypted>(
      AssetType.WineAndSpirits,
      wineId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentWineUndecrypted = await transaction
        .get(wineRef)
        .then(checkAndGetData);
      const result = Wine.assureVersion(currentWineUndecrypted);
      if (result === TypeResult.DataOutDated) Wine.handleOutDated();
      const currentWine = await Wine.decrypt(
        Wine.convertDate(currentWineUndecrypted),
        this.Encryption.current
      );

      const { command, action } =
        await buildUpdateActionCommand<TastingNoteBundle>(
          wineRef,
          transaction,
          this.refs.selfRefs.userId,
          updateFields,
          this.Encryption.current,
          ActionType.AddTastingNote,
          TastingNote.encryptedKeysArray,
          TastingNote.validateEncryptedPart,
          TastingNote.encryptPartial,
          TastingNote.decryptAndConvertDate,
          TastingNote.intoUpdate
        );

      const repo = await WineAndSpiritsRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = WineAggregateState.newAggregateRoot(
        {
          wine: await Wine.encrypt(currentWine, this.Encryption.current),
          purchases: {},
        },
        {
          actions: {
            [updateFields.id]: action,
          },
        }
      );
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async deleteTastingNote(inputId: string, actionId: string) {
    const wineId = Wine.checkAndBuildWineId(
      this.refs.currentRefs.userId,
      inputId
    );
    const wineRef = this.refs.currentRefs.getAssetDocRef<Wine.Encrypted>(
      AssetType.WineAndSpirits,
      wineId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentWineUndecrypted = await transaction
        .get(wineRef)
        .then(checkAndGetData);
      const result = Wine.assureVersion(currentWineUndecrypted);
      if (result === TypeResult.DataOutDated) Wine.handleOutDated();
      const currentWine = await Wine.decrypt(
        Wine.convertDate(currentWineUndecrypted),
        this.Encryption.current
      );
      const { command, action } =
        await buildDeleteActionCommand<TastingNoteBundle>(
          wineRef,
          transaction,
          this.refs.selfRefs.userId,
          actionId,
          ActionType.AddTastingNote,
          this.Encryption.current,
          TastingNote.decrypt
        );

      const repo = await WineAndSpiritsRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = WineAggregateState.newAggregateRoot(
        {
          wine: await Wine.encrypt(currentWine, this.Encryption.current),
          purchases: {},
        },
        {
          actions: {
            [actionId]: action,
          },
        }
      );
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }
}

export namespace WineAndSpiritsRepo {
  export async function relocate(
    refs: Refs,
    executerId: string,
    inputId: string,
    purchaseId: string,
    toLocation: LocationInfo.Encrypted,
    // specify one of these
    fromLocationId?: string,
    bottleIds?: string[]
  ) {
    if (!fromLocationId && !bottleIds)
      throw new InvalidInput("Must specify fromLocationId or bottleIds");
    const wineId = Wine.checkAndBuildWineId(refs.userId, inputId);
    const wineRef = refs.getAssetDocRef<Wine.Encrypted>(
      AssetType.WineAndSpirits,
      wineId
    );
    const purchaseRef = refs.getAssetDocRef<WinePurchase.Encrypted>(
      AssetType.WinePurchases,
      purchaseId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentWineUndecrypted = await transaction
        .get(wineRef)
        .then(checkAndGetData);
      const result = Wine.assureVersion(currentWineUndecrypted);
      if (result === TypeResult.DataOutDated) Wine.handleOutDated();
      const currentPurchaseUndecrypted = await transaction
        .get(purchaseRef)
        .then(checkAndGetData);
      const pResult = WinePurchase.assureVersion(currentPurchaseUndecrypted);
      if (pResult === TypeResult.DataOutDated) WinePurchase.handleOutDated();
      if (!bottleIds) {
        bottleIds = [];
        currentPurchaseUndecrypted.bottles.forEach((b) => {
          if (b.location.locationId == fromLocationId)
            bottleIds!.push(b.bottleId);
        });
        if (bottleIds.length == 0)
          throw new DataPoisoned("No bottles to moved from this locationId");
      }
      const repo = await newRepo(refs, transaction);

      const ar = WineAggregateState.newAggregateRoot({
        wine: currentWineUndecrypted,
        purchases: {
          [purchaseId]: currentPurchaseUndecrypted,
        },
      });
      ar.handle(
        Command.relocateBottle(
          executerId,
          purchaseId,
          bottleIds,
          currentWineUndecrypted,
          toLocation
        )
      );
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  export async function removeContactRelation(
    refs: Refs,
    executerId: string,
    inputId: string,
    purchaseId: string,
    contactId: string,
    roles: RoleToAsset[]
  ) {
    const wineId = Wine.checkAndBuildWineId(refs.userId, inputId);
    const wineRef = refs.getAssetDocRef<Wine.Encrypted>(
      AssetType.WineAndSpirits,
      wineId
    );
    const purchaseRef = refs.getAssetDocRef<WinePurchase.Encrypted>(
      AssetType.WinePurchases,
      purchaseId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentWineUndecrypted = await transaction
        .get(wineRef)
        .then(checkAndGetData);
      const result = Wine.assureVersion(currentWineUndecrypted);
      if (result === TypeResult.DataOutDated) Wine.handleOutDated();
      const currentPurchaseUndecrypted = await transaction
        .get(purchaseRef)
        .then(checkAndGetData);
      const pResult = WinePurchase.assureVersion(currentPurchaseUndecrypted);
      if (pResult === TypeResult.DataOutDated) WinePurchase.handleOutDated();
      const updateObj: WinePurchase.UpdateInCommand = {};
      roles.map((role) => {
        switch (role) {
          case RoleToAsset.Seller:
            if (contactId !== currentPurchaseUndecrypted.acquisition?.sellerId)
              throw new DataPoisoned("sellerId mismatch");
            updateObj.acquisition = {
              ...currentPurchaseUndecrypted.acquisition,
              sellerId: null!,
            };
            break;
          case RoleToAsset.Shareholder: {
            if (!currentPurchaseUndecrypted.ownership)
              throw new DataPoisoned("ownership not found");
            const updateShareholder =
              currentPurchaseUndecrypted.ownership.shareholder.filter(
                (s) => s.contactId !== contactId
              );
            if (
              updateShareholder.length ===
              currentPurchaseUndecrypted.ownership.shareholder.length
            )
              throw new DataPoisoned("shareholderId not found");
            updateObj.ownership = {
              myOwnership: currentPurchaseUndecrypted.ownership.myOwnership,
              shareholder: updateShareholder,
            };
            break;
          }
          case RoleToAsset.Beneficiary: {
            if (!currentPurchaseUndecrypted.beneficiary)
              throw new DataPoisoned("beneficiary not found");
            const updateBeneficiary =
              currentPurchaseUndecrypted.beneficiary.filter(
                (s) => s.contactId !== contactId
              );
            if (
              updateBeneficiary.length ===
              currentPurchaseUndecrypted.beneficiary.length
            )
              throw new DataPoisoned("beneficiaryId not found");
            updateObj.beneficiary = updateBeneficiary;
            break;
          }
        }
      });

      const repo = await newRepo(refs, transaction);

      const ar = WineAggregateState.newAggregateRoot({
        wine: currentWineUndecrypted,
        purchases: {
          [purchaseId]: currentPurchaseUndecrypted,
        },
      });
      ar.handle(
        Command.updatePurchase(
          executerId,
          purchaseId,
          updateObj,
          undefined,
          undefined,
          undefined,
          undefined
        )
      );
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  export async function newRepo(refs: Refs, transaction: Transaction) {
    return newAggregateRepo(
      transaction,
      refs.userId,
      AssetType.WineAndSpirits,
      new WineStateWriter(
        refs.getAssetCollectionRef(AssetType.WineAndSpirits),
        refs.getAssetCollectionRef(AssetType.WinePurchases),
        refs.Relations
      )
    );
  }
}
