import { AssetType } from "../enums";
import {
  IsAmount,
  EncryptableObject,
  NotEncrypted,
  Managed,
  Notes,
  IsReferenceId,
  IsLongString,
  IsShortString,
  IsBoolean,
  IsEnum,
  IsInt,
  IsOptional,
  IsDate,
  SimpleUpdate,
  IsOptionalOnUpdate,
  Min,
  IsString,
  ArrayUnique,
  IsArray,
} from "../../decorators";
import type { IAggregateData, Amount, MeasurementUnits } from "../common";
import { Attachment } from "./attachment";
import { DatabaseDocument } from "./databaseDocument";
import {
  OwnerAccess,
  ReadOnlyDelegateAccess,
  UpdateNotAllowed,
} from "../../decorators/accessRules";
import { PermissionCategory } from "../../refPaths";

@OwnerAccess
@ReadOnlyDelegateAccess(PermissionCategory.Insurance)
export class AssetWithoutValue
  extends DatabaseDocument
  implements IAggregateData
{
  @NotEncrypted
  @Managed
  @IsOptionalOnUpdate()
  @IsInt()
  version!: number;

  @NotEncrypted
  @UpdateNotAllowed
  @IsEnum(AssetType)
  assetType!: AssetType;

  @NotEncrypted
  @IsShortString()
  subtype!: string;

  @NotEncrypted
  @SimpleUpdate
  @IsOptionalOnUpdate()
  @IsShortString()
  name!: string;

  @NotEncrypted
  @Managed
  @IsReferenceId()
  @Notes("Reference to valuation")
  @IsOptional()
  valueSourceId?: string;

  @Notes("Asset sold will trigger this state")
  @IsShortString()
  @IsOptional()
  @NotEncrypted
  closedWith?: string;

  @NotEncrypted
  @IsBoolean()
  @Notes("Asset sold will trigger this state")
  @IsOptional()
  readonly?: boolean;

  @NotEncrypted
  @IsBoolean()
  @IsOptional()
  archived?: boolean;

  @NotEncrypted
  @SimpleUpdate
  @IsShortString()
  @IsOptional()
  mainImage?: string;

  @EncryptableObject(Attachment)
  @IsOptional()
  @IsArray()
  @ArrayUnique<Attachment>((a) => a.key, {
    message: "Attachment key should be unique",
  })
  attachments?: Attachment[];

  @NotEncrypted
  @IsReferenceId({ each: true })
  @IsArray()
  @IsOptional()
  groupIds?: string[];

  @SimpleUpdate
  @IsLongString()
  @IsOptional()
  notes?: string;

  //#NOTE: We may need to check the firebase servertimestamp format
  @NotEncrypted
  @IsOptional()
  @IsDate()
  // NOTE managed by triggers in `functions`, do not modify this field in other places
  indexedAt?: Date;

  @NotEncrypted
  @IsOptional()
  @IsDate()
  // NOTE managed by triggers in `functions`, do not modify this field in other places
  indexVersion?: number;
}
@OwnerAccess
@ReadOnlyDelegateAccess(PermissionCategory.Insurance)
export class Asset extends AssetWithoutValue {
  @NotEncrypted
  @IsAmount()
  @IsOptionalOnUpdate()
  value!: Amount;
}

export class AssetV2WithCollectableAttributesRefactored extends Asset {
  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  measurementUnits?: MeasurementUnits;

  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  @Min(0, { message: "Height can't be negative" })
  height?: number;

  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  @Min(0, { message: "Width can't be negative" })
  width?: number;

  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  @Min(0, { message: "Depth can't be negative" })
  depth?: number;

  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  @Min(0, { message: "Weight can't be negative" })
  weight?: number;

  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  @IsString()
  dimensionsSummary?: string;

  @SimpleUpdate
  @IsOptional()
  @IsLongString()
  attributeNotes?: string;

  @NotEncrypted
  @IsOptional()
  @IsDate()
  creationCompletionYear?: Date;

  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  @IsString()
  edition?: string;

  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  @IsString()
  provenance?: string;

  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  @IsString()
  geography?: string;

  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  @IsString()
  medium?: string;

  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  @IsString()
  inscription?: string;

  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  @IsString()
  description?: string;

  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  @IsString()
  catalogueText?: string;

  @NotEncrypted
  @IsOptional()
  @IsDate()
  dateExecuted?: Date;
}
