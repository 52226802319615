import * as React from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { cn } from '@/utils/classnames'
import { formatNumber } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import { PrivacyField } from '@/components/PrivacyField'

export const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn('overflow-hidden rounded bg-white', className)} {...props} />
  }
)
Card.displayName = 'Card'

export const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn('border-b border-grey/50 px-4 py-2', className)} {...props} />
  }
)
CardHeader.displayName = 'CardHeader'

export const CardTitle = React.forwardRef<HTMLHeadingElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => {
    return <h3 ref={ref} className={cn('text-lg text-text', className)} {...props} />
  }
)
CardTitle.displayName = 'CardTitle'

export const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn('p-4', className)} {...props} />
  }
)
CardContent.displayName = 'CardContent'

interface CardSummaryProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  data: {
    assets?: number
    liabilities?: number
    netValue?: number
  }
  unit?: string
  isAssociatedLiabilities?: boolean
}

export const CardSummary = React.forwardRef<HTMLDivElement, CardSummaryProps>(
  ({ title, data, unit = 'USD', isAssociatedLiabilities = false }, ref) => {
    const { t } = useTranslation()
    const database = useAuthStore((state) => state.database)
    const { data: preferences = defaultPreferences } = useSWR(
      [userQuery.currentPreferences],
      fetchCurrentPreferences(database!)
    )

    return (
      <CardHeader ref={ref} className={'flex items-center bg-grey/20'}>
        <div className={'flex flex-1 flex-col sm:flex-row'}>
          <div className={'mb-2 flex-1 sm:my-auto'}>
            <CardTitle className={'text-md text-center font-medium uppercase text-text sm:text-left sm:text-sm '}>
              {title}
            </CardTitle>
          </div>
          <div className={'flex flex-1 flex-col items-center text-text sm:flex-row sm:justify-end'}>
            {data.assets !== undefined && (
              <div
                className={
                  'w-full border-t border-grey/50 px-4 py-2 sm:w-auto sm:basis-1/3 sm:border-l sm:border-t-0 sm:py-0'
                }
              >
                <p className={'text-xs uppercase'}>{t('Assets')}</p>
                <PrivacyField className={'text-sm font-bold'}>
                  {`${unit} ${formatNumber(data.assets, preferences.numberFormat, { digits: 0 })}`}
                </PrivacyField>
              </div>
            )}
            {data.liabilities !== undefined && (
              <div
                className={'w-full border-t border-grey/50 px-4 py-2 sm:w-auto sm:basis-1/3 sm:border-l sm:border-t-0'}
              >
                <p className={'text-xs uppercase'}>
                  {isAssociatedLiabilities ? t('AssociatedLiabilities') : t('Liabilities')}
                </p>
                <span className={cn(data.liabilities < 0 && 'text-error')}>
                  <PrivacyField className={'text-sm font-bold'}>
                    {`${unit} ${formatNumber(data.liabilities, preferences.numberFormat, { digits: 0 })}`}
                  </PrivacyField>
                </span>
              </div>
            )}
            {data.netValue !== undefined && (
              <div className={'w-full border-t border-grey/50 px-4 py-2 sm:w-auto sm:border-l sm:border-t-0'}>
                <p className={'text-xs uppercase'}>{t('NetValue')}</p>
                <span className={cn(data.netValue < 0 && 'text-error')}>
                  <PrivacyField className={'text-sm font-bold'}>
                    {`${unit} ${formatNumber(data.netValue, preferences.numberFormat, { digits: 0 })}`}
                  </PrivacyField>
                </span>
              </div>
            )}
          </div>
        </div>
      </CardHeader>
    )
  }
)
CardSummary.displayName = 'CardSummary'
