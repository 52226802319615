import { useCallback, useEffect, useMemo, useRef, useState, type CSSProperties } from 'react'
import OneSchemaImporter, { type OneSchemaImporterProps } from '@oneschema/react'
import { parse } from 'date-fns'
import xlsx from 'export-json-as-xlsx'
import { Loader2Icon, XCircleIcon } from 'lucide-react'
import { Trans, useTranslation } from 'react-i18next'
import { mutate } from 'swr'
import { create } from 'zustand'

import { Art, artMediumValues, ArtStyle, artStyleValues, ArtType, artTypeValues } from 'core/remodel/types/arts'
import {
  BelongingDraft,
  BelongingsUtils,
  belongingTypeValues,
  otherCollectableTypeValues
} from 'core/remodel/types/belongings'
import { LocationType, type Currency, type MeasurementUnits } from 'core/remodel/types/common'
import { type AcquisitionType } from 'core/remodel/types/common/acquisition'
import { AssetType, CustomizedType } from 'core/remodel/types/enums'
import { BottleSize, WinePricingMethod, WinePurchase, WineStatus } from 'core/remodel/types/wineAndSprits'
import { artQuery } from '@/api/ArtService'
import { belongingQuery } from '@/api/BelongingService'
import { commonQuery, fetchOneSchemaToken } from '@/api/CommonService'
import { addContact, contactQuery, fetchContactOptions } from '@/api/ContactService'
import { otherQuery } from '@/api/OtherCollectableService'
import { fetchPropertyOptions, propertyQuery } from '@/api/PropertyService'
import { templateColumnsMap } from '@/constants/oneschema'
import { isMatchedEnv } from '@/constants/site'
import {
  templateKeyMap,
  type ArtRecord,
  type BelongingRecord,
  type OtherCollectableRecord,
  type Result,
  type Template,
  type TemplateRecord,
  type WineRecord
} from '@/types/oneSchema'
import { cn } from '@/utils/classnames'
import { delay } from '@/utils/delay'
import { formatDate } from '@/utils/formatter'
import { parseErrorMessage } from '@/utils/parseErrorMessages'
import { usePagination } from '@/hooks/usePagination'
import { useToast } from '@/hooks/useToast'
import { useAuthStore } from '@/store/authStore'
import { Button, Modal, Progress, Separator, Tooltip, TooltipContent, TooltipTrigger } from '@/components/base'
import Confirm from '@/components/Confirm'
import { DownloadIcon, ImportIcon, PlusIcon, UploadIcon } from '@/components/icon'

const clientId = process.env.NEXT_PUBLIC_ONE_SCHEMA_CLIENT_ID
const devMode = process.env.NODE_ENV !== 'production'

type ImporterState = {
  token: string | null
  isLoading: boolean
  template: Template | null
  setToken: (token: string | null) => void
  setIsLoading: (isLoading: boolean) => void
  setTemplate: (template: Template | null) => void
}

export const useImporterStore = create<ImporterState>((set) => ({
  token: null,
  isLoading: false,
  template: null,
  setToken: (token: string | null) => set({ token }),
  setIsLoading: (isLoading: boolean) => set({ isLoading }),
  setTemplate: (template: Template | null) => set({ template })
}))

const importerStyles: CSSProperties = {
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  zIndex: 9999
}
const importConfig = { type: 'local' } as const

interface DataImporterProps {
  assetType: Template
  className?: string
  disabled?: boolean
}

const comingSoon = isMatchedEnv(['prod'])

// Workflow: Trigger (Button) -> ImporterModal (OneSchema)
export function DataImporterTrigger({ className, assetType, disabled }: DataImporterProps) {
  const { t } = useTranslation()
  const { token, isLoading, setTemplate } = useImporterStore((state) => state)
  const triggerDisabled = disabled || token !== null || isLoading

  return (
    <Tooltip>
      <TooltipTrigger asChild={true}>
        <Button
          className={cn('h-9 w-9 text-white', className)}
          variant={comingSoon ? 'unstyled' : 'solid'}
          onClick={comingSoon ? undefined : () => setTemplate(assetType)}
          disabled={triggerDisabled}
          aria-label={'Import data'}
          data-testid={'import-data-button'}
        >
          <ImportIcon />
        </Button>
      </TooltipTrigger>
      <TooltipContent>{comingSoon ? t('BulkImportComingSoon') : t('Import')}</TooltipContent>
    </Tooltip>
  )
}

// Workflow: Dialog (Modal with 'download' button) => ImporterModal => DataMapper & ImportUploading (transforming data & upload into database)
export function DataImporterModal() {
  const { toast } = useToast()
  const { i18n } = useTranslation()
  const [records, setRecords] = useState<TemplateRecord[] | null>(null)
  const { token, template, setToken, setTemplate, setIsLoading } = useImporterStore((state) => state)

  const hasRecords = records && records.length > 0

  const handleDialogClose = () => setTemplate(null)

  const handleImporterClose = () => setToken(null)

  const handleMapperClose = () => {
    setIsLoading(false)
    setTemplate(null)
    setRecords(null)
  }

  const handleSuccess = (result: Result) => {
    setIsLoading(true)
    setRecords(result.records)
  }

  const handleError: OneSchemaImporterProps['onError'] = (error) => {
    toast({ variant: 'error', description: error.message })
  }

  return template ? (
    <>
      <DataImporterDialog template={template} onClose={handleDialogClose} />
      {token && clientId && (
        <OneSchemaImporter
          isOpen={true}
          userJwt={token}
          clientId={clientId}
          templateKey={templateKeyMap[template]}
          importConfig={importConfig}
          style={importerStyles}
          devMode={devMode}
          onSuccess={handleSuccess}
          onError={handleError}
          onRequestClose={handleImporterClose}
          languageCode={i18n.language}
        />
      )}
      {hasRecords && <DataMapper template={template} records={records} onClose={handleMapperClose} />}
    </>
  ) : null
}

interface DataImporterDialogProps {
  template: Template
  onClose: () => void
}

function DataImporterDialog({ template, onClose }: DataImporterDialogProps) {
  const { toast } = useToast()
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { isLoading, setToken, setIsLoading } = useImporterStore((state) => state)

  const handleOpenImporter = useCallback(async () => {
    try {
      setIsLoading(true)
      const oneSchemaToken = await fetchOneSchemaToken(database!)([commonQuery.oneSchemaToken])
      setToken(oneSchemaToken)
    } catch (e) {
      const errorMessage = parseErrorMessage(e, 'Unknown error: Import Data')
      toast({ variant: 'error', description: errorMessage })
    } finally {
      await delay()
      setIsLoading(false)
    }
  }, [database, setIsLoading, setToken, toast])

  const handleDownloadTemplate = useCallback(async () => {
    const templateKey = templateKeyMap[template]
    const { name = 'Asset type not found', columns = [] } =
      templateColumnsMap.find((temp) => temp.template_key === templateKey) ?? {}
    // 9 rows of empty columns
    const content = Array(9).fill(Object.fromEntries(Object.keys(columns).map((key) => [key, ''])))
    const excelColumns = Object.entries(columns).map(([value, label]) => ({
      label,
      value
    }))
    const xlsData = [
      {
        columns: excelColumns,
        content
      }
    ]
    const settings = {
      fileName: `${name.toLowerCase().replace(/\W|_/g, '-')}-template`,
      writeMode: 'writeFile'
    }

    // download
    xlsx(xlsData, settings)
  }, [template])

  return (
    <Modal onBackdropClick={onClose}>
      <Modal.Header className={'bg-primary'}>
        <label className={'text-sm font-medium uppercase text-white'}>{t('DataImport')}</label>
        <Modal.CloseButton className={'text-white'} onClose={onClose} />
      </Modal.Header>
      <Modal.Content className={'text-center text-[#424553]'}>
        <div className={'flex flex-col gap-4'}>
          <p className={'text-base/5 font-semibold'}>{t('ImportTitle')}</p>
          <Trans
            i18nKey={`ImportContent`}
            parent={(props: any) => <p className={'whitespace-pre-line text-sm'} {...props} />}
            components={{
              bold: <p className={'font-semibold'} />
            }}
          />
          <div className={'flex items-center justify-center gap-2.5 max-md:flex-col'}>
            <Button
              className={'w-full max-w-[200px] whitespace-nowrap text-base/5'}
              variant={'solid'}
              size={'md'}
              onClick={handleDownloadTemplate}
            >
              <DownloadIcon className={'shrink-0'} size={20} />
              {t('DownloadTemplate')}
            </Button>
            <Button
              className={'group w-full max-w-[200px] whitespace-nowrap text-base/5'}
              variant={'solid'}
              size={'md'}
              onClick={handleOpenImporter}
              disabled={isLoading}
            >
              <>
                <Loader2Icon className={'absolute animate-spin opacity-0 group-disabled:opacity-100'} />
                <span className={'group-disabled:opacity-0'}>{t('ContinueToUpload')}</span>
              </>
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

// should use `Mapping` to wait user select wine
type Status = 'Creating' | 'Mapping' | 'Uploading' | 'Completed' | 'Failed'

interface DataMapperProps {
  template: Template
  records: TemplateRecord[]
  onClose: () => void
}

function DataMapper({ template, records, onClose }: DataMapperProps) {
  const { page: _, ...params } = usePagination(['brand', 'subType', 'artStyle', 'locationId'])
  const query = useMemo(() => ({ sort: 'updatedAt', order: 'desc', ...params }), [params])

  const { toast } = useToast()
  const { t } = useTranslation()
  const cancelRef = useRef(false)
  const uploadedRef = useRef(false)
  const [done, setDone] = useState<number>(0)
  const [status, setStatus] = useState<Status>('Uploading')
  const [isMinimized, setIsMinimized] = useState<boolean>(false)
  const database = useAuthStore((state) => state.database)
  const progress = (done * 100) / records.length

  const createCustomizedTypeAdder = useCallback(
    async (type: CustomizedType) => {
      const customizedTypes = await database!.listCustomizedType(type)
      const existingTypes = new Set(customizedTypes)
      return async (newTypeName: string) => {
        if (!existingTypes.has(newTypeName)) {
          await database!.addCustomizedType(type, newTypeName)
          existingTypes.add(newTypeName)
        }
      }
    },
    [database]
  )

  const uploadData = useCallback(
    async (records: TemplateRecord[], index = 0) => {
      const sliced = records.slice(index)
      const propertyOptions = await fetchPropertyOptions(database!)([propertyQuery.options])
      const contactOptions = await fetchContactOptions(database!)([contactQuery.options])

      switch (template) {
        case AssetType.Art: {
          const results = sliced.map((r) => toArt(r as ArtRecord))
          const labelToArtTypeKeyMap = Object.fromEntries(
            artTypeValues.map((key) => [t(`collectables:ArtTypeOptions.${key}`), key])
          )
          const labelToArtStyleKeyMap = Object.fromEntries(
            artStyleValues.map((key) => [t(`collectables:ArtStyleOptions.${key}`), key])
          )
          const labelToArtMediumKeyMap = Object.fromEntries(
            artMediumValues.map((key) => [t(`collectables:ArtMediumOptions.${key}`), key])
          )
          const addArtistName = await createCustomizedTypeAdder(CustomizedType.ArtArtistName)
          const addArtMedium = await createCustomizedTypeAdder(CustomizedType.ArtMedium)
          const draftList: Art.Draft['req'][] = []

          const sellers = Array.from(new Set(results.map((result) => result.acquisition?.sellerId))).filter(
            (seller) => (seller?.trim().length ?? 0) > 0
          )
          const sellerOptions = await Promise.all(
            sellers.map(async (seller) => {
              let sellerId = contactOptions.find((option) => [option.label, option.value].includes(seller ?? ''))?.value
              if (!sellerId) {
                sellerId = await addContact(database!, { firstName: seller })
              }
              return { label: seller, value: sellerId }
            })
          )

          for (const result of results) {
            if (cancelRef.current) return
            const subtype = labelToArtTypeKeyMap[result.subtype] ?? ArtType.Architecture
            const artStyle = labelToArtStyleKeyMap[result.artStyle ?? ''] ?? ArtStyle.AbstractExpressionism
            const isCustomMedium = !labelToArtMediumKeyMap.hasOwnProperty(result.medium ?? '') && !!result.medium

            let locationType = LocationType.NewAddress
            let locationId =
              propertyOptions.find((option) => [option.label, option.value].includes(result.location.locationId))
                ?.value ?? ''
            if (locationId !== '') {
              locationType = LocationType.MyProperty
            } else {
              locationId =
                contactOptions.find((option) => [option.label, option.value].includes(result.location.locationId))
                  ?.value ?? ''
              if (locationId !== '') {
                locationType = LocationType.Address
              }
            }

            const sellerId =
              (result.acquisition?.sellerId?.length ?? 0) > 0
                ? sellerOptions.find((option) => [option.label, option.value].includes(result.acquisition?.sellerId))
                    ?.value ?? ''
                : undefined
            const acquisition = result.acquisition ? { ...result.acquisition, sellerId } : undefined

            const art: Art.CreateFields = {
              ...result,
              id: database!.genAssetId(),
              groupIds: [],
              subtype,
              artStyle,
              location: {
                ...result.location,
                locationType,
                locationId
              },
              acquisition
            }
            if (locationType === LocationType.NewAddress) {
              const { location, ...artDraft } = art
              draftList.push(artDraft)
            } else {
              await database!.art.add(art)
              setDone((num) => num + 1)
            }
            await Promise.all([addArtistName(result.artist), isCustomMedium && addArtMedium(result.medium ?? 'Other')])
          }
          if (draftList.length > 0) {
            const id = database!.genAssetId()
            const group = {
              id,
              name: `Bulk Import ${draftList.length} assets ${formatDate(new Date(), 'dd/MM/yyyy HH:mm')}`,
              isDraft: true
            }
            await database!.group.add(group, [])
            for (const draft of draftList) {
              await database!.art.addDraft(draft, id)
              setDone((num) => num + 1)
            }
          }
          await delay()
          mutate([artQuery.list, query])
          break
        }
        case AssetType.OtherCollectables: {
          const results = sliced.map((r) => toOtherCollectable(r as OtherCollectableRecord))
          const addOtherCollectablesModel = await createCustomizedTypeAdder(CustomizedType.OtherCollectablesModel)
          const addOtherCollectablesBrand = await createCustomizedTypeAdder(CustomizedType.OtherCollectablesBrand)
          const addOtherCollectablesType = await createCustomizedTypeAdder(CustomizedType.OtherCollectablesType)
          const labelToOtherCollectableTypeKeyMap = Object.fromEntries(
            otherCollectableTypeValues.map((key) => [t(`collectables:OtherCollectableTypeOptions.${key}`), key])
          )
          const draftList: BelongingDraft['req'][] = []

          const sellers = Array.from(new Set(results.map((result) => result.acquisition?.sellerId))).filter(
            (seller) => (seller?.trim().length ?? 0) > 0
          )
          const sellerOptions = await Promise.all(
            sellers.map(async (seller) => {
              let sellerId = contactOptions.find((option) => [option.label, option.value].includes(seller ?? ''))?.value
              if (!sellerId) {
                sellerId = await addContact(database!, { firstName: seller })
              }
              return { label: seller, value: sellerId }
            })
          )

          for (const result of results) {
            if (cancelRef.current) return
            const isCustomSubType = !labelToOtherCollectableTypeKeyMap.hasOwnProperty(result.subtype)
            const subtype = isCustomSubType ? result.subtype : labelToOtherCollectableTypeKeyMap[result.subtype]

            let locationType = LocationType.NewAddress
            let locationId =
              propertyOptions.find((option) => [option.label, option.value].includes(result.location.locationId))
                ?.value ?? ''
            if (locationId !== '') {
              locationType = LocationType.MyProperty
            } else {
              locationId =
                contactOptions.find((option) => [option.label, option.value].includes(result.location.locationId))
                  ?.value ?? ''
              if (locationId !== '') {
                locationType = LocationType.Address
              }
            }

            const sellerId =
              (result.acquisition?.sellerId?.length ?? 0) > 0
                ? sellerOptions.find((option) => [option.label, option.value].includes(result.acquisition?.sellerId))
                    ?.value ?? ''
                : undefined
            const acquisition = result.acquisition ? { ...result.acquisition, sellerId } : undefined

            const other: BelongingsUtils.CreateFields = {
              ...result,
              id: database!.genAssetId(),
              groupIds: [],
              subtype,
              location: {
                ...result.location,
                locationType,
                locationId
              },
              acquisition
            }
            await Promise.all([
              addOtherCollectablesModel(result.name),
              addOtherCollectablesBrand(result.brand),
              isCustomSubType && addOtherCollectablesType(subtype)
            ])
            if (locationType === LocationType.NewAddress) {
              const { location, ...otherDraft } = other
              draftList.push(otherDraft)
            } else {
              await database!.otherCollectable.add(other)
              setDone((num) => num + 1)
            }
          }
          if (draftList.length > 0) {
            const id = database!.genAssetId()
            const group = {
              id,
              name: `Bulk Import ${draftList.length} assets ${formatDate(new Date(), 'dd/MM/yyyy HH:mm')}`,
              isDraft: true
            }
            await database!.group.add(group, [])
            for (const draft of draftList) {
              await database!.otherCollectable.addDraft(draft, id)
              setDone((num) => num + 1)
            }
          }

          await delay()
          mutate([otherQuery.list, query])
          break
        }
        case AssetType.Belonging: {
          const results = sliced.map((r) => toBelonging(r as BelongingRecord))
          const addBelongingType = await createCustomizedTypeAdder(CustomizedType.BelongingType)
          const addBelongingBrand = await createCustomizedTypeAdder(CustomizedType.BelongingBrand)
          const labelToBelongingTypeKeyMap = Object.fromEntries(
            belongingTypeValues.map((key) => [t(`collectables:BelongingTypeOptions.${key}`), key])
          )
          const draftList: BelongingDraft['req'][] = []

          const sellers = Array.from(new Set(results.map((result) => result.acquisition?.sellerId))).filter(
            (seller) => (seller?.trim().length ?? 0) > 0
          )
          const sellerOptions = await Promise.all(
            sellers.map(async (seller) => {
              let sellerId = contactOptions.find((option) => [option.label, option.value].includes(seller ?? ''))?.value
              if (!sellerId) {
                sellerId = await addContact(database!, { firstName: seller })
              }
              return { label: seller, value: sellerId }
            })
          )

          for (const result of results) {
            if (cancelRef.current) return
            const isCustomSubType = !labelToBelongingTypeKeyMap.hasOwnProperty(result.subtype)
            const subtype = isCustomSubType ? result.subtype : labelToBelongingTypeKeyMap[result.subtype]

            let locationType = LocationType.NewAddress
            let locationId =
              propertyOptions.find((option) => [option.label, option.value].includes(result.location.locationId))
                ?.value ?? ''
            if (locationId !== '') {
              locationType = LocationType.MyProperty
            } else {
              locationId =
                contactOptions.find((option) => [option.label, option.value].includes(result.location.locationId))
                  ?.value ?? ''
              if (locationId !== '') {
                locationType = LocationType.Address
              }
            }

            const sellerId =
              (result.acquisition?.sellerId?.length ?? 0) > 0
                ? sellerOptions.find((option) => [option.label, option.value].includes(result.acquisition?.sellerId))
                    ?.value ?? ''
                : undefined
            const acquisition = result.acquisition ? { ...result.acquisition, sellerId } : undefined

            const belonging: BelongingsUtils.CreateFields = {
              ...result,
              id: database!.genAssetId(),
              groupIds: [],
              subtype,
              location: {
                ...result.location,
                locationType,
                locationId
              },
              acquisition
            }
            await Promise.all([isCustomSubType && addBelongingType(subtype), addBelongingBrand(result.brand)])
            if (locationType === LocationType.NewAddress) {
              const { location, ...belongingDraft } = belonging
              draftList.push(belongingDraft)
            } else {
              await database!.belonging.add(belonging)
              setDone((num) => num + 1)
            }
          }
          if (draftList.length > 0) {
            const id = database!.genAssetId()
            const group = {
              id,
              name: `Bulk Import ${draftList.length} assets ${formatDate(new Date(), 'dd/MM/yyyy HH:mm')}`,
              isDraft: true
            }
            await database!.group.add(group, [])
            for (const draft of draftList) {
              await database!.belonging.addDraft(draft, id)
              setDone((num) => num + 1)
            }
          }
          await delay()
          mutate([belongingQuery.list, query])
          break
        }
        case AssetType.WineAndSpirits:
          const results = sliced.map((r) => toWine(r as WineRecord))
          for (let i = 0; i < results.length; i++) {
            if (cancelRef.current) return
            const wine: WinePurchase.CreateFields = {
              ...results[i],
              id: database!.genAssetId(),
              bottles: results[i].bottles.map((bottle) => ({ ...bottle, bottleId: database!.genAssetId() }))
            }
            const wineInfo = await database?.getWinesCatalogues({ id: results[i].wineId })
            const purchaseId = database!.genAssetId()
            const personalRefNo = ''
            await database?.wine.add(wineInfo![0], wine.wineId, wine, purchaseId, personalRefNo, [])
            setDone((num) => num + 1)
          }
          break
      }
    },
    [database, template, t, createCustomizedTypeAdder, query]
  )

  const handleUpload = async (index = 0) => {
    try {
      setStatus('Uploading')
      await uploadData(records, index)
      toast({ variant: 'success', description: 'Upload successfully!' })
      setStatus('Completed')
    } catch (e) {
      const errorMessage = parseErrorMessage(e, 'Unknown error: Upload Data')
      toast({ variant: 'error', description: errorMessage })
      setStatus('Failed')
    }
  }

  const handleReload = async () => {
    await handleUpload(done)
  }

  const handleClose = useCallback(() => {
    cancelRef.current = true
    setDone(0)
    setStatus('Uploading')
    setIsMinimized(false)
    onClose()
  }, [onClose])

  useEffect(() => {
    const upload = async () => {
      if (uploadedRef.current) return
      uploadedRef.current = true

      try {
        await uploadData(records)
        toast({ variant: 'success', description: 'Upload successfully!' })
        setStatus('Completed')
      } catch (e) {
        const errorMessage = parseErrorMessage(e, 'Unknown error: Upload Data')
        toast({ variant: 'error', description: errorMessage })
        setStatus('Failed')
      }
    }

    if (status === 'Uploading') {
      upload()
    } else {
      handleClose()
    }
  }, [records, status, uploadData, toast, handleClose])

  return (
    <>
      {status === 'Uploading' && (
        <ImportUploading
          progress={progress}
          onClose={handleClose}
          isMinimized={isMinimized}
          setIsMinimized={setIsMinimized}
        />
      )}
      {status === 'Failed' && <ImportFailed onReload={handleReload} onClose={handleClose} />}
    </>
  )
}

interface ImportUploadingProps {
  progress: number
  isMinimized: boolean
  onClose: () => void
  setIsMinimized: (isMinimized: boolean) => void
}

function ImportUploading({ progress, isMinimized, onClose, setIsMinimized }: ImportUploadingProps) {
  const { t } = useTranslation()
  const [isEscape, setIsEscape] = useState(false)

  return isMinimized ? (
    <div className={cn('fixed bottom-6 right-6 z-50 grid w-[420px] overflow-y-auto rounded-md bg-white shadow-xl')}>
      <Button
        className={cn('absolute right-3 top-3 rounded-full p-px text-primary hover:bg-grey/25')}
        onClick={() => setIsMinimized(false)}
      >
        <PlusIcon size={20} />
      </Button>
      <div className={'flex flex-col items-center justify-center gap-4 p-4'}>
        <p>{t('ImportingData')}</p>
        <Progress className={'h-3'} value={progress} />
      </div>
    </div>
  ) : (
    <>
      <Modal className={'max-w-[640px] rounded'}>
        <Modal.Header className={'border-b'}>
          <div className={'flex items-center gap-x-2 text-text'}>
            <UploadIcon />
            <label className={'text-sm font-medium'}>{t('DataImport')}</label>
          </div>
          <Button onClick={() => setIsMinimized(true)}>
            <div className={'h-6 w-6 rounded-full text-2xl/6 text-text hover:bg-grey/25'}>{'—'}</div>
          </Button>
        </Modal.Header>
        <Modal.Content>
          <div className={'flex h-[572px] flex-col items-center justify-center gap-y-2'}>
            <div className={'flex flex-col gap-y-6'}>
              <div className={'space-y-2 text-center text-sm font-medium'}>
                <p>{t('ValidateData')}</p>
                <p>{t('DoNotCloseWindow')}</p>
              </div>
              <div className={'relative flex items-center gap-x-2'}>
                <Progress className={'h-3'} value={progress} />
                <span className={'absolute -right-8 text-xs text-primary'}>{`${progress.toFixed()}%`}</span>
              </div>
            </div>
          </div>
          <div className={'flex items-center justify-end gap-x-2 p-4'}>
            <Button className={'w-[140px]'} variant={'solid'} size={'md'} onClick={() => setIsEscape(true)}>
              {t('Cancel')}
            </Button>
          </div>
        </Modal.Content>
      </Modal>

      {isEscape && (
        <Confirm
          title={t('CancelImport')}
          content={t('ConfirmCancelImport')}
          onCancel={() => setIsEscape(false)}
          onConfirm={onClose}
          cancelLabel={t('Dismiss')}
          confirmLabel={t('Confirm')}
        />
      )}
    </>
  )
}

interface ImportFailedProps {
  onReload: () => Promise<void>
  onClose: () => void
}

function ImportFailed({ onReload, onClose }: ImportFailedProps) {
  const { t } = useTranslation()
  return (
    <Modal className={'max-w-[640px] rounded'}>
      <Modal.Header className={'border-b'}>
        <div className={'flex items-center gap-x-2 text-text'}>
          <UploadIcon />
          <label className={'text-sm font-medium'}>{t('DataImport')}</label>
        </div>
        <Modal.CloseButton className={'text-text'} onClose={onClose} />
      </Modal.Header>
      <Modal.Content className={'gap-0 p-0'}>
        <div className={'flex h-[500px] flex-col items-center justify-center gap-y-6 p-6'}>
          <XCircleIcon className={'text-[#C3626E]'} size={80} />
          <div className={'space-y-2 text-center text-text'}>
            <p className={'text-lg font-bold'}>{t('ImportFailed')}</p>
            <p className={'text-sm'}>{t('ImportFailedContent')}</p>
          </div>
        </div>
        <Separator />
        <div className={'flex items-center justify-end gap-x-2 p-4'}>
          <Button className={'w-[140px]'} variant={'outline'} size={'md'} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button className={'w-[140px]'} variant={'solid'} size={'md'} onClick={onReload}>
            {t('Reload')}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

function toArt(record: ArtRecord) {
  const art: Omit<Art.CreateFields, 'id' | 'groupIds'> = {
    name: record.art_title_,
    assetType: AssetType.Art,
    artist: record.artist_name_,
    subtype: record.art_type_ as ArtType,
    artStyle: record.art_style as ArtStyle,
    location: {
      locationType: record.location_type === 'My Property' ? LocationType.MyProperty : LocationType.Address,
      locationId: record.location_of_asset,
      roomId: record.room,
      position: record.position,
      notes: record.location_notes
    },
    personalRefNo: record.personal_reference_number,
    purchaseDate: parse(record.purchase_date, 'dd/MM/yyyy', new Date()),
    // HACK
    purchasePrice: {
      currency: record.currency_ as Currency,
      value: Number(record.purchase_price_)
    },
    price: {
      currency: record.currency_ as Currency,
      value: Number(record.item_price)
    },
    number: Number(record.number_of_items ?? 1),
    value: {
      currency: (record.valuation_currency || record.currency_) as Currency,
      value: Number(record.current_valuation_ || record.item_price)
    },
    totalCost: {
      currency: record.currency_ as Currency,
      value: Math.max(
        0,
        record.total_cost
          ? Number(record.total_cost)
          : record.acquisition_type === 'Direct'
          ? Number(record.purchase_price_) +
            Number(record.other_costs ?? 0) -
            Number(record.discountprice_adjustment ?? 0)
          : Number(record.hammer_price ?? 0) +
            Number(record.tax_payable ?? 0) +
            Number(record.buyer_premium ?? 0) +
            Number(record.other_costs ?? 0)
      )
    },
    acquisition: {
      acquisitionType: (record.acquisition_type || 'Direct') as AcquisitionType,
      sellerId: record.seller,
      invoiceNumber: record.invoice_number,
      priceAsValue: record.use_total_cost_as_asset_value === 'YES' ? true : false,
      discountAdjustment: {
        currency: record.currency_ as Currency,
        value: record.discountprice_adjustment
          ? Math.min(
              Number(record.purchase_price_) + Number(record.other_costs ?? 0),
              Number(record.discountprice_adjustment)
            )
          : 0
      },
      otherCost: { currency: record.currency_ as Currency, value: record.other_costs ? Number(record.other_costs) : 0 },
      auctionDetail: {
        hammerPrice: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.hammer_price ? Number(record.hammer_price) : 0
        },
        taxPayable: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.tax_payable ? Number(record.tax_payable) : 0
        },
        buyerPremium: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.buyer_premium ? Number(record.buyer_premium) : 0
        },
        lotNumber: record.lot_number,
        paddleNumber: record.paddle_number,
        auctionNumber: record.auction_namenumber
      },
      initialValuationAtPurchase: {
        currency: (record.valuation_currency || record.currency_) as Currency,
        value: Number(record.initial_valuation_at_purchase)
      }
    },
    measurementUnits: record.measurement as MeasurementUnits,
    height: record.height ? Number(record.height) : 0,
    width: record.width ? Number(record.width) : 0,
    depth: record.depth ? Number(record.depth) : 0,
    weight: record.weight ? Number(record.weight) : 0,
    dimensionsSummary: record.dimensions_summary,
    geography: record.geography,
    medium: record.medium,
    provenance: record.provenance,
    inscription: record.inscription,
    catalogueText: record.catalogue_text,
    ownership: {
      myOwnership: Number(record.my_ownership_?.split('%')[0] ?? 100),
      shareholder: []
    },
    beneficiary: []
  }
  return art
}

function toOtherCollectable(record: OtherCollectableRecord) {
  const other: Omit<BelongingsUtils.CreateFields, 'id' | 'groupIds'> = {
    assetType: AssetType.OtherCollectables,
    name: record.collectable_name,
    subtype: record.collectable_type,
    brand: record.brand_manufacturer_,
    location: {
      locationType: record.location_type === 'My Property' ? LocationType.MyProperty : LocationType.Address,
      locationId: record.location_of_asset,
      roomId: record.room,
      position: record.position,
      notes: record.location_notes
    },
    purchaseDate: parse(record.purchase_date, 'dd/MM/yyyy', new Date()),
    //HACK
    purchasePrice: {
      currency: record.currency_ as Currency,
      value: Number(record.purchase_price_)
    },
    number: Number(record.number_of_items_ ?? 1),
    price: {
      currency: record.currency_ as Currency,
      value: Number(record.item_price_)
    },
    value: {
      currency: (record.valuation_currency || record.currency_) as Currency,
      value: Number(record.current_valuation_ || record.item_price_)
    },
    totalCost: {
      currency: record.currency_ as Currency,
      value: Math.max(
        0,
        record.total_cost
          ? Number(record.total_cost)
          : record.acquisition_type === 'Direct'
          ? Number(record.purchase_price_) +
            Number(record.other_costs ?? 0) -
            Number(record.discountprice_adjustment ?? 0)
          : Number(record.hammer_price ?? 0) +
            Number(record.tax_payable ?? 0) +
            Number(record.buyer_premium ?? 0) +
            Number(record.other_costs ?? 0)
      )
    },
    acquisition: {
      acquisitionType: (record.acquisition_type || 'Direct') as AcquisitionType,
      sellerId: record.seller,
      invoiceNumber: record.invoice_number,
      priceAsValue: record.use_total_cost_as_asset_value === 'YES' ? true : false,
      discountAdjustment: {
        currency: record.currency_ as Currency,
        value: record.discountprice_adjustment
          ? Math.min(
              Number(record.purchase_price_) + Number(record.other_costs ?? 0),
              Number(record.discountprice_adjustment)
            )
          : 0
      },
      otherCost: { currency: record.currency_ as Currency, value: record.other_costs ? Number(record.other_costs) : 0 },
      auctionDetail: {
        hammerPrice: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.hammer_price ? Number(record.hammer_price) : 0
        },
        taxPayable: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.tax_payable ? Number(record.tax_payable) : 0
        },
        buyerPremium: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.buyer_premium ? Number(record.buyer_premium) : 0
        },
        lotNumber: record.lot_number,
        paddleNumber: record.paddle_number,
        auctionNumber: record.auction_namenumber
      },
      initialValuationAtPurchase: {
        currency: (record.valuation_currency || record.currency_) as Currency,
        value: Number(record.initial_valuation_at_purchase)
      }
    },
    measurementUnits: record.measurement_units as MeasurementUnits,
    height: record.height ? Number(record.height) : 0,
    width: record.width ? Number(record.width) : 0,
    depth: record.depth ? Number(record.depth) : 0,
    weight: record.weight ? Number(record.weight) : 0,
    dimensionsSummary: record.dimensions_summary,
    geography: record.geography,
    medium: record.medium,
    provenance: record.provenance,
    inscription: record.inscription,
    catalogueText: record.catalogue_text,
    notes: record.primary_notes,
    attributeNotes: record.attribute_notes,
    ownership: {
      myOwnership: Number(record.my_ownership_?.split('%')[0] ?? 100),
      shareholder: []
    },
    beneficiary: []
  }
  return other
}

function toBelonging(record: BelongingRecord) {
  const belonging: Omit<BelongingsUtils.CreateFields, 'id' | 'groupIds'> = {
    assetType: AssetType.Belonging,
    name: record.belonging_name_,
    subtype: record.belonging_type_,
    brand: record.brand_manufacturer_,
    location: {
      locationType: record.location_type === 'My Property' ? LocationType.MyProperty : LocationType.Address,
      locationId: record.location_of_asset,
      roomId: record.room,
      position: record.position,
      notes: record.location_notes
    },
    purchaseDate: parse(record.purchase_date, 'dd/MM/yyyy', new Date()),
    // HACK
    purchasePrice: {
      currency: record.currency_ as Currency,
      value: Number(record.purchase_price_)
    },
    number: Number(record.number_of_items_ ?? 1),
    price: {
      currency: record.currency_ as Currency,
      value: Number(record.item_price_)
    },
    value: {
      currency: (record.valuation_currency || record.currency_) as Currency,
      value: Number(record.current_valuation_ || record.item_price_)
    },
    totalCost: {
      currency: record.currency_ as Currency,
      value: Math.max(
        0,
        record.total_cost
          ? Number(record.total_cost)
          : record.acquisition_type === 'Direct'
          ? Number(record.purchase_price_) +
            Number(record.other_costs ?? 0) -
            Number(record.discountprice_adjustment ?? 0)
          : Number(record.hammer_price ?? 0) +
            Number(record.tax_payable ?? 0) +
            Number(record.buyer_premium ?? 0) +
            Number(record.other_costs ?? 0)
      )
    },
    acquisition: {
      acquisitionType: (record.acquisition_type || 'Direct') as AcquisitionType,
      sellerId: record.seller,
      invoiceNumber: record.invoice_number,
      priceAsValue: record.use_total_cost_as_asset_value === 'YES' ? true : false,
      discountAdjustment: {
        currency: record.currency_ as Currency,
        value: record.discountprice_adjustment
          ? Math.min(
              Number(record.purchase_price_) + Number(record.other_costs ?? 0),
              Number(record.discountprice_adjustment)
            )
          : 0
      },
      otherCost: { currency: record.currency_ as Currency, value: record.other_costs ? Number(record.other_costs) : 0 },

      auctionDetail: {
        hammerPrice: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.hammer_price ? Number(record.hammer_price) : 0
        },
        taxPayable: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.tax_payable ? Number(record.tax_payable) : 0
        },
        buyerPremium: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.buyer_premium ? Number(record.buyer_premium) : 0
        },
        lotNumber: record.lot_number,
        paddleNumber: record.paddle_number,
        auctionNumber: record.auction_namenumber
      },
      initialValuationAtPurchase: {
        currency: (record.valuation_currency || record.currency_) as Currency,
        value: Number(record.initial_valuation_at_purchase)
      }
    },
    measurementUnits: record.measurement_units as MeasurementUnits,
    height: record.height ? Number(record.height) : 0,
    width: record.width ? Number(record.width) : 0,
    depth: record.depth ? Number(record.depth) : 0,
    weight: record.weight ? Number(record.weight) : 0,
    dimensionsSummary: record.dimensions_summary,
    geography: record.geography,
    medium: record.medium,
    provenance: record.provenance,
    inscription: record.inscription,
    catalogueText: record.catalogue_text,
    notes: record.primary_notes,
    attributeNotes: record.attribute_notes,
    ownership: {
      myOwnership: Number(record.my_ownership_?.split('%')[0] ?? 100),
      shareholder: []
    },
    beneficiary: []
  }
  return belonging
}

function toWine(record: WineRecord) {
  const wine: Omit<WinePurchase.CreateFields, 'groupIds'> = {
    id: '', // generated when addPurchase
    wineId: record.wineId,
    purchaseDate: parse(record.purchase_date, 'dd/MM/yyyy', new Date()),
    deliveryDate: parse(record.delivery_date, 'dd/MM/yyyy', new Date()),
    bottleSize: record.bottle_size as BottleSize,
    pricingMethod: record.pricing_method === 'Bottle Price' ? WinePricingMethod.Bottle : WinePricingMethod.Lot,
    price: { currency: record.currency as Currency, value: Number(record.bottle_price) },
    totalCost: {
      currency: record.currency as Currency,
      value:
        record.pricing_method === 'Lot/Bulk Price'
          ? Number(record.bottle_price) + Number(record.other_costs)
          : Number(record.bottle_price) * Number(record.number_of_bottles) + Number(record.other_costs)
    },
    valuePerBottle: { currency: record.currency as Currency, value: Number(record.valuation_per_bottle) },
    acquisition: {
      acquisitionType: (record.acquisition_type || 'Direct') as AcquisitionType,
      auctionDetail: {
        hammerPrice: { currency: record.currency as Currency, value: Number(record.hammer_price) },
        taxPayable: { currency: record.currency as Currency, value: Number(record.tax_payable) },
        buyerPremium: { currency: record.currency as Currency, value: Number(record.buyer_premium) }
      },
      otherCost: { currency: record.currency as Currency, value: Number(record.other_costs) },
      priceAsValue: record.use_total_cost_as_asset_value === 'Yes' ? true : false,
      sellerId: record.sellerretailer,
      initialValuationAtPurchase: {
        currency: record.currency as Currency,
        value: Number(record.initial_valuation_at_purchase)
      }
    },
    ownership: { myOwnership: Number(record.my_ownership_?.split('%')[0] ?? 100), shareholder: [] },
    beneficiary: [],
    bottles: [
      {
        bottleId: '', // generated when addPurchase
        location: {
          locationType: record.location_type === 'My Property' ? LocationType.MyProperty : LocationType.Address,
          locationId: record.location_of_asset,
          roomId: record.bin,
          position: record.shelf,
          notes: record.location_notes
        },
        status: record.status === 'Pending' ? WineStatus.Pending : WineStatus.Delivered
      }
    ]
  }
  return wine
}
