import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { DocNotExist } from 'core/remodel/types/error'
import { useToast } from '@/hooks/useToast'

interface UseRedirect {
  error?: any
  shouldRedirect?: boolean
  description?: string
  redirectTo?: string | undefined
  variant?: 'error' | 'success'
}

export function useRedirect({
  error,
  shouldRedirect = false,
  description,
  redirectTo = '/',
  variant = 'error'
}: UseRedirect) {
  const router = useRouter()
  const { toast } = useToast()

  useEffect(() => {
    const redirectType = error instanceof DocNotExist ? 'docNotExist' : shouldRedirect ? 'manual' : null
    switch (redirectType) {
      case 'docNotExist':
        toast({ variant: 'error', description: "Data doesn't exist, Please check if the Asset ID is correct." })
        router.replace(redirectTo)
        break
      case 'manual':
        description && toast({ variant, description })
        router.replace(redirectTo)
        break
    }
  }, [description, error, redirectTo, router, shouldRedirect, toast, variant])
}
