export const POLICY_LINKS = {
  linkWebsite: 'https://myassets.com',
  linkApp: 'https://app.myassets.com',
  linkTerms: 'https://myassets.com/terms-and-conditions',
  linkPlaid: 'https://plaid.com/legal/#consumers',
  linkStripe: 'https://stripe.com/gb/privacy',
  linkHubspot: 'https://legal.hubspot.com/privacy-policy',
  linkGenesys: 'https://www.genesys.com/en-gb/company/legal/privacy-policy',
  linkSendgrid: 'https://www.twilio.com/en-us/legal/privacy',
  linkFirebase: 'https://firebase.google.com/support/privacy',
  linkCloudflare: 'https://www.cloudflare.com/en-gb/privacypolicy',
  linkICO: 'https://ico.org.uk/make-a-complaint',
  linkMarketing: 'mailto: marketing@myassets.com',
  linkProtection: 'mailto: dataprotection@myassets.com',
  linkCookie: 'https://www.aboutcookie.org/',
  linkAllCookie: 'https://allaboutcookies.org/',
}
