import { useEffect, useMemo, useRef, useState } from 'react'
import * as d3 from 'd3'
import { useTranslation } from 'react-i18next'
import { useIdleTimerContext } from 'react-idle-timer'

import { expiredColors as color } from '@/constants/chartConfig'
import { promptBeforeIdle } from '@/constants/expiredTime'
import { Button, Modal } from '@/components/base'
import { DonutItem } from '@/components/chart'

const totalSeconds = promptBeforeIdle / 1000
const outerRadius = 70
const innerRadius = 60

const pie = d3
  .pie<DonutItem>()
  .sort(null)
  .value((d) => Math.abs(d.value))

export default function DialogSessionCountDown() {
  const [countdown, setCountdown] = useState<number>(0)
  const { getRemainingTime, activate, isPrompted } = useIdleTimerContext()
  const { t } = useTranslation()
  const title = useRef<string>('')
  const prompted = isPrompted()
  const data = useMemo(() => {
    return [
      { name: 'passTime', value: totalSeconds - countdown },
      { name: 'remaining', value: countdown }
    ]
  }, [countdown])

  useEffect(() => {
    const timerId = setInterval(() => {
      const remaining = Math.max(Math.floor(getRemainingTime() / 1000), 0)
      setCountdown(remaining)
    }, 1000)
    return () => clearInterval(timerId)
  }, [getRemainingTime])

  // update countdown in document title
  useEffect(() => {
    title.current = document.title
    if (prompted) {
      document.title = countdown > 0 ? t('ExpiredCountdown', { remaining: countdown }) : t('SessionExpired')
      return () => {
        document.title = countdown > 0 ? title.current : t('SessionExpired')
      }
    }
  }, [countdown, t, prompted])

  const onStayLogin = () => {
    activate()
    if (typeof window !== 'undefined' && prompted) {
      try {
        ;(window as any).Genesys('subscribe', 'Launcher.ready', () => {
          ;(window as any).Genesys('command', 'Launcher.show')
        })
      } catch (e) {
        console.warn('Genesys error:', e)
      }
    }
  }

  if (typeof window !== 'undefined' && prompted) {
    try {
      ;(window as any).Genesys('subscribe', 'Launcher.ready', () => {
        ;(window as any).Genesys('command', 'Launcher.hide')
        ;(window as any).Genesys('command', 'Messenger.hide')
      })
      ;(window as any).Genesys('subscribe', 'MessagingService.ready', () => {
        ;(window as any).Genesys('command', 'MessagingService.clearConversation')
      })
      localStorage.removeItem('_e9166dec-4d8c-4670-bbd2-b6152c56aa85:gcmcopn')
      localStorage.removeItem('_e9166dec-4d8c-4670-bbd2-b6152c56aa85:gcmcsessionActive')
    } catch (e) {
      console.warn('Genesys error:', e)
    }
  }

  return (
    prompted && (
      <Modal>
        <Modal.Content className={'gap-4'}>
          <div className={'flex flex-col items-center justify-start gap-2 text-[#414554]'}>
            <p className={'mb-2 text-3xl font-bold'}>{t('SessionAboutExpire')}</p>
            <svg width={160} height={160}>
              {/* path item */}
              <g transform={`translate(80,80)`}>
                {pie(data).map(({ startAngle, endAngle }, index) => (
                  <path
                    key={`arc-${index}`}
                    className={'pointer-events-none'}
                    d={d3.arc()({ startAngle, endAngle, innerRadius, outerRadius }) ?? ''}
                    fill={color[index % color.length]}
                    stroke={'#fff'}
                    strokeWidth={1}
                  />
                ))}
              </g>
              <foreignObject className={'pointer-events-none'} x={0} y={0} width={'100%'} height={'100%'}>
                <div className={'flex h-full items-center justify-center'}>
                  <p className={'text-center text-[40px] font-bold text-primary'}>{countdown}</p>
                </div>
              </foreignObject>
            </svg>
            <p className={'text-lg'}>{t('SessionAboutToTimeOut')}</p>
            <Button className={'bg-primary p-2 text-white hover:bg-primary-hover'} onClick={onStayLogin}>
              {t('StayLogin')}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    )
  )
}
