import { group } from 'console'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR, { mutate } from 'swr'

import { addGroup, fetchGroupOptions, groupQuery } from '@/api/GroupService'
import { cn } from '@/utils/classnames'
import { useAuthStore } from '@/store/authStore'
import { Button, FormCreatableAutocomplete } from '@/components/base'

export type GroupValues = {
  groupId: string
}

const defaultValues: GroupValues = {
  groupId: ''
}

interface GroupFormProps {
  onSubmit: SubmitHandler<GroupValues>
  onCancel: () => void
  submitText?: string
}

export function GroupForm({ onCancel, onSubmit, submitText }: GroupFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit
  } = useForm<GroupValues>({ defaultValues })

  const { data: groupOptions, isValidating: loadingGroups } = useSWR([groupQuery.options], fetchGroupOptions(database!))

  return (
    <form className={' w-full gap-2 p-4 sm:flex sm:flex-col '} onSubmit={handleSubmit(onSubmit)}>
      <FormCreatableAutocomplete
        control={control}
        name={'groupId'}
        label={t('Field.Groups')}
        options={groupOptions}
        placeholder={t('TypeToSearch')}
        onCreate={async (name) => await addGroup(database!, { name }, [])}
        isLoading={loadingGroups}
        required={true}
      />

      <fieldset className={'flex flex-col gap-2 sm:flex-row sm:justify-between'} disabled={isSubmitting}>
        <Button className={'w-full'} variant={'outline'} size={'md'} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button className={'group relative w-full '} variant={'solid'} size={'md'} type={'submit'} disabled={!isValid}>
          {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
          <span className={cn({ 'opacity-0': isSubmitting })}>{submitText || t('Field.Groups')}</span>
        </Button>
      </fieldset>
    </form>
  )
}
