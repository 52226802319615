import { useEffect, useMemo, useState } from 'react'
import { File, MoreVertical } from 'lucide-react'
import { useController, useWatch, type Control, type FieldPath, type FieldValues } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import useSWR from 'swr'

import type { FullMetadata } from 'core/coreFirebase'
import { AttachmentKind } from 'core/remodel/types/common'
import { IVAttachmentFileSaltFieldKey } from 'core/remodel/types/common/attachment'
import { commonQuery, fetchStorageLimit, getMetadata, uploadFile } from '@/api/CommonService'
import { MAX_ACCOUNT_STORAGE_SPACE_MB, MAX_DOCUMENT_SIZE_MB } from '@/constants/preference'
import { isDocument } from '@/constants/upload'
import { isAllowedFileType } from '@/utils/checker'
import { formatBytes, round10 } from '@/utils/formatter'
import { heicToPng } from '@/utils/heicToPng'
import { ImageSizes, resize } from '@/utils/imageTools'
import { isFulfilled } from '@/utils/predicate'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Progress,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator
} from '@/components/base'
import TruncatedText from '@/components/base/TruncatedText'
import Confirm from '@/components/Confirm'
import DropArea from '@/components/DropArea'
import { FileCorrupted, UploadCloudIcon } from '@/components/icon'
import RenameModal, { type RenameFileValues } from '@/components/RenameModal'

export type FileWithThumbnails = {
  file: File
  thumbnails: Record<ImageSizes, File>
  needThumbNails: boolean
}

interface Attachment {
  key: string
  name: string
  type: AttachmentKind
  [IVAttachmentFileSaltFieldKey]: string
}

interface AttachmentPanelProps<Values extends FieldValues, Path extends FieldPath<Values>> {
  assetId: string
  control: Control<Values>
  name: {
    mainImage: Path
    attachments: Path
  }
  widgetOptions: AttachmentKind[]
  isRentalAsset?: boolean
}

export default function AttachmentPanel<Values extends FieldValues, Path extends FieldPath<Values>>({
  assetId,
  control,
  name,
  widgetOptions,
  isRentalAsset = false
}: AttachmentPanelProps<Values, Path>) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { field: mainImage } = useController({ name: name.mainImage, control })
  const { field } = useController({ name: name.attachments, control })
  const value = useWatch({ name: name.attachments, control })
  const attachments = { value, onChange: field.onChange }

  const [instances, setInstances] = useState<Record<string, FullMetadata>>({})
  const [progresses, setProgresses] = useState<Record<string, number>>({})
  const [uploadErrorMsg, setUploadErrorMsg] = useState<string | null>(null)

  const { data: accountStorage = { usage: 0, limit: MAX_ACCOUNT_STORAGE_SPACE_MB * Math.pow(1024, 2) } } = useSWR(
    [commonQuery.storageLimit],
    fetchStorageLimit(database!)
  )
  const storageSpacePercent = useMemo(
    () => round10((accountStorage.usage / accountStorage.limit) * 100),
    [accountStorage]
  )

  useEffect(() => {
    if (!database) return
    const getInstance = async (key: string) => {
      try {
        const attachMeta = await getMetadata(database!)([commonQuery.metadata, assetId, key])
        setInstances((prev) => ({ ...prev, [key]: attachMeta }))
      } catch (e) {
        setInstances((prev) => ({ ...prev, [key]: { contentType: 'corrupted file', size: 0 } as FullMetadata }))
        console.log(e)
      }
    }

    const getInstances = async () => {
      const queue = (attachments.value ?? []).filter(
        (attach: Attachment) => !(attach.key in instances) && !(attach.key in progresses)
      )
      setTimeout(() => {
        Promise.all(queue.map((attach: Attachment) => getInstance(attach.key)))
      }, 2000)
    }

    getInstances()
  }, [assetId, attachments.value, database, instances, progresses])

  const setMainImage = (key: string | undefined) => {
    mainImage.onChange(key)
  }

  const sizeCheck = (newFiles: FileWithThumbnails[]) => {
    if (accountStorage.usage >= accountStorage.limit) {
      setUploadErrorMsg(t('validation:OverAccountMaxUploadSize', { value: MAX_ACCOUNT_STORAGE_SPACE_MB / 1024 }))
      return false
    }
    const isSizeValidForFile = newFiles.every((file) => {
      const isDoc = isDocument(file.file)
      if (isDoc) {
        return file.file.size <= MAX_DOCUMENT_SIZE_MB * Math.pow(1024, 2)
      }
      return true
    })
    const addedSize = newFiles.reduce((acc, cur) => {
      const totalSize =
        cur.file.size +
        (cur.needThumbNails ? Object.values(cur.thumbnails).reduce((acc, { size }) => acc + size, 0) : 0)
      return acc + totalSize
    }, 0)
    const isSizeValidForAccount =
      ((accountStorage?.usage ?? 0) + addedSize) / Math.pow(1024, 2) <= MAX_ACCOUNT_STORAGE_SPACE_MB

    if (!isSizeValidForAccount) {
      setUploadErrorMsg(t('validation:OverAccountMaxUploadSize', { value: MAX_ACCOUNT_STORAGE_SPACE_MB / 1024 }))
    } else if (!isSizeValidForFile) {
      setUploadErrorMsg(t('validation:OverDocumentMaxUploadSize', { value: MAX_DOCUMENT_SIZE_MB }))
    }

    return isSizeValidForAccount && isSizeValidForFile
  }

  const produceThumbnails = async (newFiles: File[]) => {
    const resp = newFiles.map(async (file) => {
      const thumbnails = { small: file, large: file, full: file }
      const needThumbNails = file.type.startsWith('image/') && widgetOptions.includes(AttachmentKind.AssetImage)
      if (!needThumbNails) return { file, thumbnails, needThumbNails }

      await Promise.allSettled(
        Object.values(ImageSizes).map(async (size) => {
          const blob = await resize(file, size)
          thumbnails[size] = blob as File
        })
      )
      return { file, thumbnails, needThumbNails }
    })
    const check = await Promise.all(resp)
    return check
  }

  const uploadAttach = async (fileWithThumbnails: FileWithThumbnails) => {
    const { file, thumbnails, needThumbNails } = fileWithThumbnails
    const fileId = database!.genAssetId()
    const isImageOrVideo = file.type.startsWith('image/') || file.type.startsWith('video/')
    const hasNoMainImage =
      !mainImage.value || !attachments.value.find(({ key }: { key: string }) => key === mainImage.value)
    if (hasNoMainImage && isImageOrVideo && widgetOptions.includes(AttachmentKind.AssetImage)) {
      setMainImage(fileId)
    }

    // upload and append metadata
    const encryption = database!.Encryption.current
    const iv = encryption.generateNewIVSalt()
    const encodedIV = encryption.convertIVSaltToBase64(iv)
    setProgresses((prev) => ({ ...prev, [fileId]: 0 }))

    uploadFile(database!, assetId, fileId, file, iv)
      .then((resp) => {
        setInstances((prev) => {
          const { size } = prev[fileId] ?? { size: 0 }
          return { ...prev, [fileId]: { ...resp.metadata, size: size + resp.metadata.size } }
        })
        setProgresses((prev) => {
          const prevProgress = prev[fileId] ?? 0
          return { ...prev, [fileId]: prevProgress + (needThumbNails ? 25 : 100) }
        })
      })
      .catch((e) => {
        console.error(e)
        setTimeout(() => deleteAttach(fileId), 500)
      })

    // upload thumbnails
    if (needThumbNails) {
      Promise.all(
        Object.entries(thumbnails).map(([size, thumbnail]) => {
          const newId = `${fileId}_${size}`
          uploadFile(database!, assetId, newId, thumbnail, iv)
            .then((resp) => {
              setInstances((prev) => {
                const metadata = prev[fileId] ?? { size: 0 }
                return { ...prev, [fileId]: { ...metadata, size: metadata.size + resp.metadata.size } }
              })

              setProgresses((prev) => {
                const prevProgress = prev[fileId] ?? 0
                return { ...prev, [fileId]: prevProgress + 25 }
              })
            })
            .catch((e) => console.error(e))
        })
      )
    }

    return { key: fileId, encodedIV }
  }

  const appendAttachs = async (files: File[]) => {
    try {
      const { value, onChange } = attachments
      const filterHeicResult = await heicToPng(files)

      const validFiles = filterHeicResult.filter((file) => isAllowedFileType(file.type))
      if (validFiles.length !== filterHeicResult.length) {
        setUploadErrorMsg(t('validation:UnsupportedFileType'))
        return
      }
      // file size will change after heicToPng() and produceThumbnails()
      const uploadFiles = await produceThumbnails(filterHeicResult)
      if (!sizeCheck(uploadFiles)) return

      const uploadPromises = uploadFiles.map(async (fileWithThumbnails: FileWithThumbnails) => {
        const { key, encodedIV } = await uploadAttach(fileWithThumbnails)
        const {
          file: { name, type }
        } = fileWithThumbnails
        return {
          key,
          name,
          [IVAttachmentFileSaltFieldKey]: encodedIV,
          type:
            type.split('/')?.[0] === 'image' && widgetOptions.includes(AttachmentKind.AssetImage)
              ? AttachmentKind.AssetImage
              : AttachmentKind.PrimaryDetails
        } as Attachment
      })
      const attachs = (await Promise.allSettled(uploadPromises)).filter(isFulfilled).map((result) => result.value)
      onChange([...attachs, ...(value ?? [])])
    } catch (e) {
      console.log(e)
    }
  }

  const renameAttach = (data: RenameFileValues) => {
    const { value, onChange } = attachments
    onChange(
      value.map((attachment: Attachment) =>
        attachment.key === data.fileKey ? { ...attachment, name: data.newFileName } : attachment
      )
    )
  }

  const deleteAttach = (key: string) => {
    const { value, onChange } = attachments
    setInstances(({ [key]: _, ...rest }) => rest)
    setProgresses(({ [key]: _, ...rest }) => rest)

    const newAttachments = value.filter((attachment: Attachment) => attachment.key !== key)
    onChange(newAttachments)

    if (mainImage.value === key) {
      const newKey = newAttachments.find((attachment: Attachment) => attachment.type === AttachmentKind.AssetImage)?.key
      setMainImage(newKey)
    }
  }

  const handleSelectKind = (key: string, kind: AttachmentKind) => {
    const { value } = attachments
    const isAssetImage = kind === AttachmentKind.AssetImage
    const isMainImage = key === mainImage.value

    if (isAssetImage && mainImage.value === undefined) {
      setMainImage(key)
    }

    if (!isAssetImage && isMainImage) {
      const filtered = value.filter((attachment: Attachment) => attachment.key !== key)
      const newKey = filtered.find((attachment: Attachment) => attachment.type === AttachmentKind.AssetImage)?.key
      setMainImage(newKey)
    }
  }

  // only remove data from attachments array, no need to delete the ones in database
  const removeData = (key: string) => {
    const { value, onChange } = attachments
    const isMainImage = key === mainImage.value
    const newAttachments = value.filter((attachment: Attachment) => attachment.key !== key)
    if (isMainImage) {
      const newKey = newAttachments.find((attachment: Attachment) => attachment.type === AttachmentKind.AssetImage)?.key
      setMainImage(newKey)
    }
    onChange(newAttachments)
  }

  return (
    <DropArea className={'flex flex-col items-center gap-4'} onDrop={appendAttachs}>
      <label className={'group flex w-full cursor-pointer flex-col items-center'} htmlFor={'attachments'}>
        <UploadCloudIcon
          className={'text-primary transition-colors ease-out group-hover:text-primary-hover'}
          size={64}
        />
        <Trans
          t={t}
          i18nKey={'DropFiles'}
          parent={(props: any) => <p className={'text-sm text-primary'} {...props} />}
          components={[<span key={0} className={'underline'} />]}
        />
        <input
          id={'attachments'}
          className={'sr-only'}
          name={'attachments'}
          type={'file'}
          multiple={true}
          onChange={(e) => {
            if (e.target.files) {
              const files = Array.from(e.target.files)
              appendAttachs(files)
            }
          }}
        />
      </label>
      <Progress value={storageSpacePercent} />
      <span className={'text-xs text-gray-800'}>{t('StorageSpace', { percent: storageSpacePercent })}</span>
      <Separator />
      <ul className={'w-full space-y-1'}>
        {(attachments.value ?? []).map((attach: Attachment, index: number) => {
          const instance = instances[attach.key]
          const progress = progresses[attach.key]
          const isProgressing = progress !== undefined && progress !== 100
          const isImage = instance?.contentType?.startsWith('image/')
          const options = isImage ? widgetOptions : widgetOptions.filter((item) => item !== AttachmentKind.AssetImage)
          const isMainImage = mainImage.value === attach.key
          return instance ? (
            instance?.contentType === 'corrupted file' ? (
              <li key={attach.key} className={'flex items-center gap-2 border-b border-gray-200 pb-2 pt-4'}>
                <FileCorrupted className={'h-8 w-8 text-primary'} />
                <div className={'flex-1 text-sm'}>
                  <TruncatedText className={'w-60 whitespace-nowrap text-gray-600'}>{attach.name}</TruncatedText>
                  <span className={'text-grey'}>{t('Corrupted')}</span>
                </div>
                <DropMenu
                  isCorrupted={true}
                  onRemove={() => removeData(attach.key)}
                  attach={attach}
                  setMainImage={() => setMainImage(attach.key)}
                  onRename={renameAttach}
                  onDelete={() => deleteAttach(attach.key)}
                />
              </li>
            ) : (
              <li key={attach.key} className={'flex items-center gap-2 border-b border-gray-200 pb-2 pt-4'}>
                <File className={'h-8 w-8 text-primary'} />
                <div className={'flex-1 text-sm'}>
                  <TruncatedText className={'w-60  whitespace-nowrap text-gray-600'}>{attach.name}</TruncatedText>
                  <span className={'text-grey'}>{formatBytes(instance.size)}</span>
                  {isMainImage && <span className={'ml-2 text-primary'}>{t('CoverImage')}</span>}
                </div>
                <div className={'relative w-40'}>
                  <label className={'absolute -top-5 text-xs text-gray-500'}>
                    {t('Field.AssetSectionsAttributes')}
                  </label>
                  <WidgetSelect
                    control={control}
                    name={`attachments.${index}.type` as Path}
                    options={options}
                    onSelected={(kind) => handleSelectKind(attach.key, kind)}
                    isRentalAsset={isRentalAsset}
                  />
                </div>
                <DropMenu
                  attach={attach}
                  setMainImage={() => setMainImage(attach.key)}
                  onRename={renameAttach}
                  onDelete={() => deleteAttach(attach.key)}
                  onRemove={() => removeData(attach.key)}
                />
              </li>
            )
          ) : isProgressing ? (
            <li key={attach.key} className={'flex items-center gap-2 border-b border-gray-200 pb-2 pt-4'}>
              <File className={'h-8 w-8 text-primary'} />
              <div className={'flex-1 text-sm'}>
                <TruncatedText className={'w-60 whitespace-nowrap text-gray-600'}>{attach.name}</TruncatedText>
                <span className={'text-grey'}>{t('ResizingAndEncrypting')}</span>
                <Progress value={progress} />
              </div>
            </li>
          ) : (
            <li key={attach.key} className={'flex items-center gap-2 border-b border-gray-200 pb-2 pt-4'}>
              <File className={'h-8 w-8 text-primary'} />
              <div className={'flex-1 text-sm'}>
                <TruncatedText className={'w-60 whitespace-nowrap text-gray-600'}>{attach.name}</TruncatedText>
                <span className={'text-grey'}>{t('Uploading')}</span>
              </div>
            </li>
          )
        })}
      </ul>
      {uploadErrorMsg && (
        <Confirm
          title={t('UploadError')}
          content={uploadErrorMsg}
          onCancel={() => setUploadErrorMsg(null)}
          cancelLabel={t('Cancel')}
          onConfirm={() => setUploadErrorMsg(null)}
          confirmLabel={t('Confirm')}
        />
      )}
    </DropArea>
  )
}

interface WidgetSelectProps<Values extends FieldValues, Path extends FieldPath<Values>> {
  options: AttachmentKind[]
  onSelected: (kind: AttachmentKind) => void
  // control props
  control: Control<Values>
  name: Path
  isRentalAsset?: boolean
}

function WidgetSelect<Values extends FieldValues, Path extends FieldPath<Values>>({
  control,
  name,
  options,
  onSelected,
  isRentalAsset = false
}: WidgetSelectProps<Values, Path>) {
  const { t } = useTranslation()
  const { field } = useController({ control, name })

  const handleValueChange = (value: string) => {
    field.onChange(value)
    onSelected(value as AttachmentKind)
  }

  return (
    <Select name={name} value={field.value} onValueChange={handleValueChange}>
      <SelectTrigger className={'border bg-grey-input px-3 py-1.5 [&>span]:truncate'}>
        <SelectValue />
      </SelectTrigger>
      <SelectContent className={'max-h-[16rem] max-w-min overflow-y-auto'}>
        {options.map((option) => (
          <SelectItem key={option} value={option}>
            {isRentalAsset && option === AttachmentKind.Acquisition
              ? t('properties:RentalDetails')
              : t(`AttachmentKind.${option}`)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

type AttachAction = 'Rename' | 'Delete' | 'Remove' | null

interface DropMenuProps {
  attach: Attachment
  setMainImage: () => void
  onRename: (data: RenameFileValues) => void
  onDelete: () => void
  isCorrupted?: boolean
  onRemove?: () => void
}

export function DropMenu({ isCorrupted, attach, setMainImage, onRename, onDelete, onRemove }: DropMenuProps) {
  const [action, setAction] = useState<AttachAction>(null)
  const { t } = useTranslation()
  const handleClose = () => setAction(null)
  const handleDelete = () => {
    onDelete()
    handleClose()
  }
  const handleRemove = () => {
    onRemove?.()
    handleClose()
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild={true}>
          <Button className={'h-9 w-9 border-gray-200 text-primary hover:bg-primary hover:text-white'}>
            <MoreVertical />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align={'end'}>
          {isCorrupted ? (
            <DropdownMenuItem onSelect={() => setAction('Remove')}>{t('Remove')}</DropdownMenuItem>
          ) : (
            <>
              {attach.type === AttachmentKind.AssetImage && (
                <DropdownMenuItem onSelect={setMainImage}>{t('CoverImage')}</DropdownMenuItem>
              )}
              <DropdownMenuItem onSelect={() => setAction('Rename')}>{t('Rename')}</DropdownMenuItem>
              <DropdownMenuItem onSelect={() => setAction('Delete')}>{t('Delete')}</DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      <RenameModal isOpen={action === 'Rename'} file={attach} onRename={onRename} onCancel={handleClose} />
      {action === 'Delete' && (
        <Confirm
          title={t('Delete')}
          content={t('documents:AskDeleteDocuments', { name: attach.name })}
          onCancel={handleClose}
          cancelLabel={t('Cancel')}
          onConfirm={handleDelete}
          confirmLabel={t('Delete')}
        />
      )}
      {action === 'Remove' && (
        <Confirm
          title={t('Remove')}
          content={t('documents:AskRemoveMetadata', { name: attach.name })}
          onCancel={handleClose}
          cancelLabel={t('Cancel')}
          onConfirm={handleRemove}
          confirmLabel={t('Remove')}
        />
      )}
    </>
  )
}
